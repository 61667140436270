import { Stack, styled } from '@mui/material';
import { type ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { type MetadataRouter, useMetadataClient } from '@@api/services/metadata/client';
import { defaultQueryPlaceholderData } from '@@api/utils/defaultPlaceholderData';
import { getQueryParams } from '@@api/utils/getQueryParams';
import { getAuthorIdFromSearchValue } from '@@components/AuthorSearchableAutocomplete/utils';
import OverviewCreator from '@@components/Overview/OverviewCreator';
import Pagination from '@@components/Pagination';
import Spacer from '@@components/Spacer';
import linkToContent from '@@containers/ContentItem/utils/linkToContent';
import ContentStatusIcon from '@@containers/ContentStatus/ContentStatusIcon';
import PublicationStatusIcon from '@@containers/PublicationStatus/PublicationStatusIcon';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import { type UseUnitySearchOptions } from '@@containers/Search/types';
import UnitySearch from '@@containers/Search/UnitySearch';
import useUnitySearch from '@@containers/Search/useUnitySearch';
import { getDefaultTenantDefaultTeaser } from '@@containers/Teaser/utils';
import TenantImage from '@@containers/TenantSpecific/TenantImage';
import ButtonGroup from '@@form/components/ButtonGroup';
import SelectionTableField from '@@form/fields/SelectionTable';
import { SHORT_DATETIME } from '@@lib/dateTime/formats';
import { formatDate } from '@@lib/dateTime/utils';

const FullWidthContent = styled('div')(({ theme }) => ({
    margin: `0 -${theme.spacing(4)}`,
    marginTop: theme.spacing(4),
}));

const TableWrapper = styled('div')({
    minHeight: '360px',
});

const StyledTenantImage = styled(TenantImage)(({ theme }) => ({
    margin: theme.spacing(0, 1),
    verticalAlign: 'middle',
}));

type SelectedRelatedContent = Id[] | Id;

export type Props = {
    columns?: TableColumn[];
    submitButtonText?: string;
    selectedRelatedContent?: SelectedRelatedContent;
    onCancel: VoidFunction;
    onSubmit: (selectedContent: SelectedRelatedContent) => void;
    onSubmitSuccess?: VoidFunction;
    multiple?: boolean;
    params: any;
    disableUrlSync?: boolean;
    forceContentTypes?: string[];
    searchProps?: Partial<UseUnitySearchOptions>;
    modalLeavePrompt?: () => boolean;
    isRowDisabled?: ({ id }: { id: Id }) => boolean;
    className?: string;
    children?: ReactNode;
};

const SelectionForm = ({
    onCancel,
    onSubmit,
    onSubmitSuccess,
    submitButtonText,
    className,
    multiple,
    selectedRelatedContent,
    columns,
    modalLeavePrompt,
    params,
    forceContentTypes,
    disableUrlSync,
    searchProps,
    isRowDisabled,
}: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const search = useUnitySearch({
        disableUrlSync,
        fields: searchProps?.fields || {
            q: true,
            tenantIds: true,
            userIds: true,
            contentType: !forceContentTypes,
            tagIds: true,
            categoryIds: true,
            contentStatus: true,
            publicationStatus: true,
        },
        fieldsProps: searchProps?.fieldsProps,
        values: searchProps?.values,
    });

    const page = search.pageNumbers[0];

    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();
    const size = params?.size || 10;

    const query = getQueryParams<MetadataRouter['metadata']['getAll']>({
        q: search.debouncedValues.q,
        tenantIds: search.values.tenantIds,
        userIds: getAuthorIdFromSearchValue(search.values.userIds),
        contentType: forceContentTypes || search.values.contentType,
        tagIds: search.values.tagIds,
        categoryIds: search.values.categoryIds,
        sort: 'updatedAt,desc',
        contentStatus: params?.contentStatus || search.values.contentStatus,
        publicationStatus: params?.publicationStatus || search.values.publicationStatus,
        excludeIds: params?.excludeIds,
        page: page - 1,
        size,
    });

    const { data: metadata } = metadataClient.metadata.getAll.useQuery({
        queryKey: metadataKeys.metadata.getAll({ query }),
        queryData: { query },
        placeholderData: defaultQueryPlaceholderData(size),
    });

    const content = metadata?.body.content || [];
    const firstPageNumber = 1;
    const lastPageNumber = metadata?.body.totalPages || 0;

    const renderButtons = (Buttons) => (
        <ButtonGroup>
            <Buttons.CancelButton />
            <Buttons.SubmitButton>
                {submitButtonText || t('relatedcontent.submitButton')}
            </Buttons.SubmitButton>
        </ButtonGroup>
    );

    const initialValuesInterceptor = useCallback((values) => ({ tableSelection: values }), []);
    const valuesInterceptor = useCallback(({ tableSelection }) => tableSelection, []);

    const onClickFieldAction = (action, col, record) => linkToContent(record, navigate, true);

    const data = content.map((item) =>
        item
            ? {
                  ...item,
                  ...(getDefaultTenantDefaultTeaser(item) || {}),
                  tenants: (item?.tenantIds || []).map((tenantId) => (
                      <StyledTenantImage key={tenantId} id={tenantId} size="S" natural />
                  )),
              }
            : item,
    );

    const defaultColumns: TableColumn[] = [
        {
            title: t('metaform.relatedcontent.modal.publicationStatus'),
            width: '63px',
            fieldName: 'publicationStatus',
            textAlign: 'center',
            render: ({ column, value }) => (
                <PublicationStatusIcon status={value} size={column.iconSize} />
            ),
        },
        {
            title: t('metaform.relatedcontent.modal.contentStatus'),
            width: '63px',
            fieldName: 'contentStatus',
            textAlign: 'center',
            render: ({ column, value }) => (
                <ContentStatusIcon status={value} size={column.iconSize} />
            ),
        },
        {
            type: 'text',
            fieldName: 'title',
            title: t('metaform.relatedcontent.modal.titleColumn'),
            width: '350px',
        },
        {
            type: 'text',
            width: '150px',
            fieldName: 'creator',
            title: t('metaform.relatedcontent.modal.author'),
            render: OverviewCreator,
        },
        {
            type: 'text',
            width: '150px',
            fieldName: 'updatedAt',
            title: t('metaform.relatedcontent.modal.updated'),
            getValue: ({ recordValue }) => recordValue && formatDate(recordValue, SHORT_DATETIME),
        },
        {
            type: 'text',
            width: '167px',
            fieldName: 'tenants',
            title: t('metaform.relatedcontent.modal.tenants'),
        },
    ];

    const values = initialValuesInterceptor(selectedRelatedContent);

    const handleSubmit: UseReactHookFormProps['onSubmit'] = (values) => {
        const newValues = valuesInterceptor(values);

        onSubmit(newValues);

        onSubmitSuccess?.();
    };

    return (
        <Stack>
            <UnitySearch {...search.unitySearchProps} />

            <ReactHookForm
                {...{
                    className,
                    modalLeavePrompt,
                    renderButtons,
                    onCancel,
                }}
                onSubmit={handleSubmit}
                formName="SelectionForm"
                values={values}
                hasCancelButton
                hasActiveCancelButton
                alwaysShowCancelButton
                autoFocus={false}
            >
                <FullWidthContent>
                    <TableWrapper>
                        <SelectionTableField
                            name="tableSelection"
                            columns={[
                                ...(columns || defaultColumns),
                                {
                                    type: 'actions',
                                    width: '102px',
                                    wrapAfter: 2,
                                    visibleOnHoverRowOnly: true,
                                    actions: [
                                        {
                                            type: 'view',
                                            iconName: 'eye-regular',
                                            title: t('metaform.relatedcontent.modal.preview'),
                                        },

                                        {
                                            type: 'edit',
                                            iconName: 'pen-regular',
                                            title: t('metaform.relatedcontent.modal.edit'),
                                        },
                                    ],
                                },
                            ]}
                            tableData={data}
                            multiple={multiple}
                            onClickFieldAction={onClickFieldAction}
                            isRowDisabled={isRowDisabled}
                        />
                    </TableWrapper>

                    <Spacer v lg />

                    <Pagination
                        current={page}
                        first={firstPageNumber}
                        last={lastPageNumber}
                        getPageHref={search.getPageHref}
                        onChange={search.handlePageChange}
                    />
                </FullWidthContent>
            </ReactHookForm>
        </Stack>
    );
};

export default SelectionForm;
