import { LoadingButton } from '@mui/lab';
import { Stack, styled, Tooltip } from '@mui/material';
import { type FC, type ReactNode, useEffect, useState } from 'react';

import { useAiToolsClient } from '@@api/services/aiTools/client';
import { type Article } from '@@api/services/content/schemas/article';
import FeedbackEvaluation from '@@components/FeedbackEvaluation/FeedbackEvaluation';
import Icon from '@@components/Icon';
import { type Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import { type SummaryListElement } from '@@editor/helpers/Element';
import usePrevious from '@@hooks/usePrevious';

import { PluginsWrapper } from '../components/PluginsWrapper';

const SummaryWrapper = styled(PluginsWrapper)(({ theme }) => ({
    padding: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

const ActionsWrapper = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(2, 3, 1),
}));

const ErrorMessage = styled('div')(({ theme }) => ({
    ...theme.typography.medium,
    color: theme.palette.error.main,
    marginBottom: theme.spacing(3),
}));

type Props = {
    children?: ReactNode;
    readOnly?: boolean;
    editor: Editor;
    element: SummaryListElement;
    contentId: Article['id'];
};

const Summary: FC<Props> = ({ children, readOnly, editor, element, contentId }) => {
    const { t } = editor;
    const [error, setError] = useState(false);

    const params = { id: contentId };

    const { client: aiToolsClient, queryKeys: aiToolsKeys } = useAiToolsClient();
    const queryClient = aiToolsClient.useQueryClient();

    const { data: generatedSummary } = aiToolsClient.articleSummaryGeneration.get.useQuery({
        queryKey: aiToolsKeys.articleSummaryGeneration.get({ params }),
        queryData: { params },
        enabled: Boolean(contentId),
    });

    const { mutate, isPending } = aiToolsClient.articleSummaryGeneration.post.useMutation();

    const invalidateSummary = () => {
        queryClient.invalidateQueries({
            queryKey: aiToolsKeys.articleSummaryGeneration.get({ params }),
        });
    };

    const articleBody = editor.children
        .filter((element) => !Element.isSummaryListElement(element))
        .map(Node.string)
        .filter(Boolean)
        .join(' ');

    const isArticleEmpty = !articleBody.length;

    const editorArticleSummary = Node.string(editor.children.find(Element.isSummaryListElement));

    const prevEditorArticleSummary = usePrevious(editorArticleSummary);

    useEffect(() => {
        if (editorArticleSummary !== prevEditorArticleSummary) {
            setError(false);
        }
    }, [editorArticleSummary, prevEditorArticleSummary]);

    const generateArticleSummary = (e) => {
        e.stopPropagation();

        setError(false);

        mutate(
            { params, body: { articleBody } },
            {
                onSuccess: ({ body }) => {
                    editor.insertSummaryListSummary({
                        at: ReactEditor.findPath(editor, element),
                        list: body.summary || [''],
                        element,
                    });

                    invalidateSummary();
                },
                onError: () => {
                    setError(true);
                },
            },
        );
    };

    const { id, feedbackEvaluation } = generatedSummary?.body || {};

    const regenerateButton = (
        <LoadingButton
            color="primary"
            variant="outlined"
            startIcon={<Icon name="rotate" />}
            loading={isPending}
            disabled={isArticleEmpty}
            onClick={generateArticleSummary}
        >
            {editorArticleSummary.length
                ? t('editor.plugin.summary.button.replace')
                : t('editor.plugin.summary.button.generate')}
        </LoadingButton>
    );

    return (
        <SummaryWrapper>
            {children}

            {!readOnly && (
                <ActionsWrapper contentEditable={false}>
                    {error && <ErrorMessage>{t('editor.plugin.summary.error')}</ErrorMessage>}

                    <Stack direction="row" justifyContent="space-between">
                        {isArticleEmpty ? (
                            <Tooltip
                                title={t('editor.plugin.summary.button.generate.tooplip')}
                                placement="right"
                            >
                                <span>{regenerateButton}</span>
                            </Tooltip>
                        ) : (
                            regenerateButton
                        )}

                        {id && !feedbackEvaluation && editorArticleSummary.length ? (
                            <FeedbackEvaluation id={id} onSuccess={invalidateSummary} />
                        ) : null}
                    </Stack>
                </ActionsWrapper>
            )}
        </SummaryWrapper>
    );
};

export default Summary;
