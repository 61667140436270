import { TableRow } from '@mui/material';
import { type ComponentType, type CSSProperties, type ElementType, type ReactNode } from 'react';
import ReactIs from 'react-is';

import DataField from './DataField';

type RenderFieldProps = {
    key?: number;
    column: TableColumn;
    value: 'loading';
};

type Props = {
    columns: TableColumn[];
    as?: ElementType;
    className?: string;
    renderField?: (props: RenderFieldProps) => ReactNode;
    style?: CSSProperties;
};

const RowLoading = (props: Props) => {
    const { as, className, columns, style } = props;

    const renderField = (column: TableColumn, i: number) => {
        const loadingColumn = column.type !== 'actions' ? { ...column, type: 'loading' } : column;

        const fieldProps: RenderFieldProps = {
            column: loadingColumn,
            value: 'loading',
        };

        if (props.renderField) {
            if (ReactIs.isValidElementType(props.renderField)) {
                const Component = props.renderField as ComponentType<RenderFieldProps>;

                return <Component key={i} {...fieldProps} />;
            }

            return props.renderField(fieldProps);
        }

        return <DataField key={i} {...fieldProps} />;
    };

    return <TableRow {...{ as, className, style }}>{columns.map(renderField)}</TableRow>;
};

export default RowLoading;
