import { LoadingButton, type LoadingButtonProps } from '@mui/lab';
import { Tooltip } from '@mui/material';

import Icon from '@@components/Icon';

export type Props = LoadingButtonProps & { iconName?: string; tooltip?: string | boolean };

const SubmitButton = (props: Props) => {
    const { iconName, tooltip, ...rest } = props;

    const button = (
        <LoadingButton
            startIcon={<Icon name={iconName ?? 'check-sharp'} />}
            color="success"
            variant="contained"
            type="submit"
            {...rest}
        />
    );

    if (tooltip) {
        return (
            <Tooltip title={tooltip}>
                {/* Needs to be wrapped in span element for tooltip to work */}
                <span tabIndex={0} style={{ display: 'inline-block' }}>
                    {button}
                </span>
            </Tooltip>
        );
    }

    return button;
};

export default SubmitButton;
