import { omit } from 'lodash-es';
import { type Descendant } from 'slate';
import invariant from 'tiny-invariant';

import { DEFAULT_BLOCK } from '@@editor/constants';
import { Element } from '@@editor/helpers';
import {
    type CrossheadElement,
    type CustomText,
    ELEMENT_TYPES,
    type ExternalLinkElement,
    type InternalLinkElement,
    type LinkElement,
    type ParagraphElement,
    type TextElement,
} from '@@editor/helpers/Element';
import {
    type UnityExternalLink,
    type UnityInternalLink,
    type UnityLink,
    type UnityText,
    type UnityTextItem,
} from '@@editor/typings/UnityElements';
import { cleanObject } from '@@utils/object';

import { CURRENT_VERSION } from './constants';
import markRules from './rules/markRules';
import { type SerializerOptions } from './types';

/*
 * helpers
 */

export const emptyLine = {
    ...DEFAULT_BLOCK,
};

const createLinkElement = (link: UnityLink) => {
    const { metadataId } = link as UnityInternalLink;
    const { url, text, attributes } = link as UnityExternalLink;
    const children = [{ text, ...cleanObject(attributes) }] as CustomText[];

    if (metadataId) {
        return Element.create<InternalLinkElement>({
            type: ELEMENT_TYPES.INTERNAL_LINK,
            data: {
                metadataId,
            },
            children,
        });
    }

    return Element.create<ExternalLinkElement>({
        type: ELEMENT_TYPES.EXTERNAL_LINK,
        data: {
            href: url,
        },
        children,
    });
};

const isInlineOrLinkElement = (el) => Element.isLinkElement(el) || Element.isInlineElement(el);

export const createTextNode = <T extends TextElement>(
    element: UnityText,
    next = markRules.deserialize,
): T => {
    const { type = ELEMENT_TYPES.PARAGRAPH, items = [], ...rest } = element;

    invariant(typeof type === 'string', 'Property "type" of type string is required');

    const emptyTextItem = {
        text: '',
        version: CURRENT_VERSION,
        type: ELEMENT_TYPES.TEXTITEM,
    };

    // prepare inline nodes
    const inlineProcessedItems = items.reduce(
        (acc, curr, pos) => {
            if (isInlineOrLinkElement(curr)) {
                // links at the beginning of a line need an empty text prefix
                if (!acc[acc.length - 1]) {
                    acc.push(emptyTextItem);
                }

                acc.push(curr);

                // links on the end of a line need an empty text suffix and
                // links need text in between them
                if (!items[pos + 1] || isInlineOrLinkElement(items[pos + 1])) {
                    acc.push(emptyTextItem);
                }
            } else {
                acc.push(curr);
            }

            return acc;
        },
        [] as (UnityTextItem | UnityLink)[],
    );

    const children = inlineProcessedItems.reduce(
        (acc, curr) => {
            // inline elements are never children
            if (isInlineOrLinkElement(curr)) {
                return acc.concat(createLinkElement(curr as UnityLink));
            }

            return acc.concat({
                text: (curr as UnityTextItem).text,
                ...next((curr as UnityTextItem).attributes),
            });
        },
        [] as (CustomText | LinkElement)[],
    );

    // Any slate element needs at least one child
    if (children.length <= 0) {
        children.push({ text: '' });
    }

    return Element.create<T>({
        ...DEFAULT_BLOCK,
        type: type === ELEMENT_TYPES.TEXT ? ELEMENT_TYPES.PARAGRAPH : type,
        children,
        data: omit(rest, ['version', 'variants']),
    } as T);
};

export const createExternalLinkNode = (
    text: string,
    url: string,
    version: typeof CURRENT_VERSION = CURRENT_VERSION,
) =>
    createLinkElement({
        type: ELEMENT_TYPES.EXTERNAL_LINK,
        text,
        url,
        version,
    });

export const createInternalLinkNode = (
    text: string,
    metadataId: number,
    version: typeof CURRENT_VERSION = CURRENT_VERSION,
) =>
    createLinkElement({
        type: ELEMENT_TYPES.INTERNAL_LINK,
        text,
        metadataId,
        version,
    });

export const createExternalLinkTextNode = (
    text: string,
    url: string,
    version: typeof CURRENT_VERSION = CURRENT_VERSION,
) =>
    createTextNode<ParagraphElement>({
        type: ELEMENT_TYPES.TEXT,
        items: [
            {
                type: ELEMENT_TYPES.EXTERNAL_LINK,
                text,
                url,
                version,
            },
        ],
        version,
    });

export const createInternalLinkTextNode = (
    text: string,
    metadataId: number,
    version: typeof CURRENT_VERSION = CURRENT_VERSION,
) =>
    createTextNode<ParagraphElement>({
        type: ELEMENT_TYPES.TEXT,
        items: [
            {
                type: ELEMENT_TYPES.INTERNAL_LINK,
                text,
                metadataId,
                version,
            },
        ],
        version,
    });

export const createElementFromString = <T extends Element>(
    type: T['type'],
    value?: string | null,
) =>
    ({
        ...DEFAULT_BLOCK,
        type,
        children: [{ text: value ?? '' }],
    }) as T;

export const createElementFromSingleLineState = <T extends Element>(
    type: T['type'],
    value?: Descendant[],
) => {
    const nodes = value && value.length > 0 ? [...value[0].children] : [{ text: '' }];

    return {
        ...DEFAULT_BLOCK,
        type,
        children: nodes,
    } as T;
};

export const createElementFromState = <T extends Element>(
    type: T['type'],
    value?: Descendant[],
) => {
    let filteredValue: Descendant[] = [];

    // value array can have undefined elements.
    if (value && value.length > 0) {
        filteredValue = value.filter((v) => Boolean(v));
    }

    const nodes =
        filteredValue && filteredValue.length > 0 ? [...filteredValue] : [{ ...DEFAULT_BLOCK }];

    return {
        ...DEFAULT_BLOCK,
        type,
        children: nodes.map((node) => {
            if (Element.isElement(node)) {
                return createElement(node.type, node.data, {
                    useInlineEditing: true,
                    children: node.children,
                });
            }

            return node;
        }),
    } as T;
};

const createElementFromArrayOfSingleLineStates = <T extends Element>(
    type: T['type'],
    value: Descendant[][],
) => value.map((state) => createElementFromSingleLineState(type, state));

const createChildrenForInlineEditedElement = <T extends Element>(element: T): T['children'] => {
    if (Element.isImageElement(element) || Element.isEmbeddedContentElement(element)) {
        return [
            createElementFromSingleLineState(
                ELEMENT_TYPES.EMBED_CAPTION,
                element.data.embed?.caption,
            ),
            createElementFromString(ELEMENT_TYPES.EMBED_CREDIT, element.data.embed?.credit),
        ];
    }

    if (Element.isInterviewSegmentElement(element)) {
        return [
            createElementFromSingleLineState(
                ELEMENT_TYPES.INTERVIEW_SEGMENT_QUESTION,
                element.data.question,
            ),
            ...createElementFromArrayOfSingleLineStates(
                ELEMENT_TYPES.INTERVIEW_SEGMENT_ANSWER,
                element.data.answers,
            ),
        ];
    }

    if (Element.isPollElement(element)) {
        return [
            createElementFromSingleLineState(ELEMENT_TYPES.POLL_QUESTION, element.data.question),
            ...createElementFromArrayOfSingleLineStates(
                ELEMENT_TYPES.POLL_ANSWER,
                element.data.answers,
            ),
        ];
    }

    if (Element.isQuoteElement(element)) {
        return [
            createElementFromSingleLineState(ELEMENT_TYPES.QUOTE_TEXT, element.data.quote),
            createElementFromSingleLineState(ELEMENT_TYPES.QUOTE_CAPTION, element.data.caption),
        ];
    }

    if (Element.isInfoboxElement(element)) {
        return [
            createElementFromSingleLineState(ELEMENT_TYPES.INFOBOX_TITLE, element.data.title),
            createElementFromState(ELEMENT_TYPES.INFOBOX_CONTENT, element.data.content),
        ];
    }

    if (Element.isDynamicTeaserElement(element)) {
        return [
            createElementFromSingleLineState(
                ELEMENT_TYPES.DYNAMIC_TEASER_TITLE,
                element.data.title,
            ),
        ];
    }

    if (Element.isSummaryListElement(element)) {
        return [createElementFromState(ELEMENT_TYPES.SUMMARY_LIST_SUMMARY, element.data.summary)];
    }

    return element.children;
};

export const createElement = <T extends Element>(
    type: T['type'],
    data: T['data'],
    options: SerializerOptions = {},
) => {
    const { useInlineEditing, children = [{ text: '' }] } = options;

    const embedNode = Element.create<T>({
        type,
        data,
        children,
    } as T);

    if (useInlineEditing) {
        embedNode.children = createChildrenForInlineEditedElement(embedNode);
    }

    return embedNode;
};

export const createTitleNode = (text = '') =>
    Element.create({
        ...DEFAULT_BLOCK,
        type: ELEMENT_TYPES.TITLE,
        children: [{ text }],
    });

export const createTitleHeaderNode = (text = '') =>
    Element.create({
        ...DEFAULT_BLOCK,
        type: ELEMENT_TYPES.TITLE_HEADER,
        children: [{ text }],
    });

export const createLeadNode = (text = '') =>
    Element.create({
        ...DEFAULT_BLOCK,
        type: ELEMENT_TYPES.LEAD,
        children: [{ text }],
    });

export const createFooterNode = (text = '') =>
    Element.create({
        ...DEFAULT_BLOCK,
        type: ELEMENT_TYPES.FOOTER,
        children: [{ text }],
    });

export const createCrossheadNode = (text: string, data: CrossheadElement['data']) =>
    Element.create<CrossheadElement>({
        type: ELEMENT_TYPES.CROSSHEAD,
        data,
        children: [{ text }],
    });
