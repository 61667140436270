import {
    ListItem as MuiListItem,
    ListItemButton as MuiListItemButton,
    ListItemText as MuiListItemText,
} from '@mui/material';
import { type ElementType, type ForwardedRef, forwardRef } from 'react';

import {
    DivWithoutInvalidProps,
    LinkBehaviorWithoutInvalidProps,
} from '@@components/ElementWithoutInvalidProps';

import { type ListItemProps } from './types';

export const DEFAULT_HEIGHT = 48;

const ListItem = <Data,>(props: ListItemProps<Data>, ref?: ForwardedRef<HTMLDivElement>) => {
    const { children, href, disabled, draggable, to, onClick, onMouseOver, onMouseDown } = props;

    const isClickable = href || to || onClick || onMouseDown;
    const isDraggable = draggable != null && JSON.parse(String(draggable));
    const shouldRenderAsButton =
        'autoFocus' in props ||
        'disabled' in props ||
        'selected' in props ||
        isClickable ||
        isDraggable;

    const text =
        typeof children === 'string' ? (
            <MuiListItemText primary={children} primaryTypographyProps={{ variant: 'h4' }} />
        ) : (
            children
        );

    const composedProps = {
        role: 'listitem',
        ...props,
        ref,
        children: text,
        onMouseOver(e) {
            onMouseOver?.(e, props);
        },
        onMouseDown(e) {
            if (disabled === true) {
                e.preventDefault();
            } else {
                onMouseDown?.(e, props);
            }
        },
        onClick(e) {
            if (disabled === true) {
                e.preventDefault();
            } else {
                onClick?.(e, props);
            }
        },
    };

    if (!shouldRenderAsButton) {
        return <MuiListItem {...composedProps} component={DivWithoutInvalidProps} />;
    }

    let Component: ElementType = DivWithoutInvalidProps;

    if (href || to) {
        Component = LinkBehaviorWithoutInvalidProps;
    }

    return (
        <MuiListItemButton
            {...composedProps}
            component={Component}
            disableTouchRipple={!isClickable && isDraggable}
        />
    );
};

export default forwardRef(ListItem) as <Data>(
    props: ListItemProps<Data> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof ListItem>;
