import { Button, type ButtonProps, styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: `${theme.shape.borderRadius}px`,
}));

const OpaqueButton = ({ className, ...props }: ButtonProps) => (
    <Wrapper className={className}>
        <Button {...props} />
    </Wrapper>
);

export default OpaqueButton;
