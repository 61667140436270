import { type RefObject, useRef } from 'react';
import { useSlateStatic } from 'slate-react';

import { type Element } from '@@editor/helpers';

import { useDragBlock } from './useDragBlock';
import { useDropBlock } from './useDropBlock';

type Options = {
    blockRef: RefObject<HTMLDivElement>;
    element: Element;
};
export const useDndBlock = ({ blockRef, element }: Options) => {
    const editor = useSlateStatic();

    const [{ isDragging }, dragRef, preview] = useDragBlock({
        blockRef,
        editorId: editor.id,
        element,
    });

    const [, drop] = useDropBlock(editor, {
        blockRef,
    });

    const dragPreview = useRef<HTMLElement | null>(null);

    if (!dragPreview.current && blockRef.current) {
        dragPreview.current =
            blockRef.current.querySelector('[data-drag-preview="true"]') ??
            blockRef.current.closest('[data-drag-preview="true"]') ??
            blockRef.current;
    }

    preview(dragPreview);

    drop(blockRef);

    return {
        dragRef,
        isDragging,
    };
};
