import { Path, Transforms } from 'slate';

import { type TickerSummary } from '@@api/services/aiTools/schemas';
import { type Ticker } from '@@api/services/liveticker/schemas/ticker';
import { LoadingStatusManager } from '@@containers/LoadingStatusManager';
import { generateKeyForRichTextEditorLoadingStatus } from '@@containers/LoadingStatusManager/utils';
import { DEFAULT_BLOCK } from '@@editor/constants';
import { Editor } from '@@editor/helpers';
import { type PluginOptions } from '@@editor/typings/UnityPlugins';
import type usePostTickerSummary from '@@form/hooks/usePostTickerSummary';

const insertSummary = (editor: Editor, id: string, summary?: TickerSummary['summary']) => {
    const [firstMatch] = Editor.nodes(editor, {
        at: [],
        match: (node) => node.data?.loadingStatusId === id,
    });

    if (firstMatch) {
        const [, path] = firstMatch;

        Editor.withoutNormalizing(editor, () => {
            Transforms.removeNodes(editor, { at: path });

            if (summary && summary.length > 0) {
                Editor.insertElement(
                    editor,
                    summary.map((stuff) => ({
                        ...DEFAULT_BLOCK,
                        children: [{ text: stuff }],
                    })),
                    { at: path },
                );

                const endPath = summary.slice(0, -1).reduce((acc) => Path.next(acc), path);

                const target = {
                    anchor: Editor.start(editor, path),
                    focus: Editor.end(editor, endPath),
                };

                Transforms.select(editor, target);
            }
        });
    }
};

const withTickerSummary = (
    editor: Editor,
    {
        contentId,
        generateTickerSummary,
    }: PluginOptions & {
        contentId: Ticker['id'];
        generateTickerSummary: ReturnType<typeof usePostTickerSummary>;
    },
) =>
    Object.assign(editor, {
        insertTickerSummary: (at) => {
            const loadingStatusId = crypto.randomUUID();
            const params = { id: contentId };

            Editor.insertElement(
                editor,
                {
                    ...DEFAULT_BLOCK,
                    data: {
                        ...DEFAULT_BLOCK.data,
                        loadingStatusId,
                    },
                },
                { at },
            );

            const key = generateKeyForRichTextEditorLoadingStatus({
                editorId: editor.id,
                loadingStatusId,
            });

            LoadingStatusManager.load({ key });

            generateTickerSummary({ params })
                .then(({ body: { summary } }) => {
                    insertSummary(editor, loadingStatusId, summary);

                    LoadingStatusManager.loadSuccess({ key, data: { summary } });
                })
                .catch(() => {
                    insertSummary(editor, loadingStatusId);

                    LoadingStatusManager.loadError({
                        key,
                        error: new Error('Error while generating ticker summary'),
                    });
                });
        },
    });

export default withTickerSummary;
