import { useAiToolsClient } from '@@api/services/aiTools/client';

type GenerateImageAltTextConfig = {
    imageUrl: string;
};

export const useGenerateImageAltText = () => {
    const { client: aiToolsClient } = useAiToolsClient();
    const { mutateAsync: postResource } = aiToolsClient.imageAltTextGenerator.post.useMutation();

    return ({ imageUrl }: GenerateImageAltTextConfig) =>
        postResource({
            body: {
                imageUrl,
            },
        }).then(({ body }) => ({
            altText: body.altText,
        }));
};

export type GenerateImageAltText = ReturnType<typeof useGenerateImageAltText>;

export default useGenerateImageAltText;
