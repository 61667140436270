import { useQuery } from '@tanstack/react-query';

const useVersion = () => {
    const url = `${window.location.origin}/version`;

    const query = () => fetch(url, { method: 'GET' }).then((res) => res.text());
    const refetchInterval = 60000;
    const { data } = useQuery({
        queryKey: ['version'],
        queryFn: query,
        refetchInterval,
        enabled: import.meta.env.MODE === 'production',
    });

    const isNewVersionAvailable = Boolean(data && data !== VERSION);

    return {
        newVersion: data,
        isNewVersionAvailable,
    };
};

export default useVersion;
