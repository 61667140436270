import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    type BlockerFunction,
    type Location,
    useBeforeUnload,
    useBlocker,
    useLocation,
} from 'react-router-dom';

import { matchSomePath, willPathChange, willUrlParamsChange } from '@@containers/LeavePrompt/utils';
import Modal from '@@containers/Modal/Modal';
import { ModalCloseEventSource } from '@@containers/Modal/types';
import useReactHookFormContext from '@@containers/ReactHookForm/useReactHookFormContext';
import { useMatchUrl } from '@@router/useMatchUrl';

export const DEFAULT_MESSAGE =
    'Do you really want to leave this site?\n\nChanges you made may not be saved.';

type BlockerConditionArgs = {
    currentLocation: Location;
    nextLocation: Location | null;
    matchUrl: string;
};

export type BlockerCondition = (args: BlockerConditionArgs) => boolean;

const defaultBlockerCondition: BlockerCondition = ({ currentLocation, nextLocation, matchUrl }) => {
    if (!nextLocation) {
        return true;
    }

    const shouldIgnorePath = matchSomePath(nextLocation.pathname, [
        matchUrl,
        matchUrl + '/relatedContent',
        matchUrl + '/relatedContent/:variantId',
        matchUrl + '/internallink',
    ]);

    return (
        !shouldIgnorePath &&
        (willPathChange(currentLocation, nextLocation) ||
            willUrlParamsChange(currentLocation, nextLocation, {
                ignore: [
                    'isEditingContent',
                    'selectedVariantId',
                    'eventIdsAfterToCreate',
                    'page',
                    'q',
                    /^toggle:/,
                ],
            }))
    );
};

export type Props = {
    messageKey?: string;
    blockerCondition?: BlockerCondition;
    resetOnConfirm?: boolean;
};

export const NavPrompt = ({
    // t('router.modal.content')
    messageKey = 'router.modal.content',
    blockerCondition,
    resetOnConfirm,
}: Props) => {
    const location = useLocation();
    const { t } = useTranslation();
    const matchUrl = useMatchUrl();
    const { reset } = useReactHookFormContext();

    const shouldBlock: BlockerFunction = ({ currentLocation, nextLocation }) =>
        !nextLocation?.state?.ignoreBlocker &&
        (blockerCondition
            ? blockerCondition({ currentLocation, nextLocation, matchUrl })
            : defaultBlockerCondition({ currentLocation, nextLocation, matchUrl }));

    const blocker = useBlocker(shouldBlock);

    const beforeConfirm = useCallback(() => {
        if (resetOnConfirm) {
            reset();
        }
    }, [reset, resetOnConfirm]);

    const handleLeave = useCallback(() => {
        beforeConfirm();

        blocker.proceed?.();
    }, [beforeConfirm, blocker]);

    const handleStay = () => blocker.reset?.();

    const condition = blockerCondition || defaultBlockerCondition;

    useBeforeUnload(
        useCallback(
            (event) => {
                if (
                    condition({
                        currentLocation: location,
                        nextLocation: null,
                        matchUrl,
                    })
                ) {
                    event.preventDefault();

                    // eslint-disable-next-line no-param-reassign
                    event.returnValue = DEFAULT_MESSAGE;
                }
            },
            [location, condition, matchUrl],
        ),
        { capture: true },
    );

    const handleClose = (closeEventSource) => {
        if (closeEventSource === ModalCloseEventSource.CANCEL_BUTTON) {
            handleLeave();
        } else {
            handleStay();
        }
    };

    return blocker.state === 'blocked' ? (
        <Modal
            type="confirm"
            reverseButtonOrder
            title={t('router.modal.title')}
            cancelButtonText={t('router.modal.leave')}
            confirmButtonText={t('router.modal.stay')}
            onClose={handleClose}
            onConfirm={handleStay}
        >
            <Trans i18nKey={messageKey} />
        </Modal>
    ) : null;
};

export default NavPrompt;
