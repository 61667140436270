import {
    createContext,
    createRef,
    type ReactNode,
    type RefObject,
    useContext,
    useMemo,
    useRef,
} from 'react';

import { type PageLayoutArea } from './types';

type PageLayoutElement = HTMLElement | null;
type ContextValue = Record<PageLayoutArea, RefObject<PageLayoutElement>>;
type PageLayoutContext = ContextValue;

const DEFAULT_CONTEXT_VALUE: ContextValue = {
    nav: createRef(),
    notificationBar: createRef(),
    head: createRef(),
    sidebar: createRef(),
    main: createRef(),
    toolbar: createRef(),
    foot: createRef(),
};

const PageLayoutContext = createContext<PageLayoutContext>(DEFAULT_CONTEXT_VALUE);

type Props = { children: ReactNode };

export const PageLayoutProvider = ({ children }: Props) => {
    const nav = useRef<PageLayoutElement>(null);
    const notificationBar = useRef<PageLayoutElement>(null);
    const head = useRef<PageLayoutElement>(null);
    const sidebar = useRef<PageLayoutElement>(null);
    const main = useRef<PageLayoutElement>(null);
    const toolbar = useRef<PageLayoutElement>(null);
    const foot = useRef<PageLayoutElement>(null);
    const refs = useMemo(() => ({ nav, notificationBar, head, sidebar, main, toolbar, foot }), []);

    return <PageLayoutContext.Provider value={refs}>{children}</PageLayoutContext.Provider>;
};

export const usePageLayout = () => useContext(PageLayoutContext);
