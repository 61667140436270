type IEnvConfig = {
    apiEnv: string;
    devToolbarDisabled: boolean;
    googleAnalyticsTrackingId: string | null;
};

type IEnvsConfig = Record<NonNullable<ImportMetaEnv['CDII_ENV']>, IEnvConfig>;

const envs: IEnvsConfig = {
    test: {
        apiEnv: 'test',
        devToolbarDisabled: false,
        googleAnalyticsTrackingId: null,
    },
    visualTest: {
        apiEnv: 'localhost:apiMock',
        devToolbarDisabled: true,
        googleAnalyticsTrackingId: null,
    },
    dynamic: {
        apiEnv: 'development',
        devToolbarDisabled: false,
        googleAnalyticsTrackingId: null,
    },
    development: {
        apiEnv: 'development',
        devToolbarDisabled: false,
        googleAnalyticsTrackingId: null,
    },
    staging: {
        apiEnv: 'staging',
        devToolbarDisabled: false,
        googleAnalyticsTrackingId: null,
    },
    demo: {
        apiEnv: 'demo',
        devToolbarDisabled: true,
        googleAnalyticsTrackingId: null,
    },
    preprod: {
        apiEnv: 'preprod',
        devToolbarDisabled: true,
        googleAnalyticsTrackingId: null,
    },
    production: {
        apiEnv: 'production',
        devToolbarDisabled: true,
        googleAnalyticsTrackingId: 'G-4KLGEMPV88',
    },
    prod: {
        apiEnv: 'production',
        devToolbarDisabled: true,
        googleAnalyticsTrackingId: 'G-4KLGEMPV88',
    },
};

export default envs;
