import { type FieldPath, type FieldValues } from 'react-hook-form';

import Checkbox, { type CheckboxProps } from '@@form/components/Checkbox';
import useFormField, { type UseFormFieldProps } from '@@form/hooks/useFormField';

export type CheckboxFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = CheckboxProps & UseFormFieldProps<TFieldValues, TName>;

const CheckboxField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: CheckboxFieldProps<TFieldValues, TName>,
) => {
    const {
        name,
        control,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const {
        field: { ref, ...formFieldProps },
    } = useFormField<TFieldValues, TName>({
        name,
        control,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    const checked = Boolean(formFieldProps.value);

    return (
        <Checkbox
            inputRef={ref}
            {...formFieldProps}
            required={required}
            {...rest}
            checked={checked}
        />
    );
};

export default CheckboxField;
