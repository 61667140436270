import { type TFunction } from 'i18next';

import { type GenerateImageAltText } from '@@api/hooks/useGenerateImageAltText';
import { type UploadFile } from '@@api/hooks/useUploadFile';
import { type Editor } from '@@editor/helpers';
import {
    ELEMENT_TYPES,
    type ElementType,
    INLINE_TYPES,
    TEXT_TYPES,
} from '@@editor/helpers/Element';

export const PLUGIN_NAMES = {
    PARAGRAPH: 'paragraph',
    SOFT_HYPHEN: 'softHyphen',
    FILE_UPLOAD: 'fileUpload',
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    TIKTOK: 'tiktok',
    THREADS: 'threads',
    FRONTEND_COMPONENT: 'frontendComponent',
    INSTAGRAM: 'instagram',
    YOUTUBE: 'youtube',
    ZATTOO: 'zattoo',
    VIDEOCMS: 'videocms',
    EMBEDDED_CONTENT: 'embeddedcontent',
    IMAGE: 'image',
    SLIDESHOW: 'slideshow',
    INFOBOX: 'infobox',
    IMPORT_INTERVIEW: 'importInterview',
    INLINE_INTERVIEW: 'inlineInterview',
    INTERVIEW: 'interviewSegment',
    DYNAMIC_TEASER: 'dynamicTeaser',
    QUOTE: 'quote',
    INLINE_QUOTE: 'inlineQuote',
    SUMMARY: 'summary',
    TICKER_SUMMARY: 'tickerSummary',
    POLL: 'poll',
    SNIPPET: 'snippet',
    EMBEDDED_COMPONENT: 'embeddedComponent',
    LIST: 'list',
    ITALIC: 'italic',
    SUBSCRIPT: 'subscript',
    SUPERSCRIPT: 'superscript',
    LINK: 'link',
    BOLD: 'bold',
    UNDERLINED: 'underlined',
    CODE: 'code',
    SEPARATOR: 'separator',
    WRAP_TEXT: 'wrapText',
    INSERT_TEXT: 'insertText',
    INSERT_HTML: 'insertHtml',
    DRAG_DROP: 'dnd',
    EMBEDDED_INFOBOX: 'embeddedInfobox',
    SPECIAL_CHARACTERS: 'specialCharacters',
    EMBEDDED_POLL: 'embeddedPoll',
    EMBEDDED_IFRAME: 'embeddedIframe',
    EMBEDDED_SNIPPET: 'embeddedSnippet',
    AUTO_REPLACE_TEXT: 'autoReplaceText',
    NO_BREAKSPACE: 'noBreakSpace',
    SINGLE_LINE: 'singleLine',
    SINGLE_EMBED: 'singleEmbed',
    COMMENT: 'comment',
    HEADINGS_LAYOUT: 'headingsLayout',
    TEASER_GENERATOR: 'teaserGenerator',
    HEADINGS_TEASERS: 'headingsTeasers',
    SPELL_CHECKER: 'spellChecker',
    TEXT_DIFF: 'textDiff',
} as const;

export type PluginName = ValueOf<typeof PLUGIN_NAMES>;

export const MARK_PLUGINS = [
    PLUGIN_NAMES.BOLD,
    PLUGIN_NAMES.ITALIC,
    PLUGIN_NAMES.UNDERLINED,
    PLUGIN_NAMES.SUBSCRIPT,
    PLUGIN_NAMES.SUPERSCRIPT,
    PLUGIN_NAMES.TEXT_DIFF,
];

export const ELEMENT_TYPES_TO_PLUGIN_NAMES = {
    ...Object.keys(ELEMENT_TYPES).reduce((result, typeKey) => {
        if (typeKey in TEXT_TYPES) {
            return Object.assign(result, {
                [ELEMENT_TYPES[typeKey]]: PLUGIN_NAMES.PARAGRAPH,
            });
        } else if (typeKey in INLINE_TYPES) {
            return Object.assign(result, {
                [INLINE_TYPES[typeKey]]: PLUGIN_NAMES[typeKey],
            });
        } else if (typeKey in PLUGIN_NAMES) {
            return Object.assign(result, {
                [ELEMENT_TYPES[typeKey]]: PLUGIN_NAMES[typeKey],
            });
        }

        return result;
    }, {}),
    // Some types cannot be auto-mapped
    [ELEMENT_TYPES.EXTERNAL_LINK]: PLUGIN_NAMES.LINK,
    [ELEMENT_TYPES.INTERNAL_LINK]: PLUGIN_NAMES.LINK,
    [ELEMENT_TYPES.ORDERED_LIST]: PLUGIN_NAMES.LIST,
    [ELEMENT_TYPES.UNORDERED_LIST]: PLUGIN_NAMES.LIST,
    [ELEMENT_TYPES.INTERVIEW_SEGMENT]: PLUGIN_NAMES.INTERVIEW,
    [ELEMENT_TYPES.CS_SLIDESHOW]: PLUGIN_NAMES.SLIDESHOW,
    [ELEMENT_TYPES.VIDEOCMS_LIVESTREAM]: PLUGIN_NAMES.VIDEOCMS,
};

export const INLINE_ELEMENT_TYPES_TO_PLUGIN_NAMES = {
    ...ELEMENT_TYPES_TO_PLUGIN_NAMES,
    [ELEMENT_TYPES.INTERVIEW_SEGMENT]: PLUGIN_NAMES.INLINE_INTERVIEW,
    [ELEMENT_TYPES.QUOTE]: PLUGIN_NAMES.INLINE_QUOTE,
    [ELEMENT_TYPES.SUMMARY_LIST]: PLUGIN_NAMES.SUMMARY,
};

export const PLUGIN_ICON_NAMES = {
    [PLUGIN_NAMES.PARAGRAPH]: 'align-left',
    [PLUGIN_NAMES.SOFT_HYPHEN]: 'hyphen-sharp-regular',
    [PLUGIN_NAMES.FACEBOOK]: 'facebook-brands',
    [PLUGIN_NAMES.TWITTER]: 'x-twitter-brands',
    [PLUGIN_NAMES.TIKTOK]: 'tiktok-brands',
    [PLUGIN_NAMES.THREADS]: 'threads-brands',
    [PLUGIN_NAMES.FRONTEND_COMPONENT]: 'plug',
    [PLUGIN_NAMES.INSTAGRAM]: 'instagram-brands',
    [PLUGIN_NAMES.YOUTUBE]: 'youtube-brands',
    [PLUGIN_NAMES.ZATTOO]: 'zattoo-brands',
    [PLUGIN_NAMES.VIDEOCMS]: 'square-regular-play',
    [PLUGIN_NAMES.EMBEDDED_CONTENT]: 'embed-sharp',
    [PLUGIN_NAMES.IMAGE]: 'image-regular',
    [PLUGIN_NAMES.SLIDESHOW]: 'images-regular',
    [PLUGIN_NAMES.INFOBOX]: 'square-info',
    [PLUGIN_NAMES.INTERVIEW]: 'message-regular',
    [PLUGIN_NAMES.IMPORT_INTERVIEW]: 'message-regular',
    [PLUGIN_NAMES.INLINE_INTERVIEW]: 'message-regular',
    [PLUGIN_NAMES.DYNAMIC_TEASER]: 'eye-regular',
    [PLUGIN_NAMES.QUOTE]: 'quote-right',
    [PLUGIN_NAMES.INLINE_QUOTE]: 'quote-right',
    [PLUGIN_NAMES.POLL]: 'square-poll-vertical',
    [PLUGIN_NAMES.SNIPPET]: 'puzzle-piece-simple',
    [PLUGIN_NAMES.EMBEDDED_COMPONENT]: 'puzzle-piece',
    [PLUGIN_NAMES.LIST]: ['list-ul-sharp-regular', 'list-ol-sharp-regular'],
    [PLUGIN_NAMES.ITALIC]: 'italic-sharp-regular',
    [PLUGIN_NAMES.SUBSCRIPT]: 'subscript-sharp-regular',
    [PLUGIN_NAMES.SUPERSCRIPT]: 'superscript-sharp-regular',
    [PLUGIN_NAMES.LINK]: ['link-simple-sharp-regular', 'link-simple-slash-sharp-regular'],
    [PLUGIN_NAMES.BOLD]: 'bold-sharp-regular',
    [PLUGIN_NAMES.UNDERLINED]: 'underline-sharp-regular',
    [PLUGIN_NAMES.CODE]: 'code-sharp-regular',
    [PLUGIN_NAMES.SEPARATOR]: 'minus',
    [PLUGIN_NAMES.SPECIAL_CHARACTERS]: 'dollar-sign-sharp-regular',
    [PLUGIN_NAMES.EMBEDDED_INFOBOX]: 'puzzle-piece',
    [PLUGIN_NAMES.EMBEDDED_POLL]: 'puzzle-piece',
    [PLUGIN_NAMES.EMBEDDED_IFRAME]: 'puzzle-piece',
    [PLUGIN_NAMES.EMBEDDED_SNIPPET]: 'puzzle-piece',
    [PLUGIN_NAMES.COMMENT]: 'comment-lines-sharp-regular',
    [PLUGIN_NAMES.SPELL_CHECKER]: 'spell-check',
    [PLUGIN_NAMES.SUMMARY]: 'bernand',
    [PLUGIN_NAMES.TICKER_SUMMARY]: 'bernand',
} as const;

export type PluginList = PluginName | PluginConfig;

type CommentPluginOptions = {
    isCommentClickable?: boolean;
    unsupportedElementTypes?: ElementType[];
};

type ReplacementFunction = (editor: Editor, options: Record<string, any>) => string;
export type PluginConfigOptions = {
    reducedUI: boolean;
    t: TFunction;
    uploadFile?: UploadFile;
    generateImageAltText?: GenerateImageAltText;
    plugins: PluginList[];
    text?: string | string[];
    id?: string;
    replacements?: {
        needle: string;
        replacement: string | ReplacementFunction;
    }[];
} & CommentPluginOptions;

export type PluginConfig = {
    name: PluginName;
    when?: (option?: any, config?: any) => boolean;
    options?: Partial<PluginConfigOptions>;
};

// We want to keep this approach for the optional key
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export type OptionsPerPlugin = {
    [key in PluginName]?: Record<string, any>;
};

export type PluginOptions = {
    reducedUI?: boolean;
    t?: TFunction;
    uploadFile?: UploadFile;
    generateImageAltText?: GenerateImageAltText;
    plugins: Plugin[];
    text?: string | string[];
    id?: string;
} & CommentPluginOptions;

export type Plugin = {
    name: PluginName;
    when: (option?: any, config?: any) => boolean;
    options: PluginOptions;
};

export type PluginFunction = (editor: Editor, ...args: any[]) => Editor;
