import { mapValues } from 'lodash-es';

import { type CropMarks } from '@@api/utils/schemas/schemas';
import { isNumberish } from '@@utils/string';

import { PERCENT_100 } from '../constants';

export const getImageRatio = (img: CropMarks) => (img.width / img.height).toFixed(4);

// takes deserialized cropmarks
export const validateCropMarks = (cropMarks: CropMarks, { minWidth = 0, minHeight = 0 } = {}) => {
    const { x, y, width, height } = cropMarks;

    const validMaxWidth = x + width <= PERCENT_100 && x >= 0;
    const validMaxHeight = y + height <= PERCENT_100 && y >= 0;
    const validMinWidth = Math.round(width) >= minWidth;
    const validMinHeight = Math.round(height) >= minHeight;

    return validMinHeight && validMaxHeight && validMinWidth && validMaxWidth;
};

export const validateAspectRatio = (crop: CropMarks, aspectRatio: number) => {
    if (aspectRatio) {
        return Number(getImageRatio(crop)).toFixed(2) === aspectRatio.toFixed(2);
    }

    return true;
};

export const deserializeCropMarks = (cropMarks: CropMarks) =>
    mapValues(cropMarks, (o) => (isNumberish(o) ? o * PERCENT_100 : o));

export const serializeCropMarks = (cropMarks: CropMarks) =>
    mapValues(cropMarks, (o) => {
        if (isNumberish(o)) {
            if (o < 0) {
                return 0;
            }
            if (o > PERCENT_100) {
                return 1;
            }

            return o / PERCENT_100;
        }

        return o;
    });

export const getAspectRatioNumber = (aspectRatio?: string | number): number | undefined => {
    if (typeof aspectRatio === 'undefined') {
        return;
    }
    if (typeof aspectRatio === 'number') {
        return aspectRatio;
    }
    if (Number(aspectRatio)) {
        return Number(aspectRatio);
    }

    const [x, y] = aspectRatio.split(':').map(Number);

    return x / y;
};
