import { type ReactNode } from 'react';

import useTenants from '@@api/hooks/resources/useTenants';
import { type TenantRouter } from '@@api/services/tenant/client';
import { type Tenant } from '@@api/services/tenant/schemas';
import { type RequestQuery } from '@@api/types';
import { getQueryParams } from '@@api/utils/getQueryParams';
import Autocomplete from '@@form/components/Autocomplete';
import { type TextInputProps } from '@@form/components/TextInput';
import { convertToNumber } from '@@utils/idUtils';

type TenantOption = {
    id: Tenant['id'];
    name: string;
};

export const TenantOption = ({ id, name }: TenantOption) => (
    <option key={id} value={id}>
        {name}
    </option>
);

type BaseProps = {
    params?: RequestQuery<TenantRouter['tenant']['getAll']>;
    activeTenants?: Tenant['id'][];
    isFullList?: boolean;
    placeholder?: string;
    inputRef?: TextInputProps['inputRef'];
    label?: TextInputProps['label'];
    required?: TextInputProps['required'];
    disabled?: boolean;
    noOptionsText?: ReactNode;
    fullWidth?: boolean;
    openOnFocus?: boolean;
    excludeFreeMedia?: boolean;
    disableClearable?: boolean;
};

export type TenantsAutocompleteProps =
    | (BaseProps & {
          multiple: true;
          value: Tenant['id'][];
          onChange: (value: Tenant['id'][]) => void;
      })
    | (BaseProps & {
          multiple?: false;
          value: Tenant['id'] | null;
          onChange: (value: Tenant['id']) => void;
      });

const TenantsAutocomplete = (props: TenantsAutocompleteProps) => {
    const {
        params = {},
        activeTenants,
        isFullList,
        value,
        onChange,
        multiple,
        excludeFreeMedia,
        ...rest
    } = props;

    const tenantParams = getQueryParams<TenantRouter['tenant']['getAll']>(params);
    const { data = [], isLoading } = useTenants({
        params: tenantParams,
        isFullList,
        excludeFreeMedia,
    });

    const options = activeTenants
        ? data.filter((tenant) => activeTenants.includes(tenant.id))
        : data;

    const currentValue = multiple
        ? (value || [])
              .map((item) => options.find((option) => option.id === convertToNumber(item)))
              .filter((x): x is NonNullable<typeof x> => Boolean(x))
        : options.find((option) => option.id === convertToNumber(value)) ?? null;

    const handleChange = (event, value) => {
        const adjustedValue = Array.isArray(value)
            ? value.map((item) => item.id)
            : value?.id ?? value;

        onChange(adjustedValue);
    };

    return (
        <Autocomplete
            loading={isLoading}
            onChange={handleChange}
            value={currentValue}
            options={options}
            multiple={multiple}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            {...rest}
        />
    );
};

export default TenantsAutocomplete;
