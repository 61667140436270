import { Button, IconButton, Tooltip } from '@mui/material';
import { pick } from 'lodash-es';
import { type MouseEvent, type ReactNode } from 'react';

import ContextMenuItem from '@@components/ContextMenu/ContextMenuItem';
import Icon from '@@components/Icon';

import { useActionMenu } from './ActionMenuContext';

type Props = {
    action: IContextMenuAction;
    children?: ReactNode;
    disabled?: boolean;
    isInline?: boolean;
    tooltip?: string | boolean;
    onClick?: (e: MouseEvent, action: IContextMenuAction) => void;
};

const ActionMenuItem = ({ action, ...rest }: Props) => {
    const { children, isInline, onClick } = rest;
    const { iconName, title } = action;
    const { enableTooltips } = useActionMenu();

    const handleClick = (e) => {
        onClick?.(e, action);
    };

    if (isInline) {
        const button =
            iconName && !children ? (
                <IconButton
                    {...pick(rest, ['disabled', 'href', 'rel', 'target'])}
                    title={!enableTooltips ? title : undefined}
                    onClick={handleClick}
                >
                    <Icon name={iconName} />
                </IconButton>
            ) : (
                <Button
                    startIcon={iconName && <Icon name={iconName} />}
                    {...pick(rest, ['disabled', 'href', 'rel', 'target'])}
                    title={!enableTooltips ? title : undefined}
                    onClick={handleClick}
                >
                    {children}
                </Button>
            );

        return enableTooltips ? (
            <Tooltip title={title}>
                <span>{button}</span>
            </Tooltip>
        ) : (
            button
        );
    }

    return <ContextMenuItem {...action} {...rest} onClick={handleClick} />;
};

export default ActionMenuItem;
