import { FormControl, FormHelperText, FormLabel, styled } from '@mui/material';
import { omit } from 'lodash-es';
import { type FieldError, type FieldPath, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ContentItemList, {
    type Props as ContentItemListProps,
} from '@@containers/RelatedContent/ContentItemList';
import useFormField, { type UseFormFieldProps } from '@@form/hooks/useFormField';
import { type FormFieldError } from '@@form/hooks/useReactHookFormFieldError';
import { getErrorMessage } from '@@form/utils/getErrorMessage';

const Wrapper = styled('div')<{ $hasCaption?: boolean }>(({ $hasCaption, theme }) => ({
    position: 'relative',
    marginTop: $hasCaption ? theme.spacing(2) : undefined,
    marginLeft: `-${theme.spacing(4)}`,
    marginRight: `-${theme.spacing(4)}`,
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

type ContentItemListBaseProps = ContentItemListProps & {
    className?: string;
    error?: FormFieldError | FieldError;
    label?: string;
    caption?: string;
    required?: boolean;
};

const ContentItemListBase = (props: ContentItemListBaseProps) => {
    const { className, error, label, required, ...otherProps } = props;

    const { t } = useTranslation();

    const errorMessage = getErrorMessage(error, t);

    const helperText = errorMessage;

    return (
        <FormControl error={Boolean(error)} required={required}>
            {label && <StyledFormLabel>{label}</StyledFormLabel>}
            <Wrapper className={className} $hasCaption={Boolean(otherProps.caption)}>
                <ContentItemList {...otherProps} />
            </Wrapper>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

type ContentItemListFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ContentItemListBaseProps, 'onChange' | 'value'> & UseFormFieldProps<TFieldValues, TName>;

const ContentItemListField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: ContentItemListFieldProps<TFieldValues, TName>,
) => {
    const {
        name,
        control,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const { field: formFieldProps } = useFormField<TFieldValues, TName>({
        name,
        control,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    return <ContentItemListBase {...omit(formFieldProps, ['ref'])} {...rest} required={required} />;
};

export default ContentItemListField;
