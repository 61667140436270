import { overEvery } from 'lodash-es';

import { BAD_REQUEST } from '@@api/constants/errorCodes';
import { type Metadata, type Tag } from '@@api/services/metadata/schemas';
import { type Category, type Tenant } from '@@api/services/tenant/schemas';
import { DEFAULT_TENANT_ID } from '@@containers/metadata/constants';
import { DEFAULT_TEASER_ID } from '@@containers/Teaser/constants';
import { type Teaser } from '@@containers/Teaser/types';
import { getTenantSpecificTeaserVariant } from '@@containers/Teaser/utils';
import { convertToNumber } from '@@scripts/utils/idUtils';

import { COLUMN_TYPE, type ColumnType } from './components/Column/constants';
import { SectionContentItemTypes } from './constants';
import { type PlacementColumn, type PlacementDragSourceItem } from './types';

const SEPARATOR = '_';

export const PLACEHOLDER_ID = `0${SEPARATOR}0`;

export const serializeCategoryColumnId = (tenantId: Tenant['id'], categoryId: Category['id']) =>
    `${COLUMN_TYPE.CATEGORY}${tenantId}${SEPARATOR}${categoryId}`;

export const serializeTagColumnId = (tenantId: Tenant['id'], tagId: Tag['id']) =>
    `${COLUMN_TYPE.TAG}${tenantId}${SEPARATOR}${tagId}`;

export const deserializeColumnId = (columnId: PlacementColumn): [ColumnType, PlacementColumn] => {
    const columnType = columnId[0] as ColumnType;
    const id = columnId.slice(1);

    return [columnType, id];
};

export const deserializeColumnTypeId = (
    columnId: PlacementColumn,
): [Tenant['id'], Category['id'] | Tag['id']] => {
    const id = deserializeColumnId(columnId)[1];

    const [tenantId, pageTypeId] = id.split(SEPARATOR).map(convertToNumber);

    return [tenantId, pageTypeId];
};

export const getOpenColumnCategoriesByTenantId = (
    tenantId: Tenant['id'],
    columns: PlacementColumn[],
): Category['id'][] =>
    columns
        .filter((columnId) => deserializeColumnId(columnId)[0] === COLUMN_TYPE.CATEGORY)
        .map((columnId) => deserializeColumnTypeId(columnId))
        .filter(([tenantId_]) => tenantId_ === tenantId)
        .map(([, categoryId]) => categoryId);

export const getOpenColumnTagsByTenantId = (
    tenantId: Tenant['id'],
    columns: PlacementColumn[],
): Tag['id'][] =>
    columns
        .filter((columnId) => deserializeColumnId(columnId)[0] === COLUMN_TYPE.TAG)
        .map((columnId) => deserializeColumnTypeId(columnId))
        .filter(([tenantId_]) => tenantId_ === tenantId)
        .map(([, tagId]) => tagId);

export const getSectionContentItemId = (item, index) => {
    if (item.sectionContentItemType === SectionContentItemTypes.PLACEHOLDER) {
        if (item.placeholderType === 'category') {
            return `${item.placeholderTenantId}_${item.placeholderCategoryId}_${item.placeholderIndexPosition}`;
        }

        // We can actually have duplicate placeholder identifiers, that's why we need to add the index as well here!
        // Duplicate ids will break the UI (since they are used as a `key` on the list item component)!
        return `${item.placeholderTenantId}_${item.placeholderIdentifier}_${item.placeholderIndexPosition}_i${index}`;
    }

    return item.metadataId;
};

export const isSameNotNull = (property) => (source, target) =>
    source[property] !== null && source[property] === target[property];

export const isSameProperties = (properties) => overEvery(properties.map(isSameNotNull));
export const isSameColumn = isSameProperties(['columnId']);
export const isSameSectioncontentItemType = isSameNotNull('sectionContentItemType');
export const isSamePlaceholderType = isSameNotNull('placeholderType');
export const isSameMetadataId = isSameNotNull('metadataId');
export const isSamePlaceholderCategory = isSameProperties([
    'placeholderType',
    'placeholderTenantId',
    'placeholderCategoryId',
    'placeholderIndexPosition',
]);
export const isSamePlaceholderCustom = isSameProperties([
    'placeholderType',
    'placeholderTenantId',
    'placeholderTitle',
    'placeholderIdentifier',
    'placeholderIndexPosition',
]);

const placeholderComparators = {
    category: isSamePlaceholderCategory,
    custom: isSamePlaceholderCustom,
};

const isSamePlaceholder = (a, b) => placeholderComparators[a.placeholderType](a, b);

const sectionContentItemComparators = {
    [SectionContentItemTypes.DIRECT_PLACEMENT]: isSameMetadataId,
    [SectionContentItemTypes.PLACEHOLDER]: isSamePlaceholder,
};

export const isEqualSectionContentItem = (a, b) => {
    const compareSectionContentItem = sectionContentItemComparators[a.sectionContentItemType];

    return isSameSectioncontentItemType(a, b) && compareSectionContentItem(a, b);
};

export const isPlacementTenantError = (body) =>
    'status' in body &&
    'message' in body &&
    body.status === BAD_REQUEST &&
    body.message &&
    body.message.includes('are not assigned to tenant');

export const getBookmarkedMetadataList = (metadataList, bookmarkList) =>
    metadataList.map((metadata) => {
        if (!metadata) {
            return null;
        }

        const bookmark = bookmarkList.find((bookmark) => bookmark?.metadataId === metadata?.id);

        return {
            ...metadata,
            bookmarkId: bookmark?.id || null,
        };
    });

export const getMetadataListForBookmarks = (bookmarkList, metadataList) =>
    bookmarkList.map((bookmark) => {
        const metadata = metadataList.find((metadata) => metadata?.id === bookmark?.metadataId);

        if (!metadata) {
            return metadata;
        }

        return {
            ...metadata,
            bookmarkId: bookmark?.id || null,
        };
    });

export const getDefaultVariantTeaser = (entity: Metadata) =>
    getTenantSpecificTeaserVariant(entity, DEFAULT_TENANT_ID, DEFAULT_TEASER_ID);

export const getPlacementDragSourceItem = (
    entity: (Metadata & Teaser) | null | undefined,
    dragSourceItemProps?: PlacementDragSourceItem,
) =>
    ({
        ...dragSourceItemProps,
        metadataId: entity?.id,
        tenantIds: entity?.tenantIds || [],
    }) as PlacementDragSourceItem;
