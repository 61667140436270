import { type ElementType, type ReactNode } from 'react';

import { type Editor, ReactEditor } from '@@editor/helpers';
import { type ElementAttributes, type EmbedElement } from '@@editor/helpers/Element';
import { type MimeTypeConfig, type ToolbarConfig } from '@@editor/typings/Embed';
import { type PluginName } from '@@editor/typings/UnityPlugins';
import { wrapDisplayName } from '@@utils/React';

type Props = {
    attributes?: ElementAttributes;
    editor: Editor;
    element: EmbedElement;
    children?: ReactNode;
    type?: PluginName;
    contentEditable?: boolean;
    component?: ElementType;
    overlayComponent?: ElementType | null;
    placeholderComponent?: ElementType;
    placeholderText?: string;
    placeholderIconName?: string;
    toolbarConfig?: ToolbarConfig;
    mimetypeConfigs?: MimeTypeConfig;
    tooltipContent?: string;
    tenantIds?: number[];
};

export default () => (WrappedComponent) => {
    const SlateNode = (props: Props) => {
        const { attributes, children, editor, element } = props;
        const readOnly = ReactEditor.isReadOnly(editor);
        const isVoid = editor.isVoid(element);

        if (isVoid) {
            return (
                <div {...attributes}>
                    <div contentEditable={false}>
                        <WrappedComponent readOnly={readOnly} {...props} />
                    </div>

                    {children}
                </div>
            );
        }

        return (
            <div {...attributes}>
                <WrappedComponent readOnly={readOnly} {...props} />
            </div>
        );
    };

    SlateNode.displayName = wrapDisplayName(WrappedComponent, 'asSlateNode');

    return SlateNode;
};
