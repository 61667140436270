import { type ReactNode } from 'react';

import { HOTKEYS } from '@@editor/constants';
import { type Editor } from '@@editor/helpers';
import handleHotkey from '@@editor/plugins/utils/handleHotkey';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';

const MARK = 'italic';

type Props = {
    attributes: UnknownObject;
    children: ReactNode;
};

const ItalicMark = (props: Props) => <em {...props.attributes}>{props.children}</em>;

export const withItalic = (editor: Editor) =>
    Object.assign(editor, {
        renderLeaf: renderLeaf(editor, [[MARK, ItalicMark]]),
        handleHotkey: handleHotkey(editor, [
            [HOTKEYS.ITALIC, (editor: Editor) => editor.toggleMark(MARK)],
        ]),
    });

export default withItalic;
