import { ThemeProvider } from '@mui/material';

import { Editor } from '@@editor/helpers';
import { Element, ELEMENT_TYPES } from '@@editor/helpers/Element';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import Poll from './Poll';
import { PluginsWrapper } from '../../components/PluginsWrapper';
import EmbedWrapper from '../../embed/components/EmbedWrapper';
import { editorPlaceholderTheme } from '../../styles';

export const TYPE = PLUGIN_NAMES.POLL;
export const NODE_TYPE = ELEMENT_TYPES.POLL;
export const INLINE_EDITABLE_CHILDREN_TYPES = [
    ELEMENT_TYPES.POLL_QUESTION,
    ELEMENT_TYPES.POLL_ANSWER,
];

const EmptyComponent = ({ children }) => <PluginsWrapper>{children}</PluginsWrapper>;

const PollNode = (props) => {
    const { editor, element } = props;

    const generateTheme = (theme) =>
        Element.isTemplateElement(element) ? editorPlaceholderTheme(theme) : theme;

    const isInlineEdited = !Editor.isVoid(editor, element);
    const component = isInlineEdited ? EmptyComponent : Poll;

    const actions: string[] = ['delete'];

    if (!isInlineEdited) {
        actions.push('edit');
    }

    return (
        <ThemeProvider theme={generateTheme}>
            <EmbedWrapper
                {...props}
                widthType="small"
                type={TYPE}
                contentEditable={isInlineEdited}
                component={component}
                placeholderComponent={component}
                toolbarConfig={{
                    infos: {
                        iconName: PLUGIN_ICON_NAMES[TYPE],
                        title: editor.t('editor.plugin.poll.frame.title'),
                    },
                    actions,
                }}
            />
        </ThemeProvider>
    );
};

export default PollNode;
