import { get } from 'lodash-es';

import AutofitIframe from '@@components/AutofitIframe/AutofitIframe';
import { IFRAME_DEFAULT_HEIGHT } from '@@constants/Embeds';
import { type Editor } from '@@editor/helpers';
import { type EmbeddedContentElement, type EmbeddedIframeElement } from '@@editor/helpers/Element';

import EmbeddedContentPlaceholder from './EmbeddedContentPlaceholder';
import EmbedComponentLoader from '../components/EmbedComponentLoader';

type Props = {
    editor: Editor;
    element: EmbeddedContentElement | EmbeddedIframeElement;
};

const EmbeddedContentFrame = (props: Props) => {
    const { element, editor } = props;

    const { data } = element;

    if (!data.src && !data.originalSrc) {
        return <EmbeddedContentPlaceholder {...props} />;
    }

    return (
        <EmbedComponentLoader editor={editor} element={element}>
            {({ onLoad }) => (
                <AutofitIframe
                    height={get(data, 'embed.height.desktop', IFRAME_DEFAULT_HEIGHT)}
                    src={data.src as string}
                    srcDoc={data.originalSrc as string}
                    title="Embedded Content Frame"
                    onLoad={onLoad}
                />
            )}
        </EmbedComponentLoader>
    );
};

export default EmbeddedContentFrame;
