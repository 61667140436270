import { DialogTitle, paperClasses, styled } from '@mui/material';
import { omit } from 'lodash-es';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import InfoTooltip from '@@components/InfoTooltip';
import Modal from '@@containers/Modal/ModalWithLeavePrompt';
import { Editor, type Element } from '@@editor/helpers';

import InterviewImportForm from '../../interviewSegment/components/InterviewImportForm';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    paddingRight: theme.spacing(3),
}));

type Props = {
    children: ReactNode;
    editor: Editor;
};

const InterviewModal = (props: Props) => {
    const { editor, children } = props;
    const { t } = useTranslation();

    const slateModalState = editor.isImportInterviewModalVisible();

    const { at } = slateModalState || {
        at: null,
    };

    const isModalOpen = Boolean(slateModalState);

    const handleReset = () => {
        editor.hideImportInterviewModal();
    };

    const handleClose = () => {
        handleReset();
    };

    const handleSubmit = (formData) => {
        const elements: Element[] = formData.parsedText;

        // We spread here to prevent mutation of the original array
        [...elements].reverse().forEach((element) => {
            // The displayError boolean is not useful outside of the import modal,
            // we need to strip the elements from it before inserting them
            const slateElement = [{ ...omit(element, ['displayError']) } as Element];

            Editor.insertElement(editor, slateElement, { at });
        });

        handleReset();
    };

    return (
        <>
            <Modal
                disableRestoreFocus
                isOpen={isModalOpen}
                renderTitle={() => (
                    // t('editor.plugin.interviewSegment.importInterview.tooltip')
                    <InfoTooltip
                        title="editor.plugin.interviewSegment.importInterview.tooltip"
                        iconSize="medium"
                    >
                        <StyledDialogTitle>
                            {t('editor.plugin.interviewSegment.importInterview')}
                        </StyledDialogTitle>
                    </InfoTooltip>
                )}
                sx={(theme) => ({
                    [`.${paperClasses.root}`]: {
                        width: theme.fixed.editor.importInterviewDialog.width,
                    },
                })}
                onClose={handleClose}
            >
                <InterviewImportForm
                    editor={editor}
                    onCancel={handleClose}
                    onSubmit={handleSubmit}
                />
            </Modal>

            {children}
        </>
    );
};

export default InterviewModal;
