import { type RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: '*',
        children: [
            {
                path: '*',
                lazy: async () => {
                    const { Isolated } = await import('@@routes/isolated/Isolated');

                    return { Component: Isolated };
                },
            },
        ],
    },
];
