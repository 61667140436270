import { get, omit } from 'lodash-es';

import { Editor } from '@@editor/helpers';
import {
    type Element,
    type ELEMENT_TYPES,
    MIME_TYPES,
    type VideocmsElement,
    type VideocmsLivestreamElement,
} from '@@editor/helpers/Element';
import makeState from '@@editor/utils/makeState';
import { parseEmbedCode } from '@@utils/socialMedia/videocms';

import { createEmbedBlock, unifyFormData } from './../utils';

// attributes from the iframe embed code
export interface IParsedVideoCmsEmbedCode {
    url: string | URL;
    params: {
        title?: string;
        lead?: string;
        previewUrl?: string;
        mimetype?: string;
        uv?: string;
        id?: string;
    };
}

interface ITransformedParams {
    caption: Element[];
}

interface IEmbedOptions {
    nodeType: typeof ELEMENT_TYPES.VIDEOCMS | typeof ELEMENT_TYPES.VIDEOCMS_LIVESTREAM;
    withCaption?: boolean;
}

// outputs valid contentAttributes for this embed
export const transformParams = (
    parsedEmbedCode: IParsedVideoCmsEmbedCode,
    options: IEmbedOptions = {} as IEmbedOptions,
): ITransformedParams => {
    const { title, lead } = parsedEmbedCode.params;

    return options.withCaption !== false && (title || lead)
        ? { caption: makeState([title, lead].filter(Boolean).join(': ')) }
        : ({} as ITransformedParams);
};

const embedVideoCms = (
    editor: Editor,
    formData,
    initialOptions: IEmbedOptions = {} as IEmbedOptions,
    options?,
) => {
    const unifiedFormData = unifyFormData(formData);
    const embedCode = get(unifiedFormData, 'embed.src');

    const parsedEmbedCode = parseEmbedCode(embedCode);
    const { url, params } = parsedEmbedCode;

    // always prefer user input over auto-filled fields
    const embedDefaults = {
        ...transformParams(parsedEmbedCode, initialOptions),
        ...omit(unifiedFormData.embed, ['src', 'captionTitle']),
    };

    switch (params.mimetype) {
        case MIME_TYPES.VIDEOCMS_LIVESTREAM: {
            const block = createEmbedBlock<VideocmsLivestreamElement>(
                {
                    mimetype: MIME_TYPES.VIDEOCMS_LIVESTREAM,
                    src: url && url.toString(),
                    originalSrc: embedCode,
                    embed: embedDefaults,
                },
                initialOptions.nodeType,
            );

            Editor.insertElement(editor, block, options);

            return editor;
        }
        default: {
            const block = createEmbedBlock<VideocmsElement>(
                {
                    loadingStatusId: crypto.randomUUID(),
                    mimetype: MIME_TYPES.VIDEOCMS,
                    src: url.toString(),
                    originalSrc: embedCode,
                    embed: {
                        ...embedDefaults,
                        // We assign the Image data by different ways (embedDefaults + extra values)
                        // and TS doesn't like it. It needs more time to be solved
                        // @ts-expect-error
                        previewImage: params.previewUrl
                            ? {
                                  url: params.previewUrl,
                                  elvisId: null,
                                  caption: null,
                              }
                            : null,
                    },
                },
                initialOptions.nodeType,
            );

            Editor.insertElement(editor, block, options);

            return editor;
        }
    }
};

export const createEmbedVideoCms = (initialOptions) => (editor: Editor, formData, options?) =>
    embedVideoCms(editor, formData, initialOptions, options);
