import { type UploadFile } from '@@api/hooks/useUploadFile';
import { type FilerepoFile } from '@@api/services/metadata/schemas';
import { LoadingStatusManager } from '@@containers/LoadingStatusManager';
import { generateKeyForRichTextEditorLoadingStatus } from '@@containers/LoadingStatusManager/utils';
import { type Editor, type Node } from '@@editor/helpers';
import {
    type EmbedData,
    type EmbedElement,
    type ImageElement,
    NameSource,
} from '@@editor/helpers/Element';
import { validateImageFile } from '@@form/utils/validators/image';
import { type AssetPayload } from '@@utils/assets';

import { updateFileNodeData } from './utils';

type DataToUpdate = Pick<FilerepoFile, 'id' | 'title'> &
    Pick<FilerepoFile['metadata'], 'name' | 'caption' | 'credit'> & {
        src: FilerepoFile['_links']['data']['href'];
    } & Pick<EmbedData, 'nameSource' | 'naturalWidth' | 'naturalHeight'>;

type UpdateEmbedData = (data: DataToUpdate) => EmbedData;

type Options = {
    updateEmbedData: UpdateEmbedData;
    uploadFile: UploadFile;
    updateSource?: boolean;
};

export const uploadFileHelper =
    (options: Options) =>
    async (
        editor: Editor,
        block: EmbedElement,
        file: File | string | AssetPayload,
        previousNode?: Node,
    ) => {
        const { updateEmbedData, updateSource = true } = options;
        const errors = await validateImageFile(file);

        if (errors.length === 0) {
            const uploadKey = generateKeyForRichTextEditorLoadingStatus({
                editorId: editor.id,
                loadingStatusId: block.data.loadingStatusId,
                type: 'uploadFile',
            });

            const loadKey = generateKeyForRichTextEditorLoadingStatus({
                editorId: editor.id,
                loadingStatusId: block.data.loadingStatusId,
            });

            LoadingStatusManager.load({ key: uploadKey });

            return options
                .uploadFile({
                    file,
                    onUploadProgress: (progressEvent) => {
                        LoadingStatusManager.loadProgress({
                            key: uploadKey,
                            progress: progressEvent.lengthComputable
                                ? {
                                      value: progressEvent.loaded,
                                      max: progressEvent.total!,
                                      progress: (progressEvent.loaded / progressEvent.total!) * 100,
                                  }
                                : { value: 0, max: progressEvent.total!, progress: 0 },
                        });
                    },
                })
                .then((payload) => {
                    const { id, title, _links, mediaType, metadata, height, width } = payload.body;
                    const payloadSrc = _links?.data.href;
                    const { src, mimetype, originalSrc } = (block as ImageElement).data;
                    const { caption, credit, name } = metadata;

                    updateFileNodeData(editor, block, {
                        mimetype: mimetype || mediaType,
                        src: updateSource ? payloadSrc : src,
                        originalSrc: typeof originalSrc === 'string' ? originalSrc : null,
                        embed: updateEmbedData({
                            src: payloadSrc,
                            id,
                            title,
                            caption,
                            credit,
                            name,
                            nameSource: NameSource.ASSETS,
                            naturalHeight: height,
                            naturalWidth: width,
                        }),
                    });

                    // We just call this for one reason: The loader in EmbedWrapper will show quicker, if we set
                    // the loading status here already. Stuff would work without it, but it looks nicer.
                    LoadingStatusManager.load({ key: loadKey });

                    LoadingStatusManager.loadSuccess({
                        key: uploadKey,
                        data: payload.body,
                    });
                })
                .catch(() => {
                    updateFileNodeData(editor, block, {
                        ...(previousNode?.data || {}),
                    });

                    LoadingStatusManager.loadError({
                        key: uploadKey,
                        error: new Error('Error while uploading file'),
                    });
                });
        }

        updateFileNodeData(editor, block, {
            ...(previousNode?.data || {}),
        });

        return Promise.resolve();
    };
