import { styled, TableCell, TableRow } from '@mui/material';
import {
    type ComponentType,
    type CSSProperties,
    type ElementType,
    type ForwardedRef,
    forwardRef,
    type ReactNode,
} from 'react';
import ReactIs from 'react-is';

type RenderFieldProps = {
    children: ReactNode;
    colSpan: number | undefined;
};

type Props = {
    as?: ElementType;
    className?: string;
    columns?: TableColumn[];
    forwardedRef?: ForwardedRef<HTMLTableRowElement>;
    renderField?: (props: RenderFieldProps) => ReactNode;
    style?: CSSProperties;
    children: ReactNode;
};

const StyledTableRow = styled(TableRow)({});

const EmptyTableRow = (props: Props) => {
    const { as, children, className, columns, forwardedRef, style } = props;

    const renderField = (columns: TableColumn[] | undefined) => {
        const fieldProps: RenderFieldProps = {
            children,
            colSpan: columns?.length,
        };

        if (props.renderField) {
            if (ReactIs.isValidElementType(props.renderField)) {
                const Component = props.renderField as ComponentType<RenderFieldProps>;

                return <Component {...fieldProps} />;
            }

            return props.renderField(fieldProps);
        }

        return <TableCell {...fieldProps} />;
    };

    return (
        <StyledTableRow ref={forwardedRef} as={as} className={className} style={style}>
            {renderField(columns)}
        </StyledTableRow>
    );
};

const EmptyTableRowWithForwardedRef = forwardRef<HTMLTableRowElement, Props>((props, ref) => (
    <EmptyTableRow {...props} forwardedRef={ref} />
));

EmptyTableRowWithForwardedRef.displayName = 'EmptyTableRow';

export default EmptyTableRowWithForwardedRef;
