import { DevTool } from '@hookform/devtools';
import { type ReactNode, useCallback } from 'react';
import { FormProvider, type UseFormHandleSubmit } from 'react-hook-form';
import { type z } from 'zod';

import Form, { type ZodFormProps } from '@@form/components/Form/Form';
import useModalLeavePrompt from '@@form/hooks/useModalLeavePrompt';
import NavPrompt, { type BlockerCondition } from '@@router/components/NavPrompt';

import { ReactHookFormMetaProvider } from './ReactHookFormMetaContext';
import { type ReactHookFormBaseProps } from './types';
import { FormPersistProvider } from './useFormPersist';
import useGeneratedFormName from './useGeneratedFormName';
import useLegacyReactHookForm, { type UseReactHookFormReturn } from './useReactHookForm';

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
export type LegacyReactHookFormProps = ReactHookFormBaseProps & {
    formNameDependencies?: UnknownObject;
    // These props are used only for zod forms and are added here for narrowing
    form?: never;
    navPromptProps?: never;
    // End of narrowing
};

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
const NamedForm = (props: LegacyReactHookFormProps) => {
    const { modalLeavePrompt, navPromptCondition, resetOnConfirmNavPrompt, ...otherProps } = props;
    const { children } = otherProps;

    const { form, formMeta, componentProps } = useLegacyReactHookForm(otherProps);
    const { isDirty, isSubmitting } = form.formState;

    useModalLeavePrompt({ modalLeavePrompt, formContext: form });

    return (
        <FormProvider {...form}>
            <ReactHookFormMetaProvider {...formMeta}>
                <Form {...componentProps}>
                    {typeof children === 'function' ? children(form) : children}
                </Form>
            </ReactHookFormMetaProvider>

            {(isDirty || isSubmitting) && (
                <NavPrompt
                    blockerCondition={navPromptCondition}
                    resetOnConfirm={resetOnConfirmNavPrompt}
                />
            )}
        </FormProvider>
    );
};

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
const LegacyReactHookForm = (props: LegacyReactHookFormProps) => {
    const { formName, formNameDependencies = {}, ...otherProps } = props;
    const { isLoading } = otherProps;

    const generatedFormName = useGeneratedFormName({
        name: formName,
        dependsOn: { ...(isLoading !== undefined && { isLoading }), ...formNameDependencies },
    });

    // We have a separate component because we need the key to change when the formName changes
    // Without this, useReactHookForm will not fully re-render and the form will not be reset properly
    return <NamedForm key={generatedFormName} {...otherProps} formName={generatedFormName} />;
};

export type ZodReactHookFormProps<Schema extends z.ZodTypeAny, Context = unknown> = {
    form: UseReactHookFormReturn<Schema, Context>;
    children: ReactNode;
    navPromptProps?: {
        navPromptCondition?: BlockerCondition;
        resetOnConfirmNavPrompt?: boolean;
    };
    onSubmit: Parameters<UseFormHandleSubmit<z.input<Schema>, z.infer<Schema>>>[0];

    // These props are used only for legacy forms and are added here for narrowing
    formName?: never;
};

export const ZodReactHookForm = <Schema extends z.ZodTypeAny, Context = unknown>(
    props: ZodReactHookFormProps<Schema, Context>,
) => {
    const { children, form, navPromptProps = {}, onSubmit, ...formProps } = props;

    const { meta, methods, name, formMeta } = form;

    const { navPromptCondition, resetOnConfirmNavPrompt } = navPromptProps;
    const { isDirty, isSubmitting } = methods.formState;

    const handleSubmit = useCallback<NonNullable<ZodFormProps['onSubmit']>>(
        (e) => {
            // In React, even though Portals are rendered in a different part of the DOM,
            // the events will still bubble up to the parent DOM tree.
            // We need to prevent this behavior to avoid double form submission
            // triggered in modal forms.
            // https://react.dev/reference/react-dom/createPortal#caveats
            e.preventDefault();
            e.stopPropagation();

            methods.handleSubmit(onSubmit)(e);
        },
        [methods, onSubmit],
    );

    return (
        <>
            <FormProvider {...methods}>
                <ReactHookFormMetaProvider {...formMeta}>
                    <FormPersistProvider clearPersistedForm={meta.clearPersistedForm}>
                        <Form {...formProps} name={name} id={name} onSubmit={handleSubmit}>
                            {children}
                        </Form>

                        {(isDirty || isSubmitting) && (
                            <NavPrompt
                                blockerCondition={navPromptCondition}
                                resetOnConfirm={resetOnConfirmNavPrompt}
                            />
                        )}
                    </FormPersistProvider>
                </ReactHookFormMetaProvider>
            </FormProvider>

            {(meta.debug === 'devtool' || meta.debug === 'all') && (
                <DevTool control={methods.control} placement="top-right" />
            )}
        </>
    );
};

export type ReactHookFormProps<Schema extends z.ZodTypeAny, Context = unknown> =
    | LegacyReactHookFormProps
    | ZodReactHookFormProps<Schema, Context>;

const ReactHookForm = <Schema extends z.ZodTypeAny, Context = unknown>(
    props: ReactHookFormProps<Schema, Context>,
) => {
    if ('form' in props && props.form) {
        return <ZodReactHookForm {...props} />;
    }

    if ('formName' in props) {
        return <LegacyReactHookForm {...props} />;
    }

    throw new Error('This form is not configured correctly');
};

export default ReactHookForm;
