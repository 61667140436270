import { camelCase, upperFirst } from 'lodash-es';

import type PublicationStatus from '@@constants/PublicationStatus';

export const getColorName = (status: PublicationStatus) => `publicationStatus.${camelCase(status)}`;

export const getI18nKey = (status: PublicationStatus) => `publicationStatus.${status}`;

export const getIconName = (status: PublicationStatus) =>
    `publicationStatus${upperFirst(camelCase(status))}`;
