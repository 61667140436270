import { merge } from 'lodash-es';
import { Transforms } from 'slate';

import { Editor, type Node, ReactEditor } from '@@editor/helpers';
import { type EmbedBaseData } from '@@editor/helpers/Element';

export const updateFileNodeData = (editor: Editor, outerNode: Node, data: EmbedBaseData) => {
    const id = ReactEditor.findKey(editor, outerNode).id;

    const [firstMatch] = Editor.nodes<Node>(editor, {
        at: [],
        match: (node) => ReactEditor.findKey(editor, node).id === id,
    });

    if (firstMatch) {
        const [node, path] = firstMatch;

        Transforms.setNodes(editor, { ...node, data: merge({}, node.data, data) }, { at: path });
    }
};
