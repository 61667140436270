import LoadingStatusManagerMessenger from './LoadingStatusManagerMessenger';

export const LoadingStatusManager = {
    isLoading: LoadingStatusManagerMessenger.isLoading,
    load: LoadingStatusManagerMessenger.load,
    loadProgress: LoadingStatusManagerMessenger.loadProgress,
    loadSuccess: LoadingStatusManagerMessenger.loadSuccess,
    loadError: LoadingStatusManagerMessenger.loadError,
    cancelLoading: LoadingStatusManagerMessenger.cancelLoading,
};
