import { styled } from '@mui/material';

import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { type FormProps, type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import { type FrontentComponentElement } from '@@editor/helpers/Element';
import { type PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import TextareaField from '@@form/fields/Textarea';
import TextInputField from '@@form/fields/TextInput';

import PropertiesArrayField from './PropertiesArrayField';

type FormValues = {
    embed: {
        src: string | URL;
        label: string;
        alt: string;
        properties: Array<Record<string, string>>;
    };
};

export type Props = {
    type: typeof PLUGIN_NAMES.FRONTEND_COMPONENT;
    element?: FrontentComponentElement;
    renderButtons?: FormProps['renderButtons'];
    validate: Validator[];
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
    className?: string;
    // not using WithTranslation here to make the editor agnostic of the i18n framework
    t: (t: string) => string;
};

// element data needs to be transformed back into form data
const transformElementToFormValues = (element?: FrontentComponentElement): FormValues => ({
    embed: {
        src: element?.data?.src || '',
        label: element?.data?.embed?.label || '',
        alt: element?.data?.embed?.alt || '',
        properties: element?.data?.embed?.properties || [],
    },
});

const FrontendComponentForm = ({
    renderButtons,
    className,
    element,
    onSubmit,
    onCancel,
    validate,
    t,
}: Props) => {
    const handleCancel = () => onCancel();

    const initialValues = transformElementToFormValues(element);

    return (
        <ReactHookForm
            formName="FrontendComponentForm"
            values={initialValues}
            onSubmit={onSubmit}
            onCancel={handleCancel}
            renderButtons={renderButtons}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
            className={className}
        >
            <TextInputField
                name="embed.src"
                label={t('embed.frontendComponent.source')}
                required
                validate={validate}
            />
            <TextInputField name="embed.label" label={t('embed.frontendComponent.label')} />

            <PropertiesArrayField />

            <TextareaField name="embed.alt" label={t('embed.frontendComponent.alt')} />
        </ReactHookForm>
    );
};

export default styled(FrontendComponentForm)({
    width: '450px',
});
