import { type RefObject } from 'react';

import { type Element } from '@@editor/helpers';

export type DragItem = {
    blockRef: RefObject<HTMLDivElement>;
    dataTransfer?: DataTransfer;
    editorId: string;
    element: Element;
};

export const DropDirections = {
    BOTTOM: 'bottom',
    TOP: 'top',
} as const;

export type DropDirection = ValueOf<typeof DropDirections> | undefined;
