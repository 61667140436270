import { isEqual } from 'lodash-es';
import { type NodeEntry, Path, Point, Range, Text, Transforms } from 'slate';

import { DEFAULT_BLOCK } from '@@editor/constants';
import { Editor, Node } from '@@editor/helpers';
import {
    Element,
    ELEMENT_TYPES,
    type ElementType,
    type EmbedElement,
} from '@@editor/helpers/Element';
import {
    createElementFromSingleLineState,
    createElementFromString,
} from '@@editor/serialization/UnitySerializer/deserializeNodes';
import { not } from '@@utils/function';

type PreventDeleteOptions = {
    types: ElementType[];
    direction?: 'forward' | 'backward';
};

const DIRECTION_MAPPING = {
    forward: 'after',
    backward: 'before',
};

export const preventDeleteBackward = (editor: Editor, unit: string, options?) =>
    preventDelete(editor, unit, { ...options, direction: 'backward' });

export const preventDeleteForward = (editor: Editor, unit: string, options?) =>
    preventDelete(editor, unit, { ...options, direction: 'forward' });

export const preventDelete = (editor: Editor, unit: string, options: PreventDeleteOptions) => {
    const { direction = 'forward', types = [] } = options;

    if (!editor.selection) {
        return;
    }

    const adjacent = Editor[DIRECTION_MAPPING[direction]](editor, editor.selection.focus, {
        unit: 'character',
    });

    const [element] = Editor.elements<Element>(editor, {
        at: editor.selection.focus,
        mode: 'lowest',
        match: not(Element.isInlineElement),
    });

    const [adjacentElement] = Editor.elements<Element>(editor, {
        at: adjacent,
        mode: 'lowest',
        match: not(Element.isInlineElement),
    });

    if (element && adjacentElement) {
        // We do not want to mix elements of type `types` with elements of other types. Therefore
        // do not allow deleting if the adjacent element is not of the same type, as the one the
        // selection is on (nodes would get merged my slate)
        if (
            (types.includes(element[0].type) || types.includes(adjacentElement[0].type)) &&
            element[0].type !== adjacentElement[0].type
        ) {
            return true;
        }
    }
};

type PreventDeleteFragmentOptions = {
    types: ElementType[];
};

export const preventDeleteFragment = (editor: Editor, options: PreventDeleteFragmentOptions) => {
    const { types } = options;

    if (!editor.selection) {
        return;
    }

    const [start, end] = Range.edges(editor.selection);
    const elements = Editor.elements<Element>(editor, { mode: 'lowest' });
    const selectedTypes = new Set<string>();

    let containsOneOfTypes = false;

    for (const [element, path] of elements) {
        const parentPath = Path.parent(path);

        const isWholeElementInSelection =
            parentPath.length > 0 &&
            (Point.isBefore(start, Editor.start(editor, parentPath)) ||
                Editor.isStart(editor, start, [])) &&
            (Point.isAfter(end, Editor.end(editor, parentPath)) || Editor.isEnd(editor, end, []));

        if (!selectedTypes.has(element.type)) {
            selectedTypes.add(element.type);
        }

        if (types.includes(element.type) && !isWholeElementInSelection) {
            containsOneOfTypes = true;
        }

        // We do not want to mix elements of type `types` with elements of other types. Therefore,
        // if more than one type is selected and of that selected types is one of `types`, we won't
        // allow deleting
        if (selectedTypes.size > 1 && containsOneOfTypes) {
            return true;
        }
    }
};

type PreventInsertBreakOptions = {
    types: ElementType[];
};

export const preventInsertBreak = (editor: Editor, options: PreventInsertBreakOptions) => {
    const { types } = options;

    if (!editor.selection) {
        return;
    }

    const [firstMatch] = Editor.elements(editor, {
        at: editor.selection,
        types,
    });

    if (firstMatch) {
        return true;
    }
};

type NormalizeInlineEditableElementOptions = {
    type: ElementType;
    allowedChildrenTypes: ElementType[];
    minimumChildren: ElementType[];
};

export const normalizeInlineEditableElement = (
    editor: Editor,
    nodeEntry: NodeEntry,
    options: NormalizeInlineEditableElementOptions,
) => {
    const [node, path] = nodeEntry;
    const { type, allowedChildrenTypes, minimumChildren } = options;

    if (node.type === type && !Editor.isVoid(editor, node)) {
        // If there are no children at all, we assume somebody tried to remove the entire element. This can happen
        // to elements located on the first line, when the user selects the whole element and tries to delete it.
        if (node.children.length === 0 && !minimumChildren) {
            Transforms.removeNodes(editor, { at: path });

            return true;
        }

        // In non-void mode (inline editing), we want to make sure to only allow an image caption and an
        // image credit as direct childs of an image. Everything else will be moved out below.
        // One real world use case is: if we drop an image below an image, the dropped image would become
        // a child of that image, which we must prevent.
        for (let i = node.children.length - 1; i >= 0; i--) {
            const child = node.children[i];
            const childPath = [...path, i];

            if (!allowedChildrenTypes.includes(child.type)) {
                if (Text.isText(node)) {
                    Transforms.removeNodes(editor, { at: childPath });

                    return true;
                }

                const to = Path.next(path);

                Transforms.moveNodes(editor, { at: childPath, to });

                return true;
            }
        }

        // In some rare cases, it is possible to remove image caption or image credit elements. We want to
        // make sure this never happens.
        // One real world use case is: selection starts outside the image caption and ends inside the image caption,
        // press backspace. This is prevented by our `deleteFragment` handler. This normalizer acts as a backup.
        if (minimumChildren) {
            if (node.children.length === 0) {
                minimumChildren.forEach((minimumChild, index) => {
                    Transforms.insertNodes(
                        editor,
                        Element.create({ ...DEFAULT_BLOCK, type: minimumChild }),
                        { at: [...path, index] },
                    );
                });
            }
        } else {
            allowedChildrenTypes.forEach((allowedChildType, index) => {
                const childElement = node.children.find(({ type }) => type === allowedChildType);

                if (!childElement) {
                    Transforms.insertNodes(
                        editor,
                        Element.create({ ...DEFAULT_BLOCK, type: allowedChildType }),
                        { at: [...path, index] },
                    );

                    return true;
                }
            });
        }
    } else if (allowedChildrenTypes.includes(node.type) && !Editor.isVoid(editor, node)) {
        // If one of the children types is floating around in the editor, without being wrapped in any element, we will remove it.
        // This can happen to elements located on the last line, when the user selects
        // the whole element and tries to delete it.
        const parent = Node.parent(editor, path);

        if (!parent || !Element.isElement(parent)) {
            Transforms.removeNodes(editor, { at: path });

            return true;
        }
    }
};

export const syncInlineEditedEmbedElement = (
    editor: Editor,
    node: EmbedElement,
    path: Path,
    oldEmbedData: Partial<EmbedElement['data']['embed']>,
    newEmbedData: Partial<EmbedElement['data']['embed']>,
) => {
    if (!newEmbedData || isEqual(oldEmbedData, newEmbedData)) {
        return;
    }

    Editor.withoutNormalizing(editor, () => {
        node.children.forEach((child, index) => {
            const childPath = [...path, index];

            if (Element.isEmbedCaptionElement(child) && 'caption' in newEmbedData) {
                Transforms.removeNodes(editor, { at: childPath });
                Transforms.insertNodes(
                    editor,
                    createElementFromSingleLineState(
                        ELEMENT_TYPES.EMBED_CAPTION,
                        newEmbedData.caption,
                    ),
                    {
                        at: childPath,
                    },
                );
            } else if (Element.isEmbedCreditElement(child) && 'credit' in newEmbedData) {
                Transforms.removeNodes(editor, { at: childPath });
                Transforms.insertNodes(
                    editor,
                    createElementFromString(ELEMENT_TYPES.EMBED_CREDIT, newEmbedData.credit),
                    {
                        at: childPath,
                    },
                );
            }
        });
    });
};
