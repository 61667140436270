import { LinearProgress, Stack, styled, Typography } from '@mui/material';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import InfoTooltip from '@@components/InfoTooltip';
import { useLoadingStatusManager } from '@@containers/LoadingStatusManager/LoadingStatusManagerContext';
import { generateKeyForRichTextEditorLoadingStatus } from '@@containers/LoadingStatusManager/utils';
import PreviewImage from '@@containers/PreviewImage';
import { type Editor } from '@@editor/helpers';
import { Element, type ImageElement } from '@@editor/helpers/Element';
import { MAX_HEIGHT, MAX_WIDTH } from '@@editor/plugins/serializable/constants';
import toText from '@@editor/utils/toText';

import OverlayIcon from './OverlayIcon';
import EmbedComponentLoader from '../../components/EmbedComponentLoader';

const Wrapper = styled('div')({
    position: 'relative',
    width: '100%',
});

const getImageSrc = (data) =>
    // have the original url, which could be an external url, as fallback
    get(data, 'embed.url') || get(data, 'src');

const LimitedHeightPreviewImage = styled(PreviewImage)({
    maxHeight: `${MAX_HEIGHT}px`,
    borderRadius: 0,
    width: '100%',
});

type Props = {
    element: ImageElement;
    inlineEdited?: boolean;
    editor: Editor;
    onLoad?: VoidFunction;
    onError?: VoidFunction;
};

const PreviewImageWrapper = ({ element, inlineEdited, onLoad, onError }: Props) => {
    const { data } = element;
    const embed = data.embed || {};

    const src = getImageSrc(data);
    const overlayIcon = get(data, 'embed.overlayIcon');

    const caption = element.children.find(Element.isEmbedCaptionElement);

    return (
        <Wrapper>
            {overlayIcon && <OverlayIcon {...overlayIcon} />}

            <LimitedHeightPreviewImage
                transformations={{
                    ...embed,
                    mimetype: data.mimetype,
                    maxWidth: MAX_WIDTH,
                    maxHeight: MAX_HEIGHT,
                }}
                src={src}
                alt={toText(inlineEdited ? caption : embed.caption)}
                onLoad={onLoad}
                onError={onError}
            />
        </Wrapper>
    );
};

export default (props: Props) => {
    const { t } = useTranslation();
    const { element, editor } = props;
    const src = getImageSrc(element.data);

    const key = generateKeyForRichTextEditorLoadingStatus({
        editorId: editor.id,
        loadingStatusId: element.data.loadingStatusId,
        type: 'generateImageAltText',
    });

    const { isLoading } = useLoadingStatusManager({ key });

    if (src) {
        return (
            <EmbedComponentLoader editor={editor} element={element}>
                {({ onLoad, onError }) => (
                    <>
                        <PreviewImageWrapper {...props} onLoad={onLoad} onError={onError} />

                        {isLoading ? (
                            <LinearProgress />
                        ) : (
                            element.data.embed?.name && (
                                <Stack
                                    alignItems="flex-start"
                                    justifyContent="stretch"
                                    direction="row"
                                    gap={2}
                                    padding={2}
                                    style={{
                                        userSelect: 'text',
                                    }}
                                >
                                    <Typography color="primary" flexGrow="1">
                                        <Typography color="black" component="span">
                                            {t('editor.plugin.image.imageDescription.label')}:&nbsp;
                                        </Typography>

                                        {element.data.embed?.name}
                                    </Typography>

                                    <InfoTooltip
                                        title={t('editor.plugin.image.imageDescription.tooltip')}
                                        iconName="bernand"
                                        iconSize="large"
                                        iconColor="amber"
                                    />
                                </Stack>
                            )
                        )}
                    </>
                )}
            </EmbedComponentLoader>
        );
    }

    return <PreviewImageWrapper {...props} />;
};
