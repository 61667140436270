import { uniq } from 'lodash-es';
import invariant from 'tiny-invariant';

import { INFORMATION_SEPARATOR_ONE } from '@@constants/Unicode';
import required from '@@form/utils/validators/required';

export const undefinedOrNullToEmptyArray = (value) => (value == null ? [] : value);

export const undefinedOrNullToEmptyString = (value) => (value == null ? '' : value);

const baseTransformValidate = (props, options) => {
    if (props.novalidate) {
        return { validators: [() => undefined], validateIf: () => () => undefined };
    }

    invariant(
        typeof props.validate !== 'function',
        'Validate must not be a function to enable memoization.',
    );

    let validators = uniq([...(options.validate || []), ...(props.validate || [])]);

    if (props.required && validators.indexOf(required) < 0) {
        validators = [required, ...validators];
    }

    if (props.requiredCustom) {
        validators = [props.requiredCustom, ...validators];
    }

    const validateIf = (value) => (validator) => {
        if (!props.requiredCustom && required(value) && validators.indexOf(required) < 0) {
            return;
        }

        if (required(value) && props.requiredCustom && validator !== props.requiredCustom) {
            return;
        }

        return validator(value);
    };

    return { validators, validateIf };
};

export const asyncTransformValidate = (props, options = {}) => {
    const { validators, validateIf } = baseTransformValidate(props, options);

    // Since we want a list of errors, and not only the most recent error, we iterate
    // over all the errors right here and return all the error messages in an array
    return (value) => {
        const asyncErrors = validators.map(validateIf(value));

        return Promise.allSettled(asyncErrors).then((results) => {
            const errors = results
                .map((result) => (result.status === 'fulfilled' ? result.value : result.reason))
                .filter((value) => typeof value !== 'undefined');

            if (errors.length) {
                // `react-hook-form` only accepts strings as errors, no error objects or arrays. Maybe we
                // could get around this by using `resolver` (check `react-hook-form` docs).
                return errors.map((error) => JSON.stringify(error)).join(INFORMATION_SEPARATOR_ONE);
            }
        });
    };
};
