import { omit } from 'lodash-es';

import { type EmbedBaseData, MIME_TYPES } from '@@editor/helpers/Element';
import { type GenerateEmbedBlockData } from '@@editor/typings/Embed';
import { isInstagramUrl } from '@@utils/socialMedia/instagram';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ src, originalSrc, embed }) => {
    if (!isInstagramUrl(src)) {
        return;
    }

    return {
        mimetype: MIME_TYPES.INSTAGRAM,
        src: (src as URL).href,
        originalSrc,
        embed: omit<EmbedBaseData>(embed, 'src'),
    };
};
