import { type FieldPath, type FieldValues } from 'react-hook-form';

import InfoboxEditor from '@@form/components/RichTextEditor/InfoboxEditor';

import RichTextField, { type RichTextFieldProps } from './RichTextField';

export const InfoboxEditorField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: RichTextFieldProps<TFieldValues, TName>,
) => <RichTextField {...props} component={InfoboxEditor} />;

export default InfoboxEditorField;
