import { paperClasses } from '@mui/material';
import { omit } from 'lodash-es';
import { type ReactNode } from 'react';

import Modal from '@@containers/Modal/ModalWithLeavePrompt';
import { Editor, type Element } from '@@editor/helpers';
import useSteps from '@@editor/plugins/utils/useSteps';

import InterviewImportForm from './InterviewImportForm';
import InterviewSegmentForm from './InterviewSegmentForm';

type InnerProps = {
    children: ReactNode;
    editor: Editor;
    generateEmbedBlock: (editor, formData, options) => void;
};

type OutterProps = InnerProps & {
    isEditMode: boolean;
};

enum STEP {
    INTERVIEW_SEGMENT,
    INTERVIEW_IMPORT,
}

type Props = InnerProps & OutterProps;

const InterviewModal = (props: Props) => {
    const { editor, isEditMode, children, generateEmbedBlock } = props;
    const { t } = editor;

    const { isModalOpen, element, at } = editor.isInterviewModalVisible() || {
        isModalOpen: false,
        element: null,
        at: null,
    };

    const { currentStep, nextStep, prevStep, resetSteps } = useSteps(STEP.INTERVIEW_SEGMENT);
    const isInterviewImportStep = currentStep === STEP.INTERVIEW_IMPORT;

    const MODAL_TITLE_FUNCTIONS = {
        [STEP.INTERVIEW_IMPORT]: () => t(`editor.plugin.interviewSegment.importInterview`),
        [STEP.INTERVIEW_SEGMENT]: (isEditMode?: boolean) =>
            t(`editor.${isEditMode ? 'edit' : 'insert'}.embed.interviewSegment`),
    };

    const getModalTitle = (currentStep: STEP) => MODAL_TITLE_FUNCTIONS[currentStep];

    const handleReset = () => {
        resetSteps();
        editor.hideInterviewModal();
    };

    const handleClose = () => {
        if (currentStep === STEP.INTERVIEW_IMPORT) {
            prevStep();
        } else {
            handleReset();
        }
    };

    const handleSubmit = (formData) => {
        if (currentStep === STEP.INTERVIEW_SEGMENT) {
            generateEmbedBlock(editor, formData, {
                at,
                replace: isEditMode,
            });
        }

        if (currentStep === STEP.INTERVIEW_IMPORT) {
            const elements: Element[] = formData.parsedText;

            // We spread here to prevent mutation of the original array
            [...elements].reverse().forEach((element) => {
                // The displayError boolean is not useful outside of the import modal,
                // we need to strip the elements from it before inserting them
                const slateElement = [{ ...omit(element, ['displayError']) } as Element];

                Editor.insertElement(editor, slateElement, { at });
            });
        }

        handleReset();
    };

    return (
        <>
            <Modal
                disableRestoreFocus
                isOpen={isModalOpen}
                title={getModalTitle(currentStep)(isEditMode)}
                sx={(theme) => ({
                    [`.${paperClasses.root}`]: {
                        width: isInterviewImportStep
                            ? theme.fixed.editor.importInterviewDialog.width
                            : 'auto',
                    },
                })}
                onClose={handleClose}
            >
                {!isInterviewImportStep && (
                    <InterviewSegmentForm
                        editor={editor}
                        element={element}
                        onClickImportButton={nextStep}
                        onCancel={handleClose}
                        onSubmit={handleSubmit}
                    />
                )}
                {isInterviewImportStep && (
                    <InterviewImportForm
                        editor={editor}
                        onCancel={handleClose}
                        onSubmit={handleSubmit}
                    />
                )}
            </Modal>

            {children}
        </>
    );
};

export default InterviewModal;
