import { Menu, type MenuProps } from '@mui/material';
import { pick } from 'lodash-es';
import { type ReactNode, type RefCallback, useState } from 'react';

type Props = {
    children: (props: { ref: RefCallback<HTMLElement>; openMenu: VoidFunction }) => ReactNode;
    renderItems: (closeMenu: VoidFunction) => ReactNode;
} & Pick<MenuProps, 'anchorOrigin' | 'transformOrigin'>;

const ContextMenu = (props: Props) => {
    const { renderItems, children } = props;
    const [open, setOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

    const openMenu = () => {
        setOpen(true);
    };

    const closeMenu = () => {
        setOpen(false);
    };

    return (
        <>
            {children({ ref: setReferenceElement, openMenu })}

            <Menu
                anchorEl={referenceElement}
                open={open}
                sx={{ margin: 1 }}
                onClose={closeMenu}
                {...pick(props, ['anchorOrigin', 'transformOrigin'])}
            >
                {renderItems(closeMenu)}
            </Menu>
        </>
    );
};

export default ContextMenu;
