import { mapValues, pick } from 'lodash-es';
import storage from 'redux-persist/lib/storage';

import { ContentTypes } from '@@api/utils/schemas/schemas';
import { ROLES, type UserRole } from '@@auth/constants';
import { EXTERNAL_SYSTEMS } from '@@constants/externalSystems';
import IMAGE_MIME_TYPES from '@@constants/ImageMimeTypes';
import { LANG_CODES } from '@@lib/i18n/constants';

import resolveEnv from './resolveEnv';
import * as info from '../../../package.json';

export type NavigationItem = {
    key: string;
    uri: string;
    authorize?: UserRole[];
    excludeFromReducedUI?: boolean;
    excludeFromNavigationMenu?: boolean;
};

export type Navigation = {
    placement: NavigationItem;
    articles: NavigationItem;
    tickers: NavigationItem;
    slideshows: NavigationItem;
    videos: NavigationItem;
    links: NavigationItem;
    embeds: NavigationItem;
    breakingnews: NavigationItem;
    curatedList: NavigationItem;
    categories: NavigationItem;
    sitemaps: NavigationItem;
    tags: NavigationItem;
    kickwords: NavigationItem;
    teams: NavigationItem;
    releasenotes: NavigationItem;
    rtelements: NavigationItem;
};

export type MicroserviceItem = {
    baseUrl: string;
    healthUrl: string;
};

export type Microservices = {
    breakingnews: MicroserviceItem;
    notification: MicroserviceItem;
    functions: MicroserviceItem;
    tenant: MicroserviceItem;
    metadata: MicroserviceItem;
    placement: MicroserviceItem;
    // cdn
    filerepo: MicroserviceItem;
    // content (has metadata)
    slideshow: MicroserviceItem;
    liveticker: MicroserviceItem;
    content: MicroserviceItem;
    'external-content': MicroserviceItem;
    auth: MicroserviceItem;
    releasenotes: MicroserviceItem;
    websocket: MicroserviceItem;
    webstats: MicroserviceItem;
    rtelements: MicroserviceItem;
    'user-presence': MicroserviceItem;
    revisions: MicroserviceItem;
};

type PersistConfig = {
    key: string;
    debug: boolean;
    whitelist: string[];
    storage: any;
};

const EIGHT_HOURS_IN_MILLISECONDS = 28800000;

export const resolveAuthenticationApiUrl = (apiEnv?: string) =>
    `${resolveEnv(apiEnv).apiEnvVars.authRedirectUrl + window.location.origin}/login`;

const { devToolbarDisabled, env, apiEnv, apiEnvs, apiEnvVars, mode, googleAnalyticsTrackingId } =
    resolveEnv();

const contentTypeToIconName = {
    [ContentTypes.ARTICLES]: 'document-media',
    [ContentTypes.TICKERS]: 'clock-sharp-regular',
    [ContentTypes.SLIDESHOWS]: 'images-regular',
    [ContentTypes.VIDEOS]: 'rectangle-regular-play',
    [ContentTypes.LINKS]: 'arrow-up-right-from-square',
    [ContentTypes.EMBEDS]: 'code',
    [ContentTypes.BREAKIGNEWS]: 'bolt-lightning',
};

const config = {
    env,
    apiEnv,
    apiEnvs,
    apiEnvVars,
    mode,
    devToolbarDisabled,
    googleAnalyticsTrackingId,
    apiRepositoryUrl: 'https://github.com/DND-IT/cms',
    dataDogApplicationId: 'ed022b20-ebc2-4778-a31e-64e16704ec36', //  secret manager unity prod account, key name is unity/datadog/rumPocInfos https://eu-central-1.console.aws.amazon.com/secretsmanager/secret?name=unity%2Fdatadog%2FrumInfos&region=eu-central-1
    dataDogClientToken: 'pub09a0bbe35e30eed401cdf53ae4e72e93',
    contentStation: {
        newArticleUrl: apiEnvVars.contentStation.newArticleUrl,
        dossierUrl: apiEnvVars.contentStation.dossierUrl,
    },
    kordiam: {
        baseUrl: 'https://kordiam.app/dashboardPage.htm',
    },
    microservices: {
        breakingnews: {
            baseUrl: apiEnvVars.breakingnewsUrl,
            healthUrl: `${apiEnvVars.breakingnewsUrl}/health`,
        },
        notification: {
            baseUrl: apiEnvVars.notification,
            healthUrl: `${apiEnvVars.notification}/health`,
        },
        functions: {
            baseUrl: apiEnvVars.functionsUrl,
            healthUrl: `${apiEnvVars.functionsUrl}/health`,
        },
        tenant: {
            baseUrl: apiEnvVars.tenantUrl,
            healthUrl: `${apiEnvVars.tenantUrl}/health`,
        },
        metadata: {
            baseUrl: apiEnvVars.metadataUrl,
            healthUrl: `${apiEnvVars.metadataUrl}/health`,
        },
        placement: {
            baseUrl: apiEnvVars.placementUrl,
            healthUrl: `${apiEnvVars.placementUrl}/health`,
        },
        // cdn
        filerepo: {
            baseUrl: apiEnvVars.filerepoUrl,
            healthUrl: `${apiEnvVars.filerepoUrl}/health`,
        },
        // content (has metadata)
        slideshow: {
            baseUrl: apiEnvVars.slideshowUrl,
            healthUrl: `${apiEnvVars.slideshowUrl}/health`,
        },
        liveticker: {
            baseUrl: apiEnvVars.livetickerUrl,
            healthUrl: `${apiEnvVars.livetickerUrl}/health`,
        },
        content: {
            baseUrl: apiEnvVars.contentUrl,
            healthUrl: `${apiEnvVars.contentUrl}/health`,
        },
        'external-content': {
            baseUrl: apiEnvVars.externalContentUrl,
            healthUrl: `${apiEnvVars.externalContentUrl}/health`,
        },
        auth: {
            baseUrl: apiEnvVars.authUrl,
            healthUrl: `${apiEnvVars.authUrl}/health`,
        },
        releasenotes: {
            baseUrl: apiEnvVars.releasenotesUrl,
            healthUrl: `${apiEnvVars.releasenotesUrl}/health`,
        },
        websocket: {
            baseUrl: apiEnvVars.websocketUrl,
            healthUrl: `${apiEnvVars.websocketUrl.replace(/^ws/, 'http')}/health`,
        },
        webstats: {
            baseUrl: apiEnvVars.webstatsUrl,
            healthUrl: `${apiEnvVars.webstatsUrl}/health`,
        },
        rtelements: {
            baseUrl: apiEnvVars.rtElementsUrl,
            healthUrl: `${apiEnvVars.rtElementsUrl}/health`,
        },
        'spell-checker': {
            baseUrl: apiEnvVars.spellCheckerUrl,
            healthUrl: `${apiEnvVars.spellCheckerUrl}/health`,
        },
        'user-presence': {
            baseUrl: apiEnvVars.userPresencesUrl,
            healthUrl: `${apiEnvVars.userPresencesUrl}/health`,
        },
        revisions: {
            baseUrl: apiEnvVars.revisionsUrl,
            healthUrl: `${apiEnvVars.revisionsUrl}/health`,
        },
        attachments: {
            baseUrl: apiEnvVars.attachmentsUrl,
            healthUrl: `${apiEnvVars.attachmentsUrl}/health`,
        },
        'ai-tools': {
            baseUrl: apiEnvVars.aiToolsUrl,
            healthUrl: `${apiEnvVars.aiToolsUrl}/health`,
        },
        newsletter: {
            baseUrl: apiEnvVars.newsletterUrl,
            healthUrl: `${apiEnvVars.newsletterUrl}/health`,
        },
    } as Microservices,
    // Hide features in production by using reducedUI
    hiddenFloatingToolbarFeatures: [],
    // definitions for routing and navbars, order is important
    navigation: mapValues(
        {
            articles: {
                uri: '/articles',
            },
            tickers: {
                uri: '/tickers',
            },
            slideshows: {
                uri: '/slideshows',
            },
            videos: {
                uri: '/externalContent/videos',
            },
            links: {
                uri: '/externalContent/links',
            },
            embeds: {
                uri: '/externalContent/embeds',
            },
            breakingnews: {
                uri: '/breakingnews',
            },

            placement: {
                uri: '/placement',
                authorize: [ROLES.ADMIN, ROLES.CHIEF_EDITOR],
            },
            curatedList: {
                uri: '/curatedList',
            },

            categories: {
                uri: '/categories',
                authorize: [ROLES.ADMIN],
            },
            sitemaps: {
                uri: '/sitemaps',
                authorize: [ROLES.ADMIN],
            },
            newsletters: {
                uri: '/newsletters',
                authorize: [ROLES.ADMIN],
            },
            collections: {
                uri: '/collections',
                authorize: [ROLES.ADMIN],
            },
            tags: {
                uri: '/tags',
                authorize: [ROLES.ADMIN],
            },
            kickwords: {
                uri: '/kickwords',
                authorize: [ROLES.ADMIN],
            },
            teams: {
                uri: '/teams',
            },
            rtelements: {
                uri: '/rtelements',
                authorize: [ROLES.ADMIN, ROLES.CHIEF_EDITOR],
            },
            users: {
                uri: '/users',
                authorize: [ROLES.ADMIN, ROLES.CHIEF_EDITOR],
            },
            releasenotes: {
                uri: '/releasenotes',
                authorize: [ROLES.ADMIN],
                excludeFromNavigationMenu: true,
            },
            maintenancenotes: {
                uri: '/maintenancenotes',
                authorize: [ROLES.ADMIN],
                excludeFromNavigationMenu: true,
            },
        },
        (obj, key) => ({ ...obj, key }),
    ) as Navigation,
    previewImageRegexp: new RegExp(/\/server025\.newsnetz\.tv|\/unitythumb\.appuser\.ch/),
    isMockServer: import.meta.env.CDII_API === 'localhost:apiMock',
    isVisualTest: import.meta.env.MODE === 'visualTest',
    authenticationApiUrl: resolveAuthenticationApiUrl(),
    debugWebsocketClient: false,
    defaultUiLocale: LANG_CODES.ENGLISH,
    list: {
        itemsPerPage: 10,
    },
    contentTypes: Object.values(ContentTypes),
    contentTypeToIconName,
    allowedImageMimeTypes: pick(IMAGE_MIME_TYPES, ['JPEG', 'JPG', 'PNG', 'GIF', 'SVG', 'WEBP']),
    searchDebounce: 300,
    reduxPersist: {
        key: 'primary',
        debug: false,
        whitelist: ['auth', 'settings'],
        storage,
    } as PersistConfig,
    reduxPersistI18n: {
        key: 'i18n',
        debug: false,
        whitelist: ['locale'],
        storage,
    },
    persistedFormDataExpireAfter: EIGHT_HOURS_IN_MILLISECONDS,
    snackbar: {
        autoHideDuration: 10000,
        maxSnack: 5,
    },
    externalLinks: [
        [EXTERNAL_SYSTEMS.AI_TOOLBOX, apiEnvVars.externalLinks.aiToolbox],
        [EXTERNAL_SYSTEMS.KORDIAM, apiEnvVars.externalLinks.kordiam],
        [EXTERNAL_SYSTEMS.OKTA, apiEnvVars.externalLinks.okta],
        [EXTERNAL_SYSTEMS.BRIXWIRE, apiEnvVars.externalLinks.brixWire],
        [EXTERNAL_SYSTEMS.VIDEOCMS, apiEnvVars.externalLinks.videoCMS, false],
        [EXTERNAL_SYSTEMS.ASSETS, apiEnvVars.externalLinks.assets],
        [EXTERNAL_SYSTEMS.WOODWING_STUDIO, apiEnvVars.externalLinks.contentStation],
    ] as const,
    frontendComponentHost: 'tagesanzeiger.ch',
    loginAuthHeader: 'Basic Y2QyOmNkMg==',
};

if (import.meta.env.MODE === 'test' || import.meta.env.CI === 'true') {
    Object.assign(config, {
        microservices: {
            ...config.microservices,
            dummy: {
                baseUrl: info.config.apiMockBaseUrl + '/dummy',
                healthUrl: info.config.apiMockBaseUrl + `/dummy/health`,
            },
        },
    });
}

export default config;
