import { ThemeProvider } from '@mui/material';
import { Transforms } from 'slate';

import { DEFAULT_BLOCK } from '@@editor/constants';
import { Editor, Element, type Operation, ReactEditor } from '@@editor/helpers';
import {
    ELEMENT_TYPES,
    EMBED_TYPES,
    type InfoboxElement,
    TEXT_TYPES,
} from '@@editor/helpers/Element';
import deleteBackward from '@@editor/plugins/utils/deleteBackward';
import deleteForward from '@@editor/plugins/utils/deleteForward';
import deleteFragment from '@@editor/plugins/utils/deleteFragment';
import {
    normalizeInlineEditableElement,
    preventDeleteBackward,
    preventDeleteForward,
    preventDeleteFragment,
    preventInsertBreak,
} from '@@editor/plugins/utils/inlineEditing';
import insertBreak from '@@editor/plugins/utils/insertBreak';
import normalizeNode from '@@editor/plugins/utils/normalizeNode';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import getElvisIdByImage from '@@editor/selectors/getElvisIdByImage';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import Infobox from './Infobox';
import InfoboxForm from './InfoboxForm';
import { DEFAULT_INFOBOX_COLLAPSED_VALUE } from '../constants';
import InfoboxContentElement from './inlineComponents/InfoboxContentElement';
import InfoboxTitleElement from './inlineComponents/InfoboxTitleElement';
import InlineInfobox from './inlineComponents/InlineInfobox';
import { getInfoboxTitle } from './utils';
import EditorWithEmbedModal from '../../embed/components/EditorWithEmbedModal';
import EmbedWrapper from '../../embed/components/EmbedWrapper';
import { editorPlaceholderTheme } from '../../styles';
import { createGenerateLayoutBlock } from '../utils';

const TYPE = PLUGIN_NAMES.INFOBOX;
const NODE_TYPE = ELEMENT_TYPES.INFOBOX;

const INLINE_EDITABLE_CHILDREN_TYPES = [ELEMENT_TYPES.INFOBOX_TITLE, ELEMENT_TYPES.INFOBOX_CONTENT];
const CONTENT_CHILDREN_TYPES = [
    ...Object.values(TEXT_TYPES),
    ...Object.values(EMBED_TYPES),
    ELEMENT_TYPES.IMAGE,
];

type Props = {
    editor: Editor;
    element: InfoboxElement;
};

export const InfoboxNode = (props: Props) => {
    const { editor, element } = props;
    const { t, useInlineEditing } = editor;
    const { data } = element;

    const generateTheme = (theme) =>
        Element.isTemplateElement(element) ? editorPlaceholderTheme(theme) : theme;

    return (
        <ThemeProvider theme={generateTheme}>
            {/* EmbedWrapper is not properly typed (it accepts only EmbedElement but Infobox is LayoutElement */}
            {/* @ts-expect-error */}
            <EmbedWrapper
                {...props}
                type={TYPE}
                component={useInlineEditing ? InlineInfobox : Infobox}
                contentEditable={useInlineEditing}
                placeholderComponent={InlineInfobox}
                toolbarConfig={{
                    infos: {
                        iconName: PLUGIN_ICON_NAMES[TYPE],
                        title: getInfoboxTitle(data, t),
                    },
                    actions: [
                        {
                            type: 'edit',
                            iconName: 'gear',
                            onClick: (e) => {
                                e.preventDefault();

                                return requestAnimationFrame(() =>
                                    editor.showEmbedModal(
                                        TYPE,
                                        element,
                                        ReactEditor.findPath(editor, element),
                                    ),
                                );
                            },
                        },
                        'delete',
                    ],
                    tooltips: [
                        {
                            // t('editor.plugin.infobox.tooltip')
                            title: 'editor.plugin.infobox.tooltip',
                        },
                    ],
                }}
            />
        </ThemeProvider>
    );
};

export const generateLayoutBlock = createGenerateLayoutBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
});

const internalWithInfobox = (editor, options) => {
    const { apply, isVoid, useInlineEditing } = editor;
    const elementsContextActions = options.elementsContextActions;

    return Object.assign(editor, {
        apply: (operation: Operation) => {
            if (elementsContextActions) {
                if (operation.type === 'insert_node') {
                    if (Element.isInfoboxElement(operation.node)) {
                        const infoboxContent = operation.node.data.content;

                        infoboxContent.forEach((element) => {
                            if (Element.isImageElement(element)) {
                                elementsContextActions.addElement?.({
                                    editorId: editor.id,
                                    element,
                                });
                            }
                        });
                    }
                } else if (operation.type === 'set_node') {
                    const [node] = Editor.node(editor, operation.path);

                    if (Element.isInfoboxElement(node)) {
                        const infoboxContent = node.data.content;

                        infoboxContent.forEach((element) => {
                            if (
                                Element.isImageElement(element) &&
                                getElvisIdByImage(operation.properties) !==
                                    getElvisIdByImage(operation.newProperties)
                            ) {
                                elementsContextActions.removeElement?.({
                                    editorId: editor.id,
                                    element,
                                });

                                elementsContextActions.addElement?.({
                                    editorId: editor.id,
                                    element: { ...element, ...operation.newProperties },
                                });
                            }
                        });
                    }
                } else if (operation.type === 'remove_node') {
                    if (Element.isInfoboxElement(operation.node)) {
                        const infoboxContent = operation.node.data.content;

                        infoboxContent.forEach((element) => {
                            if (Element.isImageElement(element)) {
                                elementsContextActions.removeElement?.({
                                    editorId: editor.id,
                                    element,
                                });
                            }
                        });
                    }
                }
            }

            apply(operation);
        },
        isVoid: (element) => {
            if (useInlineEditing && Element.isInfoboxElement(element)) {
                return false;
            }

            return element.type === NODE_TYPE ? true : isVoid(element);
        },
        deleteForward: deleteForward(editor, [
            [preventDeleteForward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteBackward: deleteBackward(editor, [
            [preventDeleteBackward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteFragment: deleteFragment(editor, [
            [preventDeleteFragment, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        insertBreak: insertBreak(editor, [
            [preventInsertBreak, { types: [ELEMENT_TYPES.INFOBOX_TITLE] }],
        ]),
        normalizeNode: normalizeNode(editor, [
            [
                (editor, nodeEntry) => {
                    if (Element.isInfoboxContentElement(nodeEntry[0])) {
                        return normalizeInlineEditableElement(editor, nodeEntry, {
                            type: ELEMENT_TYPES.INFOBOX_CONTENT,
                            allowedChildrenTypes: CONTENT_CHILDREN_TYPES,
                            minimumChildren: [ELEMENT_TYPES.PARAGRAPH],
                        });
                    }

                    return;
                },
            ],
            [
                normalizeInlineEditableElement,
                {
                    type: ELEMENT_TYPES.INFOBOX,
                    allowedChildrenTypes: INLINE_EDITABLE_CHILDREN_TYPES,
                },
            ],
        ]),
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formProps={{ inlineEdited: useInlineEditing }}
                    formComponent={InfoboxForm}
                    generateEmbedBlock={generateLayoutBlock}
                    type={TYPE}
                />
            ),
            options,
        ),
        renderElement: renderElement(
            editor,
            [
                [NODE_TYPE, InfoboxNode],
                [ELEMENT_TYPES.INFOBOX_TITLE, InfoboxTitleElement],
                [ELEMENT_TYPES.INFOBOX_CONTENT, InfoboxContentElement],
            ],
            options,
        ),
        insertInfobox: (at) => {
            if (useInlineEditing) {
                generateLayoutBlock(
                    editor,
                    {
                        collapsed: DEFAULT_INFOBOX_COLLAPSED_VALUE,
                        title: [Element.create(DEFAULT_BLOCK)],
                        content: [Element.create(DEFAULT_BLOCK)],
                    },
                    {
                        at,
                        replace: false,
                    },
                );
                Transforms.select(editor, at);
                Transforms.collapse(editor);
            } else {
                editor.showEmbedModal(PLUGIN_NAMES.INFOBOX, undefined, at);
            }
        },
    });
};

export const withInfobox = (editor, options) =>
    internalWithInfobox(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withInfobox;
