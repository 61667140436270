import { type RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        index: true,
        lazy: async () => {
            const { VideoList } = await import('@@routes/externalContent/videos/');

            return { Component: VideoList };
        },
    },
    {
        path: 'new',
        lazy: async () => {
            const { VideoSetupPage } = await import('@@routes/externalContent/videos');

            return { Component: VideoSetupPage };
        },
    },
    {
        path: ':id/*',
        lazy: async () => {
            const { VideoDetailPage } = await import('@@routes/externalContent/videos');

            return { Component: VideoDetailPage };
        },
    },
];
