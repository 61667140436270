import { styled } from '@mui/material';
import { type CSSProperties, forwardRef, type PropsWithChildren } from 'react';

import { type Editor } from '@@editor/helpers';
import { Element, type ElementAttributes, type LeadElement } from '@@editor/helpers/Element';
import { getTextBoxStyle } from '@@editor/plugins/serializable/styles';

import HeadingWrapper from './HeadingWrapper';

type LeadWrapperProps = {
    readOnly?: boolean;
    placeholderText: string;
};

const LeadWrapper = styled(HeadingWrapper)<LeadWrapperProps>(
    ({ theme, placeholderText, readOnly }) => ({
        ...getTextBoxStyle({ theme, placeholderText }),
        ...(readOnly ? { paddingTop: '0px' } : {}),
        ...theme.typography.editorPrimaryXlarge,
    }),
);

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    element: LeadElement;
    style?: CSSProperties;
}>;

const Lead = forwardRef<HTMLElement, Props>(
    ({ attributes, children, editor, element, style }, ref) => {
        const isEmpty = Element.isEmptyTextElement(element);

        const placeholderText = isEmpty
            ? editor.t('editor.plugin.paragraph.lead.placeholderText')
            : '';

        return (
            <LeadWrapper {...{ ref, style, ...attributes, editor, element, placeholderText }}>
                {children}
            </LeadWrapper>
        );
    },
);

export default Lead;
