import { Stack, styled } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';

import Icon from '@@components/Icon';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import { type Editor, type Element } from '@@editor/helpers';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import makeState from '@@editor/utils/makeState';
import ButtonGroup from '@@form/components/ButtonGroup';
import BasicRichTextEditorField from '@@form/fields/RichTextEditor/BasicRichTextEditor';
import RichTextEditorField from '@@form/fields/RichTextEditor/RichTextEditor';

import { processElements } from '../utils';

const StyledIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.error.main,
    marginRight: theme.spacing(2),
}));

const FieldWrapper = styled('div')({
    width: '49%',
});

const FormStack = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    maxHeight: '80vh',
    overflowY: 'auto',
    width: '100%',
    paddingTop: theme.spacing(3),
}));

const InterviewError = styled('p')(({ theme }) => ({
    ...theme.typography.subtitle3,
    fontStyle: 'italic',
    width: '49%',
    display: 'flex',
    alignItems: 'center',
}));

type Props = {
    editor: Editor;
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
};

interface IFormValues {
    userInput: Element[];
    parsedText: Element[];
}

const renderButtons = (Buttons, props) => (
    <ButtonGroup>
        <Buttons.CancelButton />
        <Buttons.SubmitButton>
            {props.t('editor.plugin.interviewSegment.import.submitButton')}
        </Buttons.SubmitButton>
    </ButtonGroup>
);

const InterviewImportForm = (props: Props) => {
    const { editor, onCancel, onSubmit } = props;

    const [hasErrors, setHasErrors] = useState(false);

    const initialValues: IFormValues = useMemo(
        () => ({
            userInput: makeState(),
            parsedText: makeState(),
        }),
        [],
    );

    return (
        <ReactHookForm
            formName="InterviewImportForm"
            values={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            renderButtons={renderButtons}
            hasCancelButton
            hasActiveCancelButton
            alwaysShowCancelButton
        >
            {({ setValue }) => (
                <Stack alignItems="flex-end" justifyContent="flex-end" flex="1 0 auto">
                    <FormStack direction="row" justifyContent="space-between">
                        <FieldWrapper>
                            <BasicRichTextEditorField
                                name="userInput"
                                label={editor.t('editor.plugin.interviewSegment.import.sourceText')}
                                onChange={(value, onFieldChange) => {
                                    onFieldChange(value);
                                    const processedElements = processElements(editor, value);
                                    const errors = processedElements.find(
                                        (element) => element.data?.displayError,
                                    );

                                    setHasErrors(Boolean(errors));

                                    return setValue(
                                        'parsedText',
                                        isEmpty(processedElements)
                                            ? initialValues.parsedText
                                            : processedElements,
                                    );
                                }}
                            />
                        </FieldWrapper>

                        <FieldWrapper>
                            <RichTextEditorField
                                name="parsedText"
                                label={editor.t('editor.plugin.interviewSegment.import.parsedText')}
                                inputProps={{
                                    readOnly: true,
                                    includedPlugins: [PLUGIN_NAMES.INTERVIEW],
                                }}
                                disabled
                            />
                        </FieldWrapper>
                    </FormStack>
                    {hasErrors && (
                        <InterviewError>
                            <StyledIcon name="circle-exclamation-sharp" />
                            {editor.t('editor.plugin.interviewSegment.import.errors')}
                        </InterviewError>
                    )}
                </Stack>
            )}
        </ReactHookForm>
    );
};

export default InterviewImportForm;
