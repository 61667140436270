import { type RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: '',
        lazy: async () => {
            const { Layout } = await import('@@routes/kickwords');

            return { Component: Layout };
        },
        children: [
            {
                path: ':id',
                lazy: async () => {
                    const { KickwordForm } = await import('@@routes/kickwords/KickwordForm');

                    return { Component: KickwordForm };
                },
            },
        ],
    },
];
