import { type ReactNode } from 'react';
import { Path } from 'slate';

import { type PluginName } from '@@editor/typings/UnityPlugins';

import FloatingToolbar from './FloatingToolbar';

type Props = {
    children: ReactNode;
    path: Path;
    isLastElement?: boolean;
    activePlugins?: PluginName[];
};

const FloatingToolbarWrapper = ({ children, path, activePlugins, isLastElement }: Props) => {
    const nextPath = Path.next(path);

    return (
        <>
            <FloatingToolbar path={path} activePlugins={activePlugins} />

            {children}
            {isLastElement && <FloatingToolbar path={nextPath} activePlugins={activePlugins} />}
        </>
    );
};

export default FloatingToolbarWrapper;
