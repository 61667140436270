import { styled } from '@mui/material';
import { useCallback } from 'react';

import DndTable from '@@components/DndTable';
import { moveItem } from '@@utils/arrayUtils';

export const StyledDndTable = styled(DndTable)(({ theme }) => ({
    height: 'fit-content',
    '& th': {
        '&[data-type="lineNumber"]': {
            padding: 0,
        },
    },
    '& td': {
        '&[data-type="lineNumber"]': {
            ...theme.typography.subtitle4,
            padding: 0,
        },
        '&:last-child': {
            paddingRight: 0,
        },
    },
}));

export type OrderableTableProps = {
    columns: TableColumn[];
    onChange: (value: unknown[]) => void;
    className?: string;
    data: unknown[];
    withHeader?: boolean;
    onClickFieldAction?: (action: IContextMenuAction, column: TableColumn, record: unknown) => void;
    onDropListItem?: (sourceIndex: number, targetIndex: number) => void;
    onEndReached?: VoidFunction;
};

const OrderableTable = (props) => {
    const {
        className,
        columns,
        data,
        withHeader,
        onEndReached,
        onClickFieldAction,
        onDropListItem,
        onChange,
    } = props;

    const handleDrop = useCallback(
        (source, target) => {
            const nextValue = moveItem(data, source.index, target.index);

            if (onDropListItem) {
                onDropListItem(source.index, target.index);
            }

            onChange(nextValue);
        },
        [data, onDropListItem, onChange],
    );

    const decoratedData = data.map((item, index) => ({
        lineNumber: index + 1,
        ...item,
    }));

    const decoratedColumns = [
        {
            fieldName: 'lineNumber',
            type: 'lineNumber',
        },
        ...columns,
    ];

    return (
        <StyledDndTable
            className={className}
            data={decoratedData}
            columns={decoratedColumns}
            withHeader={withHeader}
            onEndReached={onEndReached}
            onClickFieldAction={onClickFieldAction}
            onDrop={handleDrop}
        />
    );
};

export default OrderableTable;
