import { DatePicker } from '@mui/x-date-pickers';

import type moment from 'moment';

type Props = {
    value: moment.Moment;
    inclusive?: boolean;
    onChange: (value: string | null) => void;
};

const PublishedDatePicker = (props: Props) => {
    const handleChange = (value: moment.Moment | null) => {
        if (value) {
            if (props.inclusive) {
                props.onChange(value.add(1, 'day').subtract(1, 'second').toISOString());
            }
            props.onChange(value.toISOString());
        }
    };

    return (
        <DatePicker
            {...props}
            slotProps={{ field: { clearable: true, onClear: () => props.onChange(null) } }}
            onChange={handleChange}
        />
    );
};

export default PublishedDatePicker;
