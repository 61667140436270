import { initContract } from '@ts-rest/core';
import { initTsrReactQuery } from '@ts-rest/react-query/v5';
import { useStore } from 'react-redux';
import { z } from 'zod';

import { commonResponses } from '@@api/commonResponses';
import { clientDefaults } from '@@api/constants/clientDefaults';
import { DefaultHeaders } from '@@api/headers';
import {
    Notification,
    NotificationCategory,
    NotificationStatus,
} from '@@api/services/notification/schemas';
import { getQueryKeys } from '@@api/utils/queryKeys';
import { PaginationParams, SearchParams } from '@@api/utils/schemas/queryParams';
import { paginated } from '@@api/utils/schemas/utils';
import config from '@@config';
import { type RootState } from '@@scripts/store/store';

const contract = initContract();

const router = contract.router(
    {
        categories: contract.router({
            get: {
                method: 'GET',
                path: '/categories',
                query: z.object({
                    ...SearchParams.pick({ tenantIds: true }).shape,
                }),
                responses: {
                    200: z.array(NotificationCategory),
                },
            },
        }),
        content: contract.router({
            getAll: {
                method: 'GET',
                path: '/notifications/content',
                query: z.object({
                    ...PaginationParams.shape,
                    metadataId: z.number(),
                }),
                responses: {
                    200: paginated(Notification),
                },
            },
            get: {
                method: 'GET',
                path: '/notifications/content/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: Notification,
                },
            },
            post: {
                method: 'POST',
                path: '/notifications/content',
                body: Notification,
                responses: {
                    200: Notification,
                },
            },
            put: {
                method: 'PUT',
                path: '/notifications/content/:id',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                body: Notification,
                responses: {
                    200: Notification,
                },
            },
            getStatus: {
                method: 'GET',
                path: '/notifications/content/:id/status',
                pathParams: z.object({
                    id: z.coerce.number(),
                }),
                responses: {
                    200: z.array(NotificationStatus),
                },
            },
        }),
    },
    {
        baseHeaders: DefaultHeaders,
        commonResponses,
    },
);

export type NotificationRouter = typeof router;

const notificationClient = (state: RootState) => {
    const clientArgs = {
        baseUrl: config.apiEnvVars.notification,
        ...clientDefaults(state),
    };
    const client = initTsrReactQuery(router, clientArgs);
    const queryKeys = getQueryKeys(router, clientArgs);

    return { client, queryKeys };
};

export const useNotificationClient = () => {
    const store = useStore<RootState>();
    const state = store.getState();

    return notificationClient(state);
};
