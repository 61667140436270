export const generateKeyForRichTextEditorLoadingStatus = ({
    editorId,
    // Once every elemant will have a unique id, this default value can be removed (latest
    // after migration to Plate.js).
    loadingStatusId = crypto.randomUUID(),
    type = 'load',
}: {
    editorId: string;
    loadingStatusId: string | undefined;
    type?: string;
}) => `/richTextEditorId/${editorId}/loadingStatusId/${loadingStatusId}/type/${type}`;

export const generateKeyForSlideshowSlide = ({
    slideshowId,
    slideId,
    type,
}: {
    slideshowId: string;
    slideId: string | number;
    type: string;
}) => `/slidehow/${slideshowId}/slide/${slideId}/type/${type}`;

export const generateLookupKeyForRichTextEditorLoadingStatuses = ({
    editorId,
}: {
    editorId: string;
}) => new RegExp(`^/richTextEditorId/${editorId}/loadingStatusId/.*/type/.*`);
