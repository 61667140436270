import { IconButton, Stack } from '@mui/material';
import { type FC } from 'react';

import { type AiToolsRouter, useAiToolsClient } from '@@api/services/aiTools/client';
import { type ResultChoice } from '@@api/services/aiTools/schemas';
import { getQueryParams } from '@@api/utils/getQueryParams';
import Icon from '@@components/Icon';
import { PATCH_CONTENT_TYPES } from '@@constants/http';

type Props = {
    id: string;
    onSuccess: () => void;
};

const FeedbackEvaluation: FC<Props> = ({ id, onSuccess }) => {
    const { client: aiToolsClient } = useAiToolsClient();
    const { mutate: patchResource } = aiToolsClient.resultChoice.patch.useMutation();

    const query = getQueryParams<AiToolsRouter['resultChoice']['patch']>({
        id,
    });
    const handleFeedbackOnClick = (feedback: ResultChoice['feedbackEvaluation']) => {
        patchResource(
            {
                query,
                headers: {
                    'content-type': PATCH_CONTENT_TYPES.PATCH,
                },
                body: {
                    feedbackEvaluation: feedback,
                },
            },
            {
                onSuccess,
            },
        );
    };

    return (
        <Stack direction="row" alignItems="flex-start">
            <IconButton onClick={() => handleFeedbackOnClick('THUMBS_UP')}>
                <Icon name="thumbs-up" size="L" />
            </IconButton>

            <IconButton onClick={() => handleFeedbackOnClick('THUMBS_DOWN')}>
                <Icon name="thumbs-down" size="L" />
            </IconButton>
        </Stack>
    );
};

export default FeedbackEvaluation;
