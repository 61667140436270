import { useTranslation } from 'react-i18next';

import Spacer from '@@components/Spacer';
import useFieldArray from '@@containers/ReactHookForm/useFieldArray';
import AnswersArrayField from '@@editor/plugins/serializable/layout/components/AnswersArrayField';
import SimpleRichTextEditorField from '@@form/fields/RichTextEditor/SimpleRichTextEditor';

type Props = {
    groupName?: string;
};

const PollFields = ({ groupName }: Props) => {
    const { t } = useTranslation();
    const makeName = (name) => (groupName ? `${groupName}.${name}` : name);
    const fieldArray = useFieldArray({ name: makeName('answers') });

    return (
        <>
            <SimpleRichTextEditorField
                name={makeName('question')}
                label={t('editor.plugin.layout.form.question')}
                required
            />
            <Spacer v md />

            <AnswersArrayField {...fieldArray} name={makeName('answers')} />
        </>
    );
};

export default PollFields;
