/* eslint-disable no-magic-numbers */
import {
    type CSSObject,
    Stack,
    styled,
    type Theme,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Range } from 'slate';
import { useFocused, useSlateStatic } from 'slate-react';

import Icon from '@@components/Icon';
import Spacer from '@@components/Spacer';
import { useTotalCharacterCount } from '@@containers/CharacterCount/CharacterCountContext';
import { Editor, Element } from '@@editor/helpers';
import {
    getSingleElementCharacterCount,
    isSingleElementWithoutCharacterCount,
} from '@@utils/charactersCount';

import { type ToolbarButtonConfig } from '../typings';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { ButtonsWrapper as ToolbarButtonsWrapper, Wrapper as ToolbarWrapper } from './Toolbar';
import { renderButtons } from '../utils/renderButtons';

const getReadOnlyWrapper = (theme: Theme): CSSObject => ({
    margin: `${theme.spacing(4)} -${theme.spacing(6)} 0`,
    borderTopWidth: theme.borders[2],
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.primary['100'],
    justifyContent: 'end',
    top: `-${theme.borders[2]}`,
});

const Wrapper = styled(ToolbarWrapper)<{ disabled?: boolean; $readOnly?: boolean }>(
    ({ theme, $readOnly }) => ({
        borderBottomWidth: theme.borders[2],
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.primary['100'],
        top: 0,
        margin: `0 -${theme.spacing(4)}`,
        padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
        ...($readOnly ? getReadOnlyWrapper(theme) : {}),
    }),
);

const ButtonsWrapper = styled(ToolbarButtonsWrapper)(({ theme }) => ({
    padding: theme.spacing(2, 0),
}));

const CountersWrapper = styled(Stack)<{ focused?: boolean }>(({ theme, focused }) => ({
    height: 'auto',
    color: focused ? theme.palette.primary.dark : theme.palette.primary.main,
    textAlign: 'right',
    flexGrow: 1,
}));

const ProgressWrapper = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    marginLeft: 'auto',
    position: 'relative',
    marginRight: theme.spacing(2),
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
    position: 'absolute',
    right: `-${theme.spacing(4)}`,
    top: '50%',
    transform: 'translateY(-50%)',
}));

type Counters = {
    element: number;
    total: number;
};
type Props = {
    buttonsConfig: ToolbarButtonConfig[];
    disabled?: boolean;
    readOnly?: boolean;
    targetTextLength?: number;
};

const WARNING_PERCENT_VALUE = 110;

const useProgressColors = (value: number) => {
    const theme = useTheme();

    if (value >= 90 && value <= 100) {
        return {
            mainColor: theme.palette.success.main,
            secondaryColor: theme.palette.success['100'],
            textColor: 'success.main',
        };
    }

    if (value > 100 && value <= 110) {
        return {
            mainColor: theme.palette.success.main,
            secondaryColor: theme.palette.success.main,
            textColor: 'success.main',
        };
    }

    if (value > WARNING_PERCENT_VALUE && value <= 200) {
        return {
            mainColor: theme.palette.success.main,
            secondaryColor: theme.palette.warning.main,
            textColor: 'warning.main',
        };
    }

    if (value > 200) {
        return {
            mainColor: theme.palette.warning.main,
            secondaryColor: theme.palette.warning.main,
            textColor: 'warning.main',
        };
    }

    return {
        mainColor: theme.palette.primary.main,
        secondaryColor: theme.palette.primary.light,
        textColor: 'primary.main',
    };
};

const Toolbar = ({ buttonsConfig, disabled, readOnly, targetTextLength }: Props) => {
    const { t } = useTranslation();
    const editor = useSlateStatic();
    const isFocused = useFocused();

    const selectionStart = editor.selection ? Range.start(editor.selection).path : null;
    const elementNodeEntry =
        (selectionStart &&
            Editor.above<Element>(editor, {
                at: selectionStart,
                match: (node) => Element.isElement(node),
                mode: 'highest',
            })) ||
        ([] as Element[]);

    const characterCount = getSingleElementCharacterCount(editor, elementNodeEntry[0]);
    const allCharacters = useTotalCharacterCount(editor.children);

    const counters: Counters = {
        element: characterCount,
        total: allCharacters,
    };

    const showElementCounter =
        isFocused &&
        Boolean(editor.selection) &&
        !isSingleElementWithoutCharacterCount(elementNodeEntry[0]);

    const percent = targetTextLength ? (counters.total * 100) / targetTextLength : 0;
    const roundedPercent = percent < 106.5 ? Math.floor(percent) : Math.ceil(percent);

    const showCircularProgress = !readOnly && targetTextLength;

    const { mainColor, secondaryColor, textColor } = useProgressColors(roundedPercent);

    return (
        <Wrapper
            $readOnly={readOnly}
            alignItems="center"
            contentEditable={false}
            disabled={disabled}
        >
            {!readOnly && (
                <ButtonsWrapper>{renderButtons(buttonsConfig, { disabled })}</ButtonsWrapper>
            )}

            <ProgressWrapper alignItems="center" justifyContent="flex-end">
                <CountersWrapper justifyContent="flex-end">
                    {showElementCounter && (
                        <Typography color="primary.main">
                            {t('editor.stickyToolbar.counter.element', {
                                quantity: counters.element,
                            })}
                        </Typography>
                    )}

                    <Typography color={textColor}>
                        {t('editor.stickyToolbar.counter.total', { quantity: counters.total })}
                        {targetTextLength && <span> / {targetTextLength}</span>}
                    </Typography>
                </CountersWrapper>

                {showCircularProgress && (
                    <Stack direction="row">
                        <Spacer sm h />

                        <CircularProgressWithLabel
                            percent={roundedPercent}
                            mainColor={mainColor}
                            secondaryColor={secondaryColor}
                            textColor={textColor}
                        />

                        {percent > WARNING_PERCENT_VALUE && (
                            <Tooltip
                                color="warning"
                                placement="right"
                                title={t('editor.stickyToolbar.counter.warning')}
                            >
                                <StyledIcon name="triangle-exclamation" color="warning" />
                            </Tooltip>
                        )}
                    </Stack>
                )}
            </ProgressWrapper>
        </Wrapper>
    );
};

export default Toolbar;
