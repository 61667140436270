import { styled } from '@mui/material';
import { isEqual, pick } from 'lodash-es';
import { useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { type CropMarks } from '@@api/utils/schemas/schemas';

import {
    deserializeCropMarks,
    getAspectRatioNumber,
    getImageRatio,
    validateAspectRatio,
    validateCropMarks,
} from './utils';
import { DEFAULT_CROP_MARKS } from '../constants';

export const DEFAULT_IMAGE_SIZE = 280;
export const SMALL_LANDSCAPE_IMAGE_SIZE = 216;
export const SMALL_PORTRAIT_IMAGE_SIZE = 240;
const DEFAULT_CROP_MARKS_PROPS = Object.keys(DEFAULT_CROP_MARKS);

const HALF = 50;
const THIRD = 33;

type ImageSize = Pick<CropMarks, 'width' | 'height'>;

const calculateImageSize = (image: ImageSize, small: boolean | undefined) => {
    const smallSize =
        image.width >= image.height ? SMALL_LANDSCAPE_IMAGE_SIZE : SMALL_PORTRAIT_IMAGE_SIZE;

    const imageSize = small ? smallSize : DEFAULT_IMAGE_SIZE;

    return image.width > image.height
        ? {
              width: imageSize,
              height: (image.height / image.width) * imageSize,
          }
        : {
              width: (image.width / image.height) * imageSize,
              height: imageSize,
          };
};

type Props = {
    className?: string;
    src: string;
    value: CropMarks;
    onChange: (crop: CropMarks) => void;
    onImageLoaded: (image: HTMLImageElement) => void;
    aspectRatio: string;
    setCroppingRatio?: (ratio: string) => void;
    onComplete: (crop: CropMarks, percentageCrop: CropMarks) => void;
    onImageError: VoidFunction;
    small?: boolean;
    naturalWidth?: number | null;
    naturalHeight?: number | null;
};

const ImageCrop = ({
    className,
    onChange = () => {},
    onImageLoaded = () => {},
    aspectRatio,
    value,
    src,
    onImageError,
    onComplete,
    setCroppingRatio,
    small,
    naturalWidth,
    naturalHeight,
}: Props) => {
    const [minWidth, setMinWidth] = useState<number>(20);
    const [minHeight, setMinHeight] = useState<number>(20);
    const [imageSize, setImageSize] = useState<ImageSize | undefined>();

    const handleOnChange = (crop: CropMarks, percentCrop: CropMarks) => {
        const areValidCropMarks =
            validateCropMarks(percentCrop, { minWidth, minHeight }) &&
            validateAspectRatio(crop, Number(aspectRatio));

        if (areValidCropMarks) {
            onChange(pick(percentCrop, DEFAULT_CROP_MARKS_PROPS) as CropMarks);
        }
    };

    const handleOnImageLoaded = (image: HTMLImageElement) => {
        const width = naturalWidth && naturalHeight ? naturalWidth : image.naturalWidth;
        const height = naturalWidth && naturalHeight ? naturalHeight : image.naturalHeight;

        setMinWidth(width > height ? THIRD : HALF);
        setMinHeight(height > width ? THIRD : HALF);

        setImageSize({
            width,
            height,
        });

        onImageLoaded(image);

        if (isEqual(value, deserializeCropMarks(DEFAULT_CROP_MARKS)) && aspectRatio) {
            onChange(
                centerCrop(
                    makeAspectCrop(
                        {
                            unit: '%',
                            width: 100,
                        },
                        Number(getAspectRatioNumber(aspectRatio)),
                        width,
                        height,
                    ),
                    width,
                    height,
                ),
            );
        }
    };

    return (
        <ReactCrop
            className={`${className} ReactCrop--no-animate`}
            crop={imageSize && { ...value, unit: '%' }}
            onChange={handleOnChange}
            minWidth={minWidth}
            minHeight={minHeight}
            keepSelection
            ruleOfThirds
            aspect={getAspectRatioNumber(aspectRatio)}
            onComplete={(crop, percentCrop) => {
                if (setCroppingRatio) {
                    setCroppingRatio(getImageRatio(crop));
                }
                onComplete(crop, percentCrop);
            }}
        >
            <img
                src={src}
                onLoad={(e) => handleOnImageLoaded(e.currentTarget)}
                onError={onImageError}
                style={{
                    ...(imageSize ? calculateImageSize(imageSize, small) : {}),
                }}
            />
        </ReactCrop>
    );
};

export default styled(ImageCrop)({
    userSelect: 'none',
    verticalAlign: 'top',
});
