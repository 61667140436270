import UnitySlideshow from '@@containers/Slideshow/UnitySlideshow';
import { type Element } from '@@editor/helpers';

import { getSlideshowId } from '../utils';

type Props = {
    element: Element;
    readOnly: boolean;
};

const Slideshow = ({ element, readOnly }: Props) => (
    <UnitySlideshow slideshowId={getSlideshowId(element.data)} readOnly={readOnly} />
);

export default Slideshow;
