import { type RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: '',
        lazy: async () => {
            const { Layout } = await import('@@routes/newsletters');

            return { Component: Layout };
        },
        children: [
            {
                path: ':id',
                lazy: async () => {
                    const { default: NewsletterForm } = await import(
                        '@@routes/newsletters/NewsletterForm'
                    );

                    return { Component: NewsletterForm };
                },
            },
        ],
    },
];
