import { castArray, first, flow, map, partialRight, pick } from 'lodash-es';

import { DEFAULT_BLOCK } from '@@editor/constants';
import { Element, ELEMENT_TYPES, type InterviewSegmentElement } from '@@editor/helpers/Element';
import transformValues from '@@editor/serialization/transformValues';
import { type UnityInterviewSegment } from '@@editor/typings/UnityElements';
import makeDefaultTextIfNull from '@@editor/utils/makeDefaultUnityTextIfNull';

import { type SerializerOptions } from '../../types';
import { CURRENT_VERSION } from './../../constants';
import { createElement } from './../../deserializeNodes';

const serializeInlineInterviewSegmentData = (
    children: Element[],
    data: InterviewSegmentElement['data'],
) => {
    const newData = { ...data };
    const question = children.find(Element.isInterviewSegmentQuestionElement);
    const answers = children.filter(Element.isInterviewSegmentAnswerElement);

    if (question) {
        newData.question = [{ ...DEFAULT_BLOCK, children: question.children }];
    }

    if (answers.length > 0) {
        newData.answers = answers.map((answer) => [
            { ...DEFAULT_BLOCK, children: answer.children },
        ]);
    }

    return newData;
};

const interviewRules = {
    deserialize: (element: UnityInterviewSegment, next, options?): InterviewSegmentElement => {
        const values = pick(element, ['question', 'answers', 'templateElement']);

        return createElement<InterviewSegmentElement>(
            ELEMENT_TYPES.INTERVIEW_SEGMENT,
            transformValues<typeof values, InterviewSegmentElement['data']>(values, [
                ['question', flow(makeDefaultTextIfNull, next, castArray)],
                ['answers', partialRight(map, flow(makeDefaultTextIfNull, next, castArray))],
            ]),
            options,
        );
    },

    serialize: (
        node: InterviewSegmentElement,
        next,
        options: SerializerOptions = {},
    ): UnityInterviewSegment => {
        const { type, data, children } = node;
        const { useInlineEditing } = options;
        const newData = useInlineEditing
            ? serializeInlineInterviewSegmentData(children, data)
            : data;

        return {
            version: CURRENT_VERSION,
            type,
            ...transformValues<typeof newData, Omit<UnityInterviewSegment, 'version' | 'type'>>(
                newData,
                [
                    ['question', flow(first, next)],
                    ['answers', partialRight(map, flow(first, next))],
                ],
            ),
        };
    },
};

export default interviewRules;
