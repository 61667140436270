import { omit } from 'lodash-es';

import { MIME_TYPES } from '@@editor/helpers/Element';
import { type GenerateEmbedBlockData } from '@@editor/typings/Embed';

export const generateEmbedBlockData: GenerateEmbedBlockData = ({ embed, src, originalSrc }) => ({
    mimetype: MIME_TYPES.EMBEDDED_CONTENT,
    src,
    originalSrc,
    embed: omit(embed, ['iframe', 'nodeKey']),
});
