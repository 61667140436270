import { Outlet, type RouteObject } from 'react-router-dom';

import { TenantsParamsProvider } from '@@containers/TenantSpecific/TenantsParamsContext';

export const routes: RouteObject[] = [
    {
        element: (
            <TenantsParamsProvider>
                <Outlet />
            </TenantsParamsProvider>
        ),
        children: [
            {
                index: true,
                lazy: async () => {
                    const { BreakingnewsList } = await import(
                        '@@routes/breakingnews/BreakingnewsList'
                    );

                    return { Component: BreakingnewsList };
                },
            },
            {
                path: 'new',
                lazy: async () => {
                    const { BreakingnewsSetupPage } = await import('@@routes/breakingnews');

                    return { Component: BreakingnewsSetupPage };
                },
            },
            {
                path: ':id/*',
                lazy: async () => {
                    const { BreakingnewsDetailPage } = await import('@@routes/breakingnews');

                    return { Component: BreakingnewsDetailPage };
                },
            },
        ],
    },
];
