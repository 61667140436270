import { createContext } from 'react';

import { type ModalContextType } from './types';

const initialContextValue: ModalContextType = {
    modals: [],
};

const ModalContext = createContext<ModalContextType>(initialContextValue);

export default ModalContext;
