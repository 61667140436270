import { type QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { type ReactNode } from 'react';

import { useAiToolsClient } from './services/aiTools/client';
import { useAttachmentsClient } from './services/attachments/client';
import { useAuthClient } from './services/auth/client';
import { useBreakingNewsClient } from './services/breakingnews/client';
import { useContentClient } from './services/content/client';
import { useExternalContentClient } from './services/externalContent/client';
import { useFilerepoClient } from './services/filerepo/client';
import { useFunctionsClient } from './services/functions/client';
import { useLivetickerClient } from './services/liveticker/client';
import { useMetadataClient } from './services/metadata/client';
import { useNewsletterClient } from './services/newsletter/client';
import { useNotificationClient } from './services/notification/client';
import { usePlacementClient } from './services/placement/client';
import { useReleaseNotesClient } from './services/releasenotes/client';
import { useRevisionsClient } from './services/revisions/client';
import { useRtElementsClient } from './services/rtelements/client';
import { useSlideshowClient } from './services/slideshow/client';
import { useSpellCheckerClient } from './services/spellChecker/client';
import { useTenantClient } from './services/tenant/client';
import { useUserPresenceClient } from './services/userPresence/client';

const ApiClientProvider = ({
    children,
    queryClient,
}: {
    children: ReactNode;
    queryClient: QueryClient;
}) => {
    const { client: aiToolsClient } = useAiToolsClient();
    const { client: attachmentsClient } = useAttachmentsClient();
    const { client: authClient } = useAuthClient();
    const { client: breakingnewsClient } = useBreakingNewsClient();
    const { client: contentClient } = useContentClient();
    const { client: externalContentClient } = useExternalContentClient();
    const { client: filerepoClient } = useFilerepoClient();
    const { client: functionsClient } = useFunctionsClient();
    const { client: livetickerClient } = useLivetickerClient();
    const { client: metadataClient } = useMetadataClient();
    const { client: newsletterClient } = useNewsletterClient();
    const { client: notificationClient } = useNotificationClient();
    const { client: placementClient } = usePlacementClient();
    const { client: releasenotesClient } = useReleaseNotesClient();
    const { client: revisionsClient } = useRevisionsClient();
    const { client: rtElementsClient } = useRtElementsClient();
    const { client: slideshowClient } = useSlideshowClient();
    const { client: spellcheckerClient } = useSpellCheckerClient();
    const { client: tenantClient } = useTenantClient();
    const { client: userPresenceClient } = useUserPresenceClient();

    // We do not use a loop here to avoid unnecessary re-renders.
    // Since this provider is a close to the root of the app,
    // excessive re-renders will cause visible performance reduction.
    // It's more verbose like this, but isolated.
    return (
        <QueryClientProvider client={queryClient}>
            <aiToolsClient.ReactQueryProvider>
                <attachmentsClient.ReactQueryProvider>
                    <authClient.ReactQueryProvider>
                        <breakingnewsClient.ReactQueryProvider>
                            <contentClient.ReactQueryProvider>
                                <externalContentClient.ReactQueryProvider>
                                    <filerepoClient.ReactQueryProvider>
                                        <functionsClient.ReactQueryProvider>
                                            <livetickerClient.ReactQueryProvider>
                                                <metadataClient.ReactQueryProvider>
                                                    <newsletterClient.ReactQueryProvider>
                                                        <notificationClient.ReactQueryProvider>
                                                            <placementClient.ReactQueryProvider>
                                                                <releasenotesClient.ReactQueryProvider>
                                                                    <revisionsClient.ReactQueryProvider>
                                                                        <rtElementsClient.ReactQueryProvider>
                                                                            <slideshowClient.ReactQueryProvider>
                                                                                <spellcheckerClient.ReactQueryProvider>
                                                                                    <tenantClient.ReactQueryProvider>
                                                                                        <userPresenceClient.ReactQueryProvider>
                                                                                            {
                                                                                                children
                                                                                            }
                                                                                        </userPresenceClient.ReactQueryProvider>
                                                                                    </tenantClient.ReactQueryProvider>
                                                                                </spellcheckerClient.ReactQueryProvider>
                                                                            </slideshowClient.ReactQueryProvider>
                                                                        </rtElementsClient.ReactQueryProvider>
                                                                    </revisionsClient.ReactQueryProvider>
                                                                </releasenotesClient.ReactQueryProvider>
                                                            </placementClient.ReactQueryProvider>
                                                        </notificationClient.ReactQueryProvider>
                                                    </newsletterClient.ReactQueryProvider>
                                                </metadataClient.ReactQueryProvider>
                                            </livetickerClient.ReactQueryProvider>
                                        </functionsClient.ReactQueryProvider>
                                    </filerepoClient.ReactQueryProvider>
                                </externalContentClient.ReactQueryProvider>
                            </contentClient.ReactQueryProvider>
                        </breakingnewsClient.ReactQueryProvider>
                    </authClient.ReactQueryProvider>
                </attachmentsClient.ReactQueryProvider>
            </aiToolsClient.ReactQueryProvider>
        </QueryClientProvider>
    );
};

export default ApiClientProvider;
