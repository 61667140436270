import isPropValid from '@emotion/is-prop-valid';
import { styled, type Theme } from '@mui/material';
import { type CSSProperties, forwardRef, type PropsWithChildren } from 'react';

import { type Editor, ReactEditor } from '@@editor/helpers';
import { type CrossheadElement, Element, type ElementAttributes } from '@@editor/helpers/Element';
import {
    type BadgeProps,
    getTextReadOnlyBadgeStyle,
    READONLY_BADGE_ATTRIBUTE,
} from '@@editor/plugins/serializable/styles';

import {
    CROSSHEAD_STYLES,
    CROSSHEAD_TRANSLATION_KEYS,
    EDITOR_TO_ORDERED_LISTICLES,
} from './../constants';
import { getCrossheadStyle } from './../utils';

const getArticleEditorCrossheadStyle = ({
    $isListicle,
    $isSubsection,
    theme,
}: {
    $isListicle: boolean;
    $isSubsection: boolean;
    theme: Theme;
}) => ({
    justifyContent: $isListicle ? 'center' : 'inherit',
    alignItems: 'center',
    flexGrow: 1,
    ...theme.typography.editorSecondaryLarge,
    fontWeight: $isSubsection ? '600' : 'bold',
    ...($isSubsection && { ...{ paddingLeft: theme.spacing(4) } }),
});

type CrossheadWrapperProps = {
    $isListicle: boolean;
    $isArticleEditor?: boolean;
    $isSubsection: boolean;
    readOnly?: boolean;
    showBadge?: boolean;
} & BadgeProps;

const CrossheadWrapper = styled('p', {
    shouldForwardProp: (prop: string) => isPropValid(prop),
})<CrossheadWrapperProps>(
    ({ $isListicle, $isArticleEditor, $isSubsection, readOnly, showBadge, theme, ...props }) => ({
        position: 'relative',
        margin: 0,
        ...theme.typography.title4,
        ...(!$isArticleEditor &&
            readOnly &&
            showBadge &&
            getTextReadOnlyBadgeStyle({ theme, ...props })),
        ...($isArticleEditor &&
            getArticleEditorCrossheadStyle({ $isListicle, $isSubsection, theme })),
    }),
);

const ListicleWrapper = styled('div')<{
    $isOrderedListicle: boolean;
    $orderedListicleNumber: number;
}>(({ $isOrderedListicle, $orderedListicleNumber, theme }) => ({
    display: 'inline-block',
    paddingTop: theme.spacing(2),

    '&::before': {
        content: $isOrderedListicle ? `'${$orderedListicleNumber}. '` : "'• '",
    },
}));

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    element: CrossheadElement;
    style?: CSSProperties;
}>;

const Crosshead = forwardRef<HTMLElement, Props>(
    ({ attributes, children, editor, element, style }, ref) => {
        const readOnly = ReactEditor.isReadOnly(editor);
        const crossheadStyle = getCrossheadStyle(element);
        const badgeText = editor.t(CROSSHEAD_TRANSLATION_KEYS[crossheadStyle]);
        const styleProps = { [READONLY_BADGE_ATTRIBUTE]: badgeText };
        const isArticleEditor = editor.isArticleEditor;

        const isListicle = [
            CROSSHEAD_STYLES.ORDERED_LISTICLE,
            CROSSHEAD_STYLES.UNORDERED_LISTICLE,
        ].includes(crossheadStyle);

        const isSubsection = crossheadStyle === CROSSHEAD_STYLES.SUBSECTION;

        const isOrderedListicle = crossheadStyle === CROSSHEAD_STYLES.ORDERED_LISTICLE;

        const orderedListicles = EDITOR_TO_ORDERED_LISTICLES.get(editor) ?? [];
        const orderedListicleIndex = Array.from(orderedListicles).findIndex(
            (node) => node === element,
        );
        const orderedListicleNumber = orderedListicleIndex !== -1 ? orderedListicleIndex + 1 : 0;

        const showBadge = !Element.isTemplateElement(element);

        const content =
            isArticleEditor && isListicle ? (
                <ListicleWrapper
                    $isOrderedListicle={isOrderedListicle}
                    $orderedListicleNumber={orderedListicleNumber}
                >
                    {children}
                </ListicleWrapper>
            ) : (
                children
            );

        return (
            <CrossheadWrapper
                {...{ ref, style, ...attributes, ...styleProps, readOnly, showBadge }}
                data-type={badgeText}
                $isListicle={isListicle}
                $isSubsection={isSubsection}
                $isArticleEditor={isArticleEditor}
            >
                {content}
                <span contentEditable={false} />
            </CrossheadWrapper>
        );
    },
);

export default Crosshead;
