type UpdateHeight = (height: number) => void;
type SetAutofit = (id: string) => void;

const frames: Record<string, { updateHeight: UpdateHeight; setAutofit: SetAutofit }> = {};

export const register = (updateHeight: UpdateHeight, setAutofit: SetAutofit): string => {
    const id = 'autofit_' + crypto.randomUUID();

    if (!frames[id]) {
        frames[id] = { updateHeight, setAutofit };

        return id;
    }

    return register(updateHeight, setAutofit);
};

export const unregister = (id: string): void => {
    if (frames[id]) {
        delete frames[id];
    }
};

const run = (e: MessageEvent): void => {
    const messageObject = e.data as { type: string; iframeId?: string; contentHeight?: number };

    if (messageObject.type === 'autofit') {
        if (messageObject.iframeId) {
            if (messageObject.contentHeight) {
                if (frames[messageObject.iframeId]) {
                    frames[messageObject.iframeId].updateHeight(messageObject.contentHeight);
                } else {
                    unregister(messageObject.iframeId);
                }
            }
        } else {
            Object.keys(frames).forEach((id) => {
                if (frames[id]) {
                    frames[id].setAutofit(id);
                }
            });
        }
    }
};

window.addEventListener('message', run);
