export type Listener = {
    eventName:
        | typeof ActionType.LOAD
        | typeof ActionType.LOAD_PROGRESS
        | typeof ActionType.LOAD_SUCCESS
        | typeof ActionType.LOAD_ERROR;
    fn: (
        payload:
            | LoadAction['payload']
            | LoadProgressAction['payload']
            | LoadSuccessAction['payload']
            | LoadErrorAction['payload'],
    ) => void;
    key: Key;
};

type Key = string;
type Progress = { value: number; max: number; progress: number };

type LoadStatus = {
    isLoading: true;
    progress?: never;
    data?: never;
    error?: never;
};

type ProgressStatus = {
    isLoading: true;
    progress: Progress;
    data?: never;
    error?: never;
};

type SuccessStatus = {
    isLoading: false;
    progress?: never;
    data?: AnyObject;
    error?: never;
};

type ErrorStatus = {
    isLoading: false;
    progress?: never;
    data?: never;
    error: Error;
};

export type Status = LoadStatus | ProgressStatus | SuccessStatus | ErrorStatus;

export type ReducerState = {
    listeners: Listener[];
    statuses: Record<Key, Status>;
};

export type ContextValue = ReducerState['statuses'];

export type DispatchContextValue = {
    addListener: (
        eventName: AddListenerAction['payload']['eventName'],
        fn: AddListenerAction['payload']['fn'],
        key: AddListenerAction['payload']['key'],
    ) => void;
    removeListener: (
        eventName: RemoveListenerAction['payload']['eventName'],
        fn: RemoveListenerAction['payload']['fn'],
    ) => void;
};

export const ActionType = {
    ADD_LISTENER: 'ADD_LISTENER',
    REMOVE_LISTENER: 'REMOVE_LISTENER',
    LOAD: 'LOAD',
    LOAD_PROGRESS: 'LOAD_PROGRESS',
    LOAD_SUCCESS: 'LOAD_SUCCESS',
    LOAD_ERROR: 'LOAD_ERROR',
    CANCEL_LOADING: 'CANCEL_LOADING',
} as const;

export type ActionType = ValueOf<typeof ActionType>;

export type AddListenerAction = {
    type: typeof ActionType.ADD_LISTENER;
    payload: Listener;
};

export type RemoveListenerAction = {
    type: typeof ActionType.REMOVE_LISTENER;
    payload: Omit<Listener, 'key'>;
};

export type LoadAction = {
    type: typeof ActionType.LOAD;
    payload: {
        key: Key;
    };
};

export type LoadProgressAction = {
    type: typeof ActionType.LOAD_PROGRESS;
    payload: {
        key: Key;
        progress: Progress;
    };
};

export type LoadSuccessAction<TData extends UnknownObject | undefined = UnknownObject | undefined> =
    {
        type: typeof ActionType.LOAD_SUCCESS;
        payload: TData extends undefined
            ? {
                  key: Key;
                  data?: TData;
              }
            : {
                  key: Key;
                  data: TData;
              };
    };

export type LoadErrorAction = {
    type: typeof ActionType.LOAD_ERROR;
    payload: {
        key: Key;
        error: Error;
    };
};

export type CancelLoadingAction = {
    type: typeof ActionType.CANCEL_LOADING;
    payload: {
        key: Key;
    };
};

export type Action =
    | AddListenerAction
    | RemoveListenerAction
    | LoadAction
    | LoadProgressAction
    | LoadSuccessAction
    | LoadErrorAction
    | CancelLoadingAction;

export type UseLoadingStatusManagerProps<
    TData extends UnknownObject | undefined = UnknownObject | undefined,
> = {
    key: Key;
    onLoad?: (payload: LoadAction['payload']) => void;
    onLoadProgress?: (payload: LoadProgressAction['payload']) => void;
    onLoadSuccess?: (payload: LoadSuccessAction<TData>['payload']) => void;
    onLoadError?: (payload: LoadErrorAction['payload']) => void;
};
