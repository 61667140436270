import { type FC, useEffect, useState } from 'react';

import AutofitIframe from '@@components/AutofitIframe/AutofitIframe';
import Loader from '@@components/Loader';

import BrokenPreview from './BrokenPreview';

type Props = {
    src: string | null;
    isLoading?: boolean;
    isValid?: boolean;
};

const EmbedPreview: FC<Props> = ({ src, isLoading = Boolean(src), isValid = true }) => {
    const [loading, setLoading] = useState(isLoading);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    const handleOnLoad = () => setLoading(false);

    if (!src) {
        return null;
    }

    return (
        <>
            {loading && <Loader />}
            {isValid && <AutofitIframe src={src} onLoad={handleOnLoad} />}
            {!isValid && !loading && <BrokenPreview />}
        </>
    );
};

export default EmbedPreview;
