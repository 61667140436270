import { isString } from 'lodash-es';
import { type FieldError } from 'react-hook-form';

import { INFORMATION_SEPARATOR_ONE } from '@@constants/Unicode';
import useReactHookFormContext from '@@containers/ReactHookForm/useReactHookFormContext';

export type FormFieldError = string[][];

const useReactHookFormFieldError = (name: string): FormFieldError | FieldError | undefined => {
    const { formState, getFieldState } = useReactHookFormContext();
    // We need to connect `getFieldState` with `formState`, because it needs to trigger a a re-render when an
    // error on a field is raised. Otherwise we would get, for example, a wrong error state on scheduled placing
    // in metadata form for the `requiredOneOf` validation.
    const { error: fieldError } = getFieldState(name, formState);

    const errorTypes = fieldError?.types;

    // Legacy errors are the ones not handled through ZodResolver
    // and they exclusively come from the validate function
    const legacyValidationError = errorTypes?.validate;
    const legacyError =
        legacyValidationError && isString(legacyValidationError)
            ? legacyValidationError
                  .split(INFORMATION_SEPARATOR_ONE)
                  .map((error) => JSON.parse(error))
            : undefined;
    const zodError = fieldError && !legacyError ? fieldError : undefined;

    // We prioritize the legacy error over the Zod error for compatibility
    const finalError = legacyError || zodError;

    return finalError;
};

export default useReactHookFormFieldError;
