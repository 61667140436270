import { noop } from 'lodash-es';
import { type Dispatch, useReducer } from 'react';

import {
    type Action,
    type ActionFunctions,
    ActionTypes,
    type BeforeConfirmFunction,
    type Config,
    type RegisterAction,
    type State,
    type UnregisterAction,
    type WhenFunction,
} from './types';

export const initialState: State = {
    configs: [],
    when: noop,
    beforeConfirm: (result, callback) => callback(),
};

const defaultConfig: Partial<Config> = {
    beforeConfirm: () => true,
};

// `NavPrompt` binds the `when` function on mount and changes it's context when executed. Therefore
// we need to pass in all information, by using a creator function, since the function will
// run in an unknown context later
const createWhen =
    (configs: Config[]): WhenFunction =>
    (...args) => {
        for (const config of configs) {
            const { id, when } = config;

            if (when(...args)) {
                return id;
            }
        }
    };

const createBeforeConfirm =
    (configs: Config[]): BeforeConfirmFunction =>
    (result, callback) => {
        const config = configs.find(({ id }) => id === result);

        if (!config || config.beforeConfirm() !== false) {
            callback?.();
        }
    };

export const applyAction = (state: State, action: Action) => {
    switch (action.type) {
        case ActionTypes.REGISTER: {
            const configs = state.configs.concat({
                ...defaultConfig,
                ...(action.config as Config),
                id: action.id,
            });
            const when = createWhen(configs);
            const beforeConfirm = createBeforeConfirm(configs);

            return {
                ...state,
                configs,
                when,
                beforeConfirm,
            };
        }

        case ActionTypes.UNREGISTER: {
            const configs = state.configs.filter(({ id }) => id !== action.id);
            const when = createWhen(configs);
            const beforeConfirm = createBeforeConfirm(configs);

            return {
                ...state,
                configs,
                when,
                beforeConfirm,
            };
        }
    }

    return state;
};

export const createActionFunctions = (dispatch: Dispatch<Action>): ActionFunctions => ({
    register: (config) => {
        const id = crypto.randomUUID();
        const registerAction: RegisterAction = { type: ActionTypes.REGISTER, id, config };
        const unregisterAction: UnregisterAction = { type: ActionTypes.UNREGISTER, id };

        dispatch(registerAction);

        return () => dispatch(unregisterAction);
    },
});

const useLeavePromptValue = () => {
    const [state, dispatch] = useReducer(applyAction, initialState);
    const actions = createActionFunctions(dispatch);

    return { state, actions };
};

export default useLeavePromptValue;
