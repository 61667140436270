import { styled } from '@mui/material';
import { type FC } from 'react';

import PreviewImage from '@@containers/PreviewImage';
import { type DynamicTeaserElement } from '@@editor/helpers/Element';
import LeadEditor from '@@form/components/RichTextEditor/LeadEditor';

import TeaserItem from './TeaserItem';
import { PluginsWrapper } from '../../components/PluginsWrapper';
import { MAX_HEIGHT, MAX_WIDTH } from '../../constants';

const StyledLeadEditor = styled(LeadEditor)(({ theme }) => ({
    ...theme.typography.editorSecondaryMedium,
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 2),
}));

type Props = {
    element: DynamicTeaserElement;
};

const DynamicTeaser: FC<Props> = ({ element }) => {
    const { title, image, metadataIds } = element.data;

    return (
        <PluginsWrapper>
            <StyledLeadEditor value={title} readOnly />

            {image?.url && (
                <PreviewImage
                    src={image.url}
                    transformations={{
                        ...image,
                        maxWidth: MAX_WIDTH,
                        maxHeight: MAX_HEIGHT,
                    }}
                />
            )}

            {metadataIds?.map((metadataId, index) => (
                <TeaserItem key={index} metadataId={metadataId} />
            ))}
        </PluginsWrapper>
    );
};

export default DynamicTeaser;
