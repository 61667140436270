import {
    type CancelLoadingAction,
    type LoadAction,
    type LoadErrorAction,
    type LoadProgressAction,
    type LoadSuccessAction,
} from './types';

export const MessageEventType = {
    IS_LOADING_REQUEST: 'UNITY_LOADING_STATUS_MANAGER_IS_LOADING_REQUEST',
    IS_LOADING_RESPONSE: 'UNITY_LOADING_STATUS_MANAGER_IS_LOADING_RESPONSE',
    LOAD_REQUEST: 'UNITY_LOADING_STATUS_MANAGER_LOAD_REQUEST',
    LOAD_PROGRESS_REQUEST: 'UNITY_LOADING_STATUS_MANAGER_LOAD_PROGRESS_REQUEST',
    LOAD_SUCCESS_RESPONSE: 'UNITY_LOADING_STATUS_MANAGER_LOAD_SUCCESS_RESPONSE',
    LOAD_ERROR_RESPONSE: 'UNITY_LOADING_STATUS_MANAGER_LOAD_ERROR_RESPONSE',
    CANCEL_LOADING_REQUEST: 'UNITY_LOADING_STATUS_MANAGER_CANCEL_LOADING_REQUEST',
} as const;

export type MessageEventType = ValueOf<typeof MessageEventType>;

type IsLoadingRequestMessageEvent = {
    type: typeof MessageEventType.IS_LOADING_REQUEST;
    channelId: string;
    payload: { key: RegExp };
};

type IsLoadingResponseMessageEvent = {
    type: typeof MessageEventType.IS_LOADING_RESPONSE;
    channelId: string;
    payload: { isLoading: string };
};

type LoadRequestMessageEvent = {
    type: typeof MessageEventType.LOAD_REQUEST;
    payload: LoadAction['payload'];
};

type LoadProgressRequestMessageEvent = {
    type: typeof MessageEventType.LOAD_PROGRESS_REQUEST;
    payload: LoadProgressAction['payload'];
};

type LoadSuccessResponseMessageEvent = {
    type: typeof MessageEventType.LOAD_SUCCESS_RESPONSE;
    payload: LoadSuccessAction['payload'];
};

type LoadErrorResponseMessageEvent = {
    type: typeof MessageEventType.LOAD_ERROR_RESPONSE;
    payload: LoadErrorAction['payload'];
};

type CancelLoadingRequestMessageEvent = {
    type: typeof MessageEventType.CANCEL_LOADING_REQUEST;

    payload: CancelLoadingAction['payload'];
};

export const isLoadingRequestMessageEvent = (
    event: MessageEvent,
): event is MessageEvent<IsLoadingRequestMessageEvent> =>
    event.data.type === MessageEventType.IS_LOADING_REQUEST;

export const isLoadingResponseMessageEvent = (
    event: MessageEvent,
): event is MessageEvent<IsLoadingResponseMessageEvent> =>
    event.data.type === MessageEventType.IS_LOADING_RESPONSE;

export const isLoadRequestMessageEvent = (
    event: MessageEvent,
): event is MessageEvent<LoadRequestMessageEvent> =>
    event.data.type === MessageEventType.LOAD_REQUEST;

export const isLoadProgressRequestMessageEvent = (
    event: MessageEvent,
): event is MessageEvent<LoadProgressRequestMessageEvent> =>
    event.data.type === MessageEventType.LOAD_PROGRESS_REQUEST;

export const isLoadSuccessResponseMessageEvent = (
    event: MessageEvent,
): event is MessageEvent<LoadSuccessResponseMessageEvent> =>
    event.data.type === MessageEventType.LOAD_SUCCESS_RESPONSE;

export const isLoadErrorResponseMessageEvent = (
    event: MessageEvent,
): event is MessageEvent<LoadErrorResponseMessageEvent> =>
    event.data.type === MessageEventType.LOAD_ERROR_RESPONSE;

export const isCancelLoadingRequestMessageEvent = (
    event: MessageEvent,
): event is MessageEvent<CancelLoadingRequestMessageEvent> =>
    event.data.type === MessageEventType.CANCEL_LOADING_REQUEST;

class LoadingStatusManagerMessenger {
    static listen(callback) {
        const handleMessageEvent = (event) => {
            if (event.origin !== window.location.origin && process.env.MODE !== 'test') {
                return;
            }

            if (isLoadingRequestMessageEvent(event)) {
                const isLoading = callback(event);

                window.postMessage(
                    {
                        type: MessageEventType.IS_LOADING_RESPONSE,
                        channelId: event.data.channelId,
                        payload: { isLoading },
                    } satisfies IsLoadingResponseMessageEvent,
                    window.location.origin,
                );
            } else if (
                isLoadRequestMessageEvent(event) ||
                isLoadProgressRequestMessageEvent(event) ||
                isLoadSuccessResponseMessageEvent(event) ||
                isLoadErrorResponseMessageEvent(event) ||
                isCancelLoadingRequestMessageEvent(event)
            ) {
                callback(event);
            }
        };

        window.addEventListener('message', handleMessageEvent);

        return () => {
            window.removeEventListener('message', handleMessageEvent);
        };
    }

    static isLoading(payload: IsLoadingRequestMessageEvent['payload']) {
        return new Promise<IsLoadingResponseMessageEvent['payload']['isLoading']>((resolve) => {
            const channelId = crypto.randomUUID();

            const listener = (event: MessageEvent<IsLoadingResponseMessageEvent>) => {
                if (isLoadingResponseMessageEvent(event) && event.data.channelId === channelId) {
                    window.removeEventListener('message', listener);

                    resolve(event.data.payload.isLoading);
                }
            };

            window.addEventListener('message', listener);

            window.postMessage(
                {
                    type: MessageEventType.IS_LOADING_REQUEST,
                    channelId,
                    payload,
                } satisfies IsLoadingRequestMessageEvent,
                window.location.origin,
            );
        });
    }

    static load(payload: LoadRequestMessageEvent['payload']) {
        window.postMessage(
            {
                type: MessageEventType.LOAD_REQUEST,
                payload,
            } satisfies LoadRequestMessageEvent,
            window.location.origin,
        );
    }

    static loadProgress(payload: LoadProgressRequestMessageEvent['payload']) {
        window.postMessage(
            {
                type: MessageEventType.LOAD_PROGRESS_REQUEST,
                payload,
            } satisfies LoadProgressRequestMessageEvent,
            window.location.origin,
        );
    }

    static loadSuccess(payload: LoadSuccessResponseMessageEvent['payload']) {
        window.postMessage(
            {
                type: MessageEventType.LOAD_SUCCESS_RESPONSE,
                payload,
            } satisfies LoadSuccessResponseMessageEvent,
            window.location.origin,
        );
    }

    static loadError(payload: LoadErrorResponseMessageEvent['payload']) {
        window.postMessage(
            {
                type: MessageEventType.LOAD_ERROR_RESPONSE,
                payload,
            } satisfies LoadErrorResponseMessageEvent,
            window.location.origin,
        );
    }

    static cancelLoading(payload: CancelLoadingAction['payload']) {
        window.postMessage(
            {
                type: MessageEventType.CANCEL_LOADING_REQUEST,
                payload,
            } satisfies CancelLoadingRequestMessageEvent,
            window.location.origin,
        );
    }
}

export default LoadingStatusManagerMessenger;
