import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useMetadataClient } from '@@api/services/metadata/client';
import { type Metadata } from '@@api/services/metadata/schemas';
import ContentDetails from '@@containers/ContentItem/ContentDetails';
import ContentItemWithActions from '@@containers/ContentItem/ContentItemWithActions';
import { PREVIEW_IMAGE_WIDTH, Wrapper } from '@@containers/ContentItem/styles';
import linkToContent from '@@containers/ContentItem/utils/linkToContent';
import PreviewImage from '@@containers/PreviewImage';
import { getDefaultTeaserVariant } from '@@containers/Teaser/utils';
import TenantImage from '@@containers/TenantSpecific/TenantImage';
import { useTenantVariant } from '@@containers/TenantSpecific/TenantVariantContext';
import showPreview from '@@scripts/utils/showPreview';

const TeaserImageWrapper = styled('div')({
    width: '100px',
    flexShrink: 0,
});

const TeaserImage = styled(PreviewImage)({
    width: '100%',
    height: '56px',
    objectFit: 'cover',
});

const SmallTenantImage = styled(TenantImage)(({ theme }) => ({
    margin: theme.spacing(1, 1, 0, 1),
    verticalAlign: 'middle',
}));

const StyledWrapper = styled(Wrapper)({
    paddingLeft: '0',
    paddingRight: '0',
});

type Props = {
    id: Metadata['id'];
    onDelete: (teaserId: number) => void;
    readOnly?: boolean;
};

const ContentItem = ({ id, readOnly, onDelete }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();

    const params = { id };

    const { variantId } = useTenantVariant();

    const { data: metadataData } = metadataClient.metadata.get.useQuery({
        queryKey: metadataKeys.metadata.get({ params, query: {} }),
        queryData: { params },
    });

    const data = metadataData?.body;

    if (!data) {
        return null;
    }

    const entity = {
        ...data,
        ...getDefaultTeaserVariant(data.teasers),
    };

    const onEdit = () => linkToContent(data, navigate, true);

    const handleClickPreview = () => {
        showPreview(entity.id, variantId);
    };

    return (
        <ContentItemWithActions
            onPreviewClick={handleClickPreview}
            onDeleteClick={() => onDelete(entity.id)}
            readOnly={readOnly}
            onEditClick={onEdit}
        >
            <StyledWrapper $canDrag={!readOnly}>
                <TeaserImageWrapper>
                    <TeaserImage
                        src={entity.image.url}
                        transformations={{
                            ...entity.image,
                            maxWidth: PREVIEW_IMAGE_WIDTH,
                        }}
                    />
                    {entity.tenantIds.map((tenantId) => (
                        <SmallTenantImage key={tenantId} id={tenantId} size="S" natural />
                    ))}
                </TeaserImageWrapper>

                <ContentDetails
                    date={entity.publishedAt || entity.updatedAt}
                    datePrefix={entity.publishedAt && t('relatedcontent.contentItem.published')}
                    entity={entity}
                    small
                />
            </StyledWrapper>
        </ContentItemWithActions>
    );
};

export default ContentItem;
