import { type FieldPath, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    isSecureSingleIframe,
    singleIFrameOrURLValidator,
} from '@@form/utils/validators/singleIFrameOrURLValidator';
import { getSrcFromIframeString } from '@@utils/DOM';
import { isSecureUrl } from '@@utils/URL';

import TextareaField, { type TextareaFieldProps } from './Textarea';

type Value = string;

export const parseValueForUrl = (value: Value) => {
    if (isSecureUrl(value)) {
        return value;
    }

    if (isSecureSingleIframe(value)) {
        return getSrcFromIframeString(value);
    }

    return null;
};

const UrlIframe = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: TextareaFieldProps<TFieldValues, TName>,
) => {
    const { t } = useTranslation();

    return (
        <TextareaField
            placeholder={t('form.fields.urlIframe.placeholder')}
            {...props}
            validate={[singleIFrameOrURLValidator]}
        />
    );
};

export default UrlIframe;
