import { castArray, first, flow, pick } from 'lodash-es';

import { DEFAULT_BLOCK } from '@@editor/constants';
import { Element, ELEMENT_TYPES, type QuoteElement } from '@@editor/helpers/Element';
import transformValues from '@@editor/serialization/transformValues';
import { type UnityQuote } from '@@editor/typings/UnityElements';
import makeDefaultTextIfNull from '@@editor/utils/makeDefaultUnityTextIfNull';

import { type SerializerOptions } from '../../types';
import { CURRENT_VERSION } from './../../constants';
import { createElement } from './../../deserializeNodes';

const serializeInlineQuoteData = (children: Element[], data: QuoteElement['data']) => {
    const newData = { ...data };
    const quoteText = children.find(Element.isQuoteTextElement);
    const quoteCaption = children.find(Element.isQuoteCaptionElement);

    if (quoteText) {
        newData.quote = [{ ...DEFAULT_BLOCK, children: quoteText.children }];
    }

    if (quoteCaption) {
        newData.caption = [{ ...DEFAULT_BLOCK, children: quoteCaption.children }];
    }

    return newData;
};

const quoteRules = {
    deserialize: (element: UnityQuote, next, options?): QuoteElement => {
        const values = pick(element, ['quote', 'caption', 'templateElement']);

        return createElement<QuoteElement>(
            ELEMENT_TYPES.QUOTE,
            transformValues<typeof values, QuoteElement['data']>(values, [
                [['quote', 'caption'], flow(makeDefaultTextIfNull, next, castArray)],
            ]),
            options,
        );
    },

    serialize: (node: QuoteElement, next, options: SerializerOptions = {}): UnityQuote => {
        const { type, data, children } = node;
        const { useInlineEditing } = options;
        const newData = useInlineEditing ? serializeInlineQuoteData(children, data) : data;

        return {
            version: CURRENT_VERSION,
            type,
            ...transformValues<typeof newData, Omit<UnityQuote, 'version' | 'type'>>(newData, [
                [['quote', 'caption'], flow(first, next)],
            ]),
        };
    },
};

export default quoteRules;
