import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Icon, { type IconSize } from '@@components/Icon';
import type PublicationStatus from '@@constants/PublicationStatus';

import { getColorName, getI18nKey, getIconName } from './utils';

type Props = {
    className?: string;
    size?: IconSize;
    status: PublicationStatus;
    title?: string;
};

const PublicationStatusIcon = (props: Props) => {
    const { t } = useTranslation();
    const { className, status, size = 'large', title = t(getI18nKey(status)) } = props;
    const color = getColorName(status);
    const name = getIconName(status);

    return <Icon {...{ className, color, name, size, title }} />;
};

export default styled(PublicationStatusIcon)({});
