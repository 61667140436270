import { styled } from '@mui/material';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { type Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import { type ElementAttributes, type EmbedElement } from '@@editor/helpers/Element';

import { getPollPlaceholderStyle } from './styles';

type StyledPollAnswerElementProps = {
    $templateElement: boolean;
    $placeholderText: string;
};

export const StyledPollAnswerElement = styled('div')<StyledPollAnswerElementProps>(
    ({ $templateElement, $placeholderText, theme }) => ({
        ...theme.typography.editorSubtitleMedium,
        textAlign: 'center',
        padding: `0 0 ${theme.spacing(2)}`,
        ...($templateElement &&
            getPollPlaceholderStyle({ theme, placeholderText: $placeholderText })),
    }),
);

type Props = {
    attributes: ElementAttributes;
    children: ReactNode;
    editor: Editor;
    element: EmbedElement;
};

const PollAnswerElement = (props: Props) => {
    const { attributes, children, editor, element } = props;
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);

    return (
        <StyledPollAnswerElement
            {...attributes}
            $templateElement={
                Element.isTemplateElement(parentNode) || Element.isEmptyPollAnswerElement(element)
            }
            $placeholderText={t('editor.plugin.layout.form.answer')}
        >
            {children}
        </StyledPollAnswerElement>
    );
};

export default PollAnswerElement;
