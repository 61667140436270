import { datadogRum } from '@datadog/browser-rum';

import config from '@@config';

const isStagingOrProd =
    config.env === 'staging' || config.env === 'production' || config.env === 'prod';

const isDataDogEnabled = () =>
    config.dataDogApplicationId &&
    config.dataDogClientToken &&
    isStagingOrProd &&
    config.mode !== 'test' &&
    !config.isVisualTest;

export const initializeDataDogRUM = () => {
    if (isDataDogEnabled()) {
        datadogRum.init({
            applicationId: config.dataDogApplicationId,
            clientToken: config.dataDogClientToken,
            env: config.env,
            site: 'datadoghq.eu',
            // Specify a version number to identify the deployed version of your application in Datadog
            version: window.VERSION ?? undefined,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            // percent of server traces sampled
            traceSampleRate: 100,
            service: 'unitycms',
            // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
            allowedTracingUrls: [
                { match: /https:\/\/.*\.unitycms\.io/, propagatorTypes: ['b3multi'] },
            ],
        });
    }

    datadogRum.startSessionReplayRecording();
};

export const setDataDogRUMUserId = (id: string) => {
    if (isDataDogEnabled()) {
        datadogRum.setUser({
            id,
        });
    }
};

export const addDataDogRUMAction = (name: string, context: Record<string, string | boolean>) => {
    if (isDataDogEnabled()) {
        datadogRum.addAction(name, context);
    }
};

export const addDatadogError = (error: unknown, context?: UnknownObject) => {
    if (isDataDogEnabled()) {
        datadogRum.addError(error, context);
    }
};
