import { type ReactNode } from 'react';

type Props = {
    attributes: UnknownObject;
    children: ReactNode;
};

const Li = (props: Props) => <li {...props.attributes}>{props.children}</li>;

export default Li;
