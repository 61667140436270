import { createContext, useContext } from 'react';

import { type Tenant } from '@@api/services/tenant/schemas';

type Context = {
    selectedVariantId: Tenant['id'] | null | undefined;
    variantId: Tenant['id'] | null | undefined;
};

const TenantVariantContext = createContext({
    selectedVariantId: null,
    variantId: null,
} as Context);

export const useTenantVariant = () => useContext(TenantVariantContext);

export default TenantVariantContext;
