import { Stack, styled } from '@mui/material';
import { type ReactEventHandler, type ReactNode, useEffect, useState } from 'react';

import { type UploadFile, type UploadFileResponse } from '@@api/hooks/useUploadFile';
import { type Image } from '@@api/utils/schemas/schemas';
import Loader from '@@components/Loader';
import Spacer from '@@components/Spacer';
import { type ImageMimeType } from '@@constants/ImageMimeTypes';
import { validateImageFile, type ValidationError } from '@@form/utils/validators/image';

import { DEFAULT_IMAGE_SIZES } from '../constants';
import Buttons, { ButtonsProvider, type ButtonsType } from './ButtonsContext';

const ButtonBar = styled(Stack)({
    flexDirection: 'row',
    marginTop: '20px',
    marginBottom: '12px',
});

const FixedSizeLoader = styled(Loader)({
    width: DEFAULT_IMAGE_SIZES.loaderWidth,
    height: DEFAULT_IMAGE_SIZES.loaderHeight,
});

export const selectors = {
    ButtonBar,
    FixedSizeLoader,
};

const StyledStack = styled(Stack)({
    maxWidth: '100%',
});

export type ImageUploadProps = {
    allowedMimeTypes: ImageMimeType[];
    renderImage: (props: RenderImageProps) => ReactNode;
    uploadImage: UploadFile;
    onDeleteImage: () => void;
    onUploadSuccess: (payload: UploadFileResponse) => void;
    className?: string;
    originalImageUrl?: string;
    renderButtons?: (buttons: ButtonsType, props: ImageUploadProps) => ReactNode;
    renderLoader?: () => ReactNode;
    src: string;
    onLoadImage?: ReactEventHandler<HTMLImageElement>;
    onUploadFailure: (errors?: ValidationError[]) => void;
    aspectRatio: string;
    hasButtonGroup?: boolean;
    onChange: (image: Image) => void;
};

export type RenderImageProps = {
    handleOnLoad: ReactEventHandler<HTMLImageElement>;
    isLoading: boolean;
    hasFinishedLoading: boolean;
    value?: Image;
} & Omit<ImageUploadProps, 'src' | 'renderImage' | 'renderLoader'>;

const ImageUpload = (props: ImageUploadProps) => {
    const propsWithDefaults: ImageUploadProps = {
        ...props,
        onLoadImage: props.onLoadImage || (() => {}),
        renderLoader: props.renderLoader || (() => <FixedSizeLoader isLoading />),
        hasButtonGroup: props.hasButtonGroup ?? true,
    };

    const {
        src,
        onUploadFailure,
        uploadImage,
        onUploadSuccess,
        onDeleteImage,
        onLoadImage,
        className,
        renderButtons,
        hasButtonGroup,
        allowedMimeTypes,
    } = propsWithDefaults;

    const [isLoading, setIsLoading] = useState(false);
    const [hasFinishedLoading, setHasFinishedLoading] = useState(true);

    const [prevSrc, setPrevSrc] = useState(src);

    useEffect(() => {
        if (prevSrc !== src) {
            setPrevSrc(src);
            if (src) {
                setHasFinishedLoading(false);
            }
        }
    }, [src, prevSrc]);

    const handleImageUpload = async (e, files) => {
        setIsLoading(true);
        setHasFinishedLoading(false);

        const errors = await validateImageFile(files[0]);

        if (errors.length > 0) {
            onUploadFailure(errors);
            setIsLoading(false);
            setHasFinishedLoading(true);
        } else {
            uploadImage({
                file: files[0],
            })
                .then((payload) => {
                    onUploadSuccess(payload);
                })
                .catch(() => {
                    onUploadFailure();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleImageDelete = () => {
        setIsLoading(false);
        setHasFinishedLoading(true);
        onDeleteImage();
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                observer.disconnect();
            }
        });
    });

    const handleImageOnLoad = (imgEl) => {
        setHasFinishedLoading(true);
        onLoadImage?.(imgEl);
        observer.observe(imgEl);
    };

    const renderImageContainer = () => {
        const { src, renderImage, renderLoader, ...rest } = propsWithDefaults;
        const renderImageProps = {
            ...rest,
            handleOnLoad: handleImageOnLoad,
            isLoading,
            hasFinishedLoading,
        };

        if (src) {
            return (
                <>
                    <div style={{ display: hasFinishedLoading ? 'inherit' : 'none' }}>
                        {renderImage(renderImageProps)}
                    </div>

                    {!hasFinishedLoading ? renderLoader?.() : null}
                </>
            );
        }

        if (isLoading && !hasFinishedLoading) {
            return renderLoader?.();
        }

        return renderImage(renderImageProps);
    };
    const renderButtonPreset = () => (
        <ButtonBar flex="0 1 auto">
            <Buttons.UploadButton />
            <Spacer sm h />
            {src ? <Buttons.DeleteButton /> : null}
        </ButtonBar>
    );

    return (
        // to make renderButtons work correctly we need to use context to share props
        <ButtonsProvider
            handleImageUpload={handleImageUpload}
            allowedMimeTypes={allowedMimeTypes}
            handleImageDelete={handleImageDelete}
        >
            <StyledStack className={className} flex="0 1 auto">
                <Stack direction="row" alignItems="stretch">
                    {renderImageContainer()}
                </Stack>

                {hasButtonGroup &&
                    hasFinishedLoading &&
                    (renderButtons
                        ? renderButtons(Buttons, propsWithDefaults)
                        : renderButtonPreset())}
            </StyledStack>

            <Spacer lg h />
        </ButtonsProvider>
    );
};

export default ImageUpload;
