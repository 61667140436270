import { type FieldPath, type FieldValues } from 'react-hook-form';

import { number } from '@@form/utils/parsers';

import TextInputField, { type TextInputFieldProps } from './TextInput';

const NumberField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: TextInputFieldProps<TFieldValues, TName>,
) => (
    <TextInputField
        type="number"
        {...props}
        transform={{
            input: String,
            // @ts-expect-error CD2-8734 - useTransform errors are tricky to fix but transform might disappear with Zod forms
            output: (event) => number(event.target.value),
            ...props.transform,
        }}
    />
);

export default NumberField;
