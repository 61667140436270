import {
    EN_DASH,
    HYPHEN_MINUS,
    NARROW_NO_BREAK_SPACE,
    NO_BREAK_SPACE,
    SOFT_HYPHEN,
    SPACE,
} from '@@constants/Unicode';
import { PLUGIN_NAMES, type PluginConfig } from '@@editor/typings/UnityPlugins';

import { ELEMENT_TYPES } from './helpers/Element';
import { CURRENT_VERSION } from './serialization/UnitySerializer/constants';

export const SLATE_ATTRIBUTE_SELECTORS = {
    EDITOR: "[data-slate-editor='true']",
    BLOCKS: "[data-slate-node='element']:not([data-slate-inline='true'])",
};

export const DEFAULT_BLOCK = {
    type: ELEMENT_TYPES.PARAGRAPH,
    data: {},
    children: [{ text: '' }],
};

export const DEFAULT_UNITY_BLOCK = {
    type: ELEMENT_TYPES.TEXT,
    version: CURRENT_VERSION,
    items: [{ text: '', version: CURRENT_VERSION, type: ELEMENT_TYPES.TEXTITEM }],
};

export const DEFAULT_EMBED_DATA = {
    align: 'full-width',
    fit: 'contain',
    isLoading: false,
};

export const MARKS = {
    BOLD: 'bold',
    ITALIC: 'italic',
    UNDERLINED: 'underlined',
    CODE: 'code',
    SUBSCRIPT: 'subscript',
    SUPERSCRIPT: 'superscript',
    COMMENT: 'commentId',
};

export const HOTKEYS = {
    BOLD: 'mod+b',
    ITALIC: 'mod+i',
    UNDERLINED: 'mod+u',
    CODE: 'mod+.',
    LIST_UL: 'alt+u',
    LIST_OL: 'alt+o',
    SUBSCRIPT: 'mod+g',
    SUPERSCRIPT: 'mod+h',
    SELECT_ALL: 'mod+a',
    DELETE_BLOCK: 'mod+shift+d',
};

export const DATA_TRANSFER_TYPE_UNITY_ATTACHMENT = 'application/x-unity-attachment';
export const DATA_TRANSFER_TYPE_SLATE_FRAGMENT = 'application/x-slate-fragment';

export const ORIGIN_EVENT = {
    CUT: 'cut',
    COPY: 'copy',
    DRAG: 'drag',
} as const;

type PluginConfigTemplate = {
    specialCharacters: PluginConfig;
    softHyphen: PluginConfig;
    autoReplaceText: PluginConfig;
};

export const PLUGIN_CONFIG_TEMPLATES: PluginConfigTemplate = {
    specialCharacters: {
        name: PLUGIN_NAMES.SPECIAL_CHARACTERS,
        options: {
            plugins: [
                {
                    name: PLUGIN_NAMES.WRAP_TEXT,
                    options: {
                        id: 'single-guillemets',
                        // U+2039 | U+203A
                        text: ['‹', '›'],
                    },
                },
                {
                    name: PLUGIN_NAMES.INSERT_TEXT,
                    options: {
                        id: 'subscript2',
                        // U+2082
                        text: '₂',
                    },
                },
                {
                    name: PLUGIN_NAMES.WRAP_TEXT,
                    options: {
                        id: 'guillemets',
                        // 00AB | 00BB
                        text: ['«', '»'],
                    },
                },
                {
                    name: PLUGIN_NAMES.INSERT_TEXT,
                    options: {
                        id: 'superscript2',
                        // U+00B2
                        text: '²',
                    },
                },
                {
                    name: PLUGIN_NAMES.INSERT_TEXT,
                    options: {
                        id: 'quotation-dash',
                        text: EN_DASH,
                    },
                },
                {
                    name: PLUGIN_NAMES.INSERT_TEXT,
                    options: {
                        id: 'superscript3',
                        // U+00B3
                        text: '³',
                    },
                },
            ],
        },
    },
    softHyphen: {
        name: PLUGIN_NAMES.INSERT_TEXT,
        options: {
            id: 'soft-hyphen',
            text: SOFT_HYPHEN,
            plugins: [PLUGIN_NAMES.SOFT_HYPHEN],
        },
    },
    autoReplaceText: {
        name: PLUGIN_NAMES.AUTO_REPLACE_TEXT,
        options: {
            replacements: [
                // <--> needs to be placed above <-- and --> in order to work correctly
                {
                    needle: '<-->',
                    replacement: '↔',
                    // It's possible to enable a replacement for certain locales only
                    // locales: [LANG_CODES.FRENCH],
                },
                {
                    needle: `<--${SPACE}`,
                    replacement: `←${SPACE}`,
                },
                {
                    needle: '-->',
                    replacement: '→',
                },
                {
                    needle: '-+',
                    replacement: '±',
                },
                {
                    needle: '+-',
                    replacement: '±',
                },
                {
                    needle: '~=',
                    replacement: '≈',
                },
                {
                    needle: '(c)',
                    replacement: '©',
                },
                {
                    needle: '(r)',
                    replacement: '®',
                },
                {
                    needle: '(tm)',
                    replacement: '™',
                },
                {
                    needle: '(o)',
                    replacement: '˚',
                },
                {
                    needle: '...',
                    replacement: '…',
                },
                {
                    needle: '<<',
                    replacement: '«',
                },
                {
                    needle: '>>',
                    replacement: '»',
                },
                {
                    needle: '((',
                    replacement: '‹',
                },
                {
                    needle: '))',
                    replacement: '›',
                },
                {
                    needle: '"',
                    replacement: (editor, { textBefore }) =>
                        textBefore.lastIndexOf('«') > textBefore.lastIndexOf('»') ? '»' : '«',
                },
                {
                    needle: `${SPACE}${HYPHEN_MINUS}${SPACE}`,
                    replacement: `${SPACE}${EN_DASH}${SPACE}`,
                },
                {
                    needle: '---',
                    replacement: SOFT_HYPHEN,
                },
                {
                    needle: ':::',
                    replacement: NO_BREAK_SPACE,
                },
                {
                    needle: `CO2${SPACE}`,
                    replacement: `CO₂${SPACE}`,
                },
                {
                    needle: `H2O${SPACE}`,
                    replacement: `H₂O${SPACE}`,
                },
                {
                    needle: `km2${SPACE}`,
                    replacement: `km²${SPACE}`,
                },
                // m2 needs to be placed below km2 in order to work correctly
                {
                    needle: `m2${SPACE}`,
                    replacement: `m²${SPACE}`,
                },
                {
                    needle: `m3${SPACE}`,
                    replacement: `m³${SPACE}`,
                },
                {
                    needle: `1/2${SPACE}`,
                    replacement: `½${SPACE}`,
                },
                {
                    needle: `1/3${SPACE}`,
                    replacement: `⅓${SPACE}`,
                },
                {
                    needle: `2/3${SPACE}`,
                    replacement: `⅔${SPACE}`,
                },
                {
                    needle: `1/4${SPACE}`,
                    replacement: `¼${SPACE}`,
                },
                {
                    needle: `3/4${SPACE}`,
                    replacement: `¾${SPACE}`,
                },
                {
                    needle: `1/5${SPACE}`,
                    replacement: `⅕${SPACE}`,
                },
                {
                    needle: `2/5${SPACE}`,
                    replacement: `⅖${SPACE}`,
                },
                {
                    needle: `3/5${SPACE}`,
                    replacement: `⅗${SPACE}`,
                },
                {
                    needle: `4/5${SPACE}`,
                    replacement: `⅘${SPACE}`,
                },
                {
                    needle: `1/6${SPACE}`,
                    replacement: `⅙${SPACE}`,
                },
                {
                    needle: `5/6${SPACE}`,
                    replacement: `⅚${SPACE}`,
                },
                {
                    needle: `1/7${SPACE}`,
                    replacement: `⅐${SPACE}`,
                },
                {
                    needle: `1/8${SPACE}`,
                    replacement: `⅛${SPACE}`,
                },
                {
                    needle: `3/8${SPACE}`,
                    replacement: `⅜${SPACE}`,
                },
                {
                    needle: `5/8${SPACE}`,
                    replacement: `⅝${SPACE}`,
                },
                {
                    needle: `7/8${SPACE}`,
                    replacement: `⅞${SPACE}`,
                },
                {
                    needle: `1/9${SPACE}`,
                    replacement: `⅑${SPACE}`,
                },
                {
                    needle: `1/10${SPACE}`,
                    replacement: `⅒${SPACE}`,
                },
                {
                    needle: '1er',
                    replacement: '1ᵉʳ',
                },
                {
                    needle: `'`,
                    replacement: '’',
                },
                {
                    needle: `;;;`,
                    replacement: NARROW_NO_BREAK_SPACE,
                },
            ],
            plugins: ['noBreakSpace'],
        },
    },
};
