import { IconButton, Link, Stack, styled, Tooltip } from '@mui/material';
import moment from 'moment';
import { Fragment, type MouseEvent, type ReactNode } from 'react';

import { type AuthState, getAuth } from '@@auth/authSlice';
import { ROLES } from '@@auth/constants';
import Icon from '@@components/Icon';
import config from '@@config';
import Autocomplete from '@@form/components/Autocomplete';
import Switch from '@@form/components/Switch';
import { FULL_SHORT_DATETIME } from '@@lib/dateTime/formats';
import { resolveApiEnv } from '@@scripts/config/resolveEnv';
import { getSetting, getSmartSetting, setSetting } from '@@settings/settingsSlice';
import { useDispatch, useSelector } from '@@store/hooks';

import { MODE_SMALL } from '../constants';
import { type Microservices } from '../DeveloperToolbar';

const formatDateTime = (dateTime) => moment(dateTime).locale('en').format(FULL_SHORT_DATETIME);

const formatDuration = (start, end) => moment.duration(start.diff(end)).locale('en').humanize();

const StyledAutocomplete = styled(Autocomplete)({
    minWidth: 190,
}) as typeof Autocomplete;

const ScrollContainer = styled('div')({
    overflow: 'auto',
    height: '100%',
});

const Wrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    minWidth: '100px',
    maxWidth: '400px',
    margin: theme.spacing(4),
    padding: 0,
    boxShadow: theme.shadows[1],
    background: theme.palette.common.white,
    borderRadius: `${theme.shape.borderRadius}px`,
    border: `${theme.borders[2]} solid ${theme.palette.warning.main}`,
    zIndex: theme.zIndex.layer3,
    [`${ScrollContainer}`]: {
        padding: theme.spacing(3),
    },
}));

const Buttons = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(2, 3),
    display: 'flex',
    gap: theme.spacing(1),
}));

const SectionTitle = styled('h2')(({ theme }) => ({
    ...theme.typography.title3,
    padding: theme.spacing(3, 0, 2),
    margin: 0,
    '> small': {
        ...theme.typography.small,
    },
}));

const Table = styled('table')(({ theme }) => ({
    borderSpacing: theme.spacing(2),
    borderCollapse: 'separate',
}));

const StyledIcon = styled(Icon, {
    shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => ({
    color: error ? theme.palette.error.main : theme.palette.success.main,
    marginRight: theme.spacing(1),
}));

const List = styled('ul')({
    listStyleType: 'none',
    paddingLeft: 0,
});

const ListItem = styled('li', {
    shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => ({
    ...theme.typography.subtitle3,
    color: error ? theme.palette.error.main : 'inherit',
}));

const ErrorMessage = styled('span')(({ theme }) => ({
    marginLeft: theme.spacing(4),
}));

type BuildInfo = {
    caption: string;
    tooltip?: string;
    value?: ReactNode;
};

type Props = {
    healthyMicroserviceCount: number;
    unhealthyMicroserviceCount: number;
    microservices: Microservices;
    onClickCloseButton: (e: MouseEvent<HTMLButtonElement>) => void;
    api: string;
    onChangeApi: (event, api: string | null) => void;
};

export const BigToolbar = ({
    healthyMicroserviceCount,
    unhealthyMicroserviceCount,
    microservices,
    onClickCloseButton,
    api,
    onChangeApi,
}: Props) => {
    const dispatch = useDispatch();
    const auth = useSelector(getAuth);
    const noFormRestoration = useSelector((state) =>
        getSmartSetting(state, { key: 'noFormRestoration' }),
    );

    const smartBetaFeaturesEnabled = useSelector((state) =>
        getSmartSetting(state, { key: 'betaFeaturesEnabled' }),
    );
    const smartReducedUI = useSelector((state) => getSmartSetting(state, { key: 'reducedUI' }));
    const smartUserRole = useSelector((state) => getSmartSetting(state, { key: 'userRole' }));
    const userRole = useSelector((state) => getSetting(state, { key: 'userRole' }));

    const handleClickOnMinimizeButton = () =>
        dispatch(setSetting({ path: 'devToolbar', value: MODE_SMALL }));

    const handleChangeOfReducedUI = (e) =>
        dispatch(setSetting({ path: 'reducedUI', value: e.target.checked }));
    const handleChangeOfUserRole = (event, value) =>
        dispatch(setSetting({ path: 'userRole', value }));
    const handleChangeOfIsFormRestoreDisabled = (e) =>
        dispatch(setSetting({ path: 'noFormRestoration', value: e.target.checked }));

    const renderAuthValue = (key: string, value: ValueOf<AuthState>, allValues?: AuthState) => {
        switch (key) {
            case 'loggedInTime':
                return formatDateTime(value);

            case 'accessToken':
                return <span>***</span>;

            case 'expiresIn': {
                const now = moment();
                const expiration = moment(allValues?.loggedInTime).add(value, 'milliseconds');

                return formatDuration(now, expiration);
            }

            default:
                return value;
        }
    };

    const buildInfos: BuildInfo[] = [
        {
            caption: 'MODE',
            tooltip: 'The type of build (visualTest, test, development, production)',
            value: import.meta.env.MODE,
        },
        {
            caption: 'CDII_ENV',
            tooltip: 'The current environment (see @@config/envs.ts)',
            value: config.env,
        },
        {
            caption: 'API_ENV',
            tooltip: 'The current API used',
            value: config.apiEnv,
        },
        {
            caption: 'Last built',
            value: formatDateTime(BUILD_TIMESTAMP),
        },
        {
            caption: 'Commit',
            value: (
                <Link href={`${REPOSITORY_URL}/commits/${VERSION}`} target="_blank">
                    {VERSION}
                </Link>
            ),
        },
    ];

    const apiAutocompleteOptions = Object.keys(config.apiEnvs).filter((apiEnv) => {
        const excluded = ['production', 'preprod', 'demo'];

        if (!config.isMockServer) {
            excluded.push('localhost:apiMock');
        }

        if (import.meta.env.MODE !== 'test') {
            excluded.push('test');
        }

        return !excluded.includes(apiEnv);
    });

    return (
        <Wrapper>
            <Buttons>
                <IconButton size="small" title="Minimize" onClick={handleClickOnMinimizeButton}>
                    <Icon name="window-minimize" />
                </IconButton>

                <IconButton size="small" title="Close" onClick={onClickCloseButton}>
                    <Icon name="xmark" />
                </IconButton>
            </Buttons>

            <ScrollContainer>
                <SectionTitle>Build information</SectionTitle>
                <Table>
                    <tbody>
                        {buildInfos.map(({ tooltip, caption, value }: BuildInfo) => (
                            <tr key={caption}>
                                <td>
                                    {tooltip ? (
                                        <Tooltip title={tooltip}>
                                            <span tabIndex={0}>{caption}</span>
                                        </Tooltip>
                                    ) : (
                                        caption
                                    )}
                                </td>
                                <td>{value}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <SectionTitle>Configuration</SectionTitle>
                <Table>
                    <tbody>
                        <tr>
                            <td id="api">API</td>
                            <td>
                                <StyledAutocomplete
                                    placeholder={resolveApiEnv()}
                                    value={api}
                                    label="API"
                                    aria-labelledby="api"
                                    fullWidth
                                    onChange={onChangeApi}
                                    options={apiAutocompleteOptions}
                                    getOptionLabel={(option) => option}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td id="reducedUI">reducedUI</td>
                            <td>
                                <Switch
                                    inputProps={{ 'aria-labelledby': 'reducedUI' }}
                                    checked={smartReducedUI}
                                    onChange={handleChangeOfReducedUI}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td id="betaFeaturesEnabled">betaFeaturesEnabled</td>
                            <td>
                                <Switch
                                    inputProps={{ 'aria-labelledby': 'betaFeaturesEnabled' }}
                                    checked={smartBetaFeaturesEnabled}
                                    disabled
                                />
                            </td>
                        </tr>

                        <tr>
                            <td id="userRole">userRole</td>
                            <td>
                                <StyledAutocomplete
                                    placeholder={smartUserRole}
                                    value={userRole}
                                    label="userRole"
                                    aria-labelledby="userRole"
                                    fullWidth
                                    onChange={handleChangeOfUserRole}
                                    options={Object.values(ROLES)}
                                    getOptionLabel={(option) => option}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td id="noFormRestoration">noFormRestoration</td>
                            <td>
                                <Switch
                                    inputProps={{ 'aria-labelledby': 'noFormRestoration' }}
                                    checked={noFormRestoration}
                                    onChange={handleChangeOfIsFormRestoreDisabled}
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <SectionTitle>Authentication</SectionTitle>
                <Table>
                    <tbody>
                        {Object.entries<string | number | null>(auth).map(([key, value], index) => (
                            <tr key={index}>
                                <td title={key}>{key}</td>
                                <td>{renderAuthValue(key, value, auth)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <SectionTitle>
                    Microservices{' '}
                    <small>
                        ({healthyMicroserviceCount} up, {unhealthyMicroserviceCount} down)
                    </small>
                </SectionTitle>
                <List>
                    {Object.entries(microservices).map(([key, microservice], index) =>
                        microservice.health ? (
                            <Fragment key={index}>
                                <ListItem error={Boolean(microservice.health.error)} title={key}>
                                    <Stack direction="row" alignItems="center">
                                        <StyledIcon
                                            error={Boolean(microservice.health.error)}
                                            name={
                                                microservice.health.error ? 'xmark' : 'check-sharp'
                                            }
                                        />
                                        {microservice.baseUrl || key}
                                    </Stack>
                                </ListItem>

                                {microservice.health.error && (
                                    <li>
                                        <ErrorMessage>{microservice.health.error}</ErrorMessage>
                                    </li>
                                )}
                            </Fragment>
                        ) : null,
                    )}
                </List>
            </ScrollContainer>
        </Wrapper>
    );
};

export default BigToolbar;
