import { type RTElement } from '@@api/services/rtelements/schemas';
import { type Editor } from '@@editor/helpers';
import Infobox from '@@editor/plugins/serializable/layout/infobox/Infobox';

import EmbeddedContentFrame from '../../embed/embeddedcontent/EmbeddedContentFrame';
import Poll from '../../poll/components/Poll';
import Snippet from '../../snippet/Snippet';
import { COMPONENT_TYPE } from '../constants';

const getComponent = (type, t) => {
    const elementComponent = {
        [COMPONENT_TYPE.INFOBOX]: Infobox,
        [COMPONENT_TYPE.POLL]: Poll,
        [COMPONENT_TYPE.IFRAME]: (props) => <EmbeddedContentFrame {...props} t={t} />,
        [COMPONENT_TYPE.SNIPPET]: Snippet,
    };

    return elementComponent[type];
};

type Props = {
    editor: Editor;
    record: RTElement;
};

const EmbeddedComponentPreview = (props: Props) => {
    const { editor, record } = props;

    const Component = getComponent(record.type, editor.t);

    return <Component editor={editor} element={record.element[0]} />;
};

export default EmbeddedComponentPreview;
