import { type ReactNode } from 'react';

import ModalLeavePromptContext from './ModalLeavePromptContext';
import useLeavePromptValue from '../useLeavePromptValue';

type Props = { children: ReactNode };

const ModalLeavePromptProvider = ({ children }: Props) => {
    const contextValue = useLeavePromptValue();

    return (
        <ModalLeavePromptContext.Provider value={contextValue}>
            {children}
        </ModalLeavePromptContext.Provider>
    );
};

export default ModalLeavePromptProvider;
