import {
    Checkbox as MuiCheckbox,
    type CheckboxProps as MuiCheckboxProps,
    FormControlLabel,
    type FormControlLabelProps,
} from '@mui/material';

export type CheckboxProps = MuiCheckboxProps & {
    label?: FormControlLabelProps['label'];
    labelPlacement?: FormControlLabelProps['labelPlacement'];
};

const Checkbox = (props: CheckboxProps) => {
    const { label, labelPlacement, ...rest } = props;

    if (label) {
        return (
            <FormControlLabel
                control={<MuiCheckbox {...rest} />}
                label={label}
                labelPlacement={labelPlacement}
                slotProps={{
                    typography: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            />
        );
    }

    return <MuiCheckbox {...rest} />;
};

export default Checkbox;
