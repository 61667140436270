import { type RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: '*',
        children: [
            {
                path: '*',
                lazy: async () => {
                    const { Sitemap } = await import('@@routes/sitemap');

                    return { Component: Sitemap };
                },
            },
        ],
    },
];
