import { noop } from 'lodash-es';

import { type Attachment } from '@@api/services/attachments/schemas';
import type { ImageMimeType } from '@@constants/ImageMimeTypes';
import { type ImageElement } from '@@editor/helpers/Element';
import Base64 from '@@editor/serialization/Base64';
import makeState from '@@editor/utils/makeState';
import {
    type AssetPayload,
    isAssetsPayload,
    isSupportedAsset,
    parseAssetPayload,
} from '@@utils/assets';

import { isSupportedFile, isSupportedUrl } from '../fileUpload';

export const INVALID_FILE_TYPE_ERROR = 'Invalid File Type Error';
export const INVALID_IMAGE_URL_ERROR = 'Invalid Image Url Error';

type ImageDataTransfer = {
    files?: FileList | File[];
    html?: string;
    text?: string;
    urls?: string[];
    attachment?: string;
};

type Images = Array<File | string | AssetPayload | ImageElement['data']>;

type Callback = (error: Error | null, asset?: File | string | AssetPayload) => void;

const getImagesFromAttachment = (attachment: ImageDataTransfer['attachment']): Images => {
    const { sourceUrl, sourceId, fileMetadata } = Base64.deserializeNode(attachment) as Attachment;

    const { height, width, metadata, mediaType } = fileMetadata;

    const { caption, credit, name } = metadata;

    const imageData: ImageElement['data'] = {
        // @ts-expect-error TODO: CD2-9370 This error will get fixed with the change
        // in metadata schema for FilerepoFile
        mimetype: mediaType,
        src: sourceUrl,
        embed: {
            url: sourceUrl,
            elvisId: sourceId,
            caption: makeState(caption),
            credit,
            name,
            naturalHeight: height,
            naturalWidth: width,
        },
    };

    return [imageData];
};

const getImagesFromHtml = (html: string, callback: Callback): Images => {
    const images: Images = [];
    const divElement = document.createElement('div');

    divElement.innerHTML = html;

    const imageElements = divElement.querySelectorAll('img');

    imageElements.forEach((image) => {
        const url = image.src;

        images.push(url);

        callback(null, url);
    });

    return images;
};

const getImagesFromAssets = (
    text: string,
    callback: Callback,
    allowedMimeTypes: ImageMimeType[],
): Images => {
    const assets = parseAssetPayload(text);
    const images: Images = [];

    if (assets) {
        assets.forEach((asset) => {
            if (isSupportedAsset(asset, allowedMimeTypes)) {
                const assetPayload: AssetPayload = {
                    id: asset.id,
                    // we keep elvisId in the asset payload for better alignment
                    // as there are places where we rely on this data
                    // like for example attachments which use elvisId on the elements
                    embed: {
                        elvisId: asset.id,
                    },
                    metadata: {
                        filename: asset.metadata.filename,
                        folderPath: asset.metadata.folderPath,
                    },
                    previewUrl: asset.previewUrl,
                };

                images.push(assetPayload);

                callback(null, assetPayload);
            } else {
                const error = new Error(`File type "${asset.assetType}" is not supported`);

                error.name = INVALID_FILE_TYPE_ERROR;

                callback(error);
            }
        });
    }

    return images;
};

const getImagesFromNonAssetsText = (
    text: string,
    callback: Callback,
    allowedMimeTypes: ImageMimeType[],
): Images => {
    const images: Images = [];

    text.split('\r\n').forEach((url) => {
        if (isSupportedUrl(url, allowedMimeTypes)) {
            images.push(url);

            callback(null, url);
        } else {
            const error = new Error(`"${url}" is not supported`);

            error.name = INVALID_IMAGE_URL_ERROR;

            callback(error);
        }
    });

    return images;
};

const getImagesFromText = (
    text: string,
    callback: Callback,
    allowedMimeTypes: ImageMimeType[],
): Images =>
    isAssetsPayload(text)
        ? getImagesFromAssets(text, callback, allowedMimeTypes)
        : getImagesFromNonAssetsText(text, callback, allowedMimeTypes);

const getImagesFromUrls = (urls: string[], callback: Callback): Images => {
    const images: Images = [];

    urls.forEach((url) => {
        images.push(url);

        callback(null, url);
    });

    return images;
};

const getImagesFromFiles = (
    files: FileList | File[],
    callback: Callback,
    allowedMimeTypes: ImageMimeType[],
): Images => {
    const images: Images = [];

    Array.from(files).forEach((file) => {
        if (isSupportedFile(file, allowedMimeTypes)) {
            images.push(file);

            callback(null, file);
        } else {
            const error = new Error(`File type "${file.type}" is not supported`);

            error.name = INVALID_FILE_TYPE_ERROR;

            callback(error);
        }
    });

    return images;
};

export const getImagesFromDataTransfer = (
    imageDataTransfer: ImageDataTransfer,
    allowedMimeTypes: ImageMimeType[],
    callback: (error: Error | null, asset?: File | string | AssetPayload) => void = noop,
): Images => {
    if (allowedMimeTypes.length === 0) {
        return [];
    }
    if (!allowedMimeTypes) {
        throw new Error('Allowed mime types must be provided');
    }

    const { attachment, html, text, urls, files } = imageDataTransfer;

    if (attachment) {
        return getImagesFromAttachment(attachment);
    }

    if (html) {
        return getImagesFromHtml(html, callback);
    }

    if (text) {
        return getImagesFromText(text, callback, allowedMimeTypes);
    }

    if (urls) {
        return getImagesFromUrls(urls, callback);
    }

    if (files) {
        return getImagesFromFiles(files, callback, allowedMimeTypes);
    }

    return [];
};

export default getImagesFromDataTransfer;
