import { styled } from '@mui/material';
import { Path } from 'slate';

import { Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import FloatingToolbarWrapper from '@@editor/toolbars/floatingToolbar/FloatingToolbarWrapper';
import { getToolbarSettings } from '@@editor/utils/getToolbarSettings';

import ErrorWrapper from '../serializable/components/ErrorWrapper';

const FloatingToolbarSpacer = styled('div')(({ theme }) => ({
    height: theme.fixed.editor.floatingToolbar.height,
}));

const withErrorWrapper = (displayError) => (WrappedComponent, element) =>
    displayError ? (
        <ErrorWrapper element={element}>{WrappedComponent}</ErrorWrapper>
    ) : (
        WrappedComponent
    );

const withFloatingToolbar = (WrappedComponent, editor: Editor, element: Element) => {
    const isReadOnly = ReactEditor.isReadOnly(editor);
    const toolbarSettings = getToolbarSettings(editor, element);
    const isToolbarVisible =
        !isReadOnly && !editor.hideFloatingToolbar && toolbarSettings?.floatingToolbar.enabled;

    const elementTypesWithoutSpacer = [
        Element.isLinkElement,
        Element.isEmbedCaptionElement,
        Element.isEmbedCreditElement,
        Element.isInterviewSegmentQuestionElement,
        Element.isInterviewSegmentAnswerElement,
        Element.isPollQuestionElement,
        Element.isPollAnswerElement,
        Element.isQuoteCaptionElement,
        Element.isQuoteTextElement,
        Element.isListItemElement,
        Element.isSummaryListSummaryElement,
    ];

    // We don't want to show the spacer when element is inside other elements, like infobox
    const childElementTypesWithoutSpacer = [Element.isListElement];

    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);

    const hideSpacer =
        elementTypesWithoutSpacer.some((typeChecker) => typeChecker(element)) ||
        (parentNode !== editor &&
            childElementTypesWithoutSpacer.some((typeChecker) => typeChecker(element)));

    if (isToolbarVisible) {
        const path = ReactEditor.findPath(editor, element);
        const [, parentPath] = Editor.parent(editor, path);
        const [, lastNodePath] = Editor.last(editor, parentPath);
        const isLastElement = Path.compare(path, lastNodePath) === 0;
        const activePlugins =
            toolbarSettings.floatingToolbar.allowedPlugins ?? editor.defaultFloatingToolbarPlugins;

        return (
            <FloatingToolbarWrapper
                isLastElement={isLastElement}
                path={path}
                activePlugins={activePlugins}
            >
                {WrappedComponent}
            </FloatingToolbarWrapper>
        );
    } else if (editor.preserveFloatingToolbarHeight) {
        return (
            <>
                {!hideSpacer && <FloatingToolbarSpacer />}
                {WrappedComponent}
            </>
        );
    }

    return WrappedComponent;
};

const renderElement = (editor: Editor, elementsConfig, outerOptions?) => {
    const { renderElement } = editor;

    return (props) => {
        const elementConfig = elementsConfig.find(([type]) => type === props.element.type);

        if (elementConfig) {
            const displayError = props.element?.data?.displayError;
            const [, Fn, innerOptions = {}] = elementConfig;

            if (
                (editor.isArticleEditor || editor.isArticleHeadingsEditor) &&
                ReactEditor.isReadOnly(editor)
            ) {
                if (
                    Element.isTemplateElement(props.element) ||
                    Element.isEmptyElement(props.element)
                ) {
                    return null;
                }
            }

            // In some cases Fn is already a component
            const component =
                typeof Fn === 'function' ? (
                    Fn({
                        editor,
                        ...props,
                        ...outerOptions,
                        ...innerOptions,
                        useInlineEditing: editor.useInlineEditing,
                    })
                ) : (
                    <Fn
                        editor={editor}
                        {...props}
                        {...outerOptions}
                        {...innerOptions}
                        useInlineEditing={editor.useInlineEditing}
                    />
                );

            const componentWithErrorWrapper = withErrorWrapper(displayError)(
                component,
                props.element,
            );

            return withFloatingToolbar(componentWithErrorWrapper, editor, props.element);
        }

        return renderElement(props);
    };
};

export default renderElement;
