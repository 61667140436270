import isPropValid from '@emotion/is-prop-valid';
import { styled } from '@mui/material';
import { type CSSProperties, forwardRef, type PropsWithChildren } from 'react';

import Loader from '@@components/Loader';
import { useLoadingStatusManager } from '@@containers/LoadingStatusManager/LoadingStatusManagerContext';
import { generateKeyForRichTextEditorLoadingStatus } from '@@containers/LoadingStatusManager/utils';
import { type Editor, ReactEditor } from '@@editor/helpers';
import { type ElementAttributes, type ParagraphElement } from '@@editor/helpers/Element';
import {
    type BadgeProps,
    getTextReadOnlyBadgeStyle,
    READONLY_BADGE_ATTRIBUTE,
} from '@@editor/plugins/serializable/styles';

const StyledLoader = styled(Loader)({
    display: 'flex',
});

type PProps = BadgeProps & {
    $isArticleEditor?: boolean;
    showBadge?: boolean;
};

export const P = styled('p', { shouldForwardProp: (prop: string) => isPropValid(prop) })<PProps>(
    ({ $isArticleEditor, readOnly, showBadge, theme, ...props }) => ({
        position: 'relative',
        margin: 0,
        ...(!$isArticleEditor &&
            readOnly &&
            showBadge &&
            getTextReadOnlyBadgeStyle({ theme, ...props })),
        ...($isArticleEditor && theme.typography.editorPrimaryLarge),
    }),
);

type Props = PropsWithChildren<{
    attributes?: ElementAttributes;
    editor: Editor;
    element: ParagraphElement;
    showBadge: boolean;
    style?: CSSProperties;
}>;

const Paragraph = forwardRef<HTMLElement, Props>((props, ref) => {
    const { editor, attributes, children, showBadge, style, element } = props;

    const readOnly = ReactEditor.isReadOnly(editor);
    const translatedStyle = editor.t('embed.paragraph.crosshead.default');
    const styleProps = { [READONLY_BADGE_ATTRIBUTE]: translatedStyle };

    const key = generateKeyForRichTextEditorLoadingStatus({
        editorId: editor.id,
        loadingStatusId: element.data?.loadingStatusId,
    })!;

    // If `isLoading` is undefined, the `Loader` component would use `true` as a default value,
    // which is not what we want. Therefore, we use `false` explicitly, if no loading status is available.
    const { isLoading = false } = useLoadingStatusManager({ key });

    return (
        <P
            {...{ ref, style, ...attributes, ...styleProps, readOnly, showBadge }}
            data-type={translatedStyle}
            $isArticleEditor={editor.isArticleEditor}
        >
            <StyledLoader isLoading={isLoading} keepChildrenInDom>
                {children}
            </StyledLoader>
        </P>
    );
});

export default Paragraph;
