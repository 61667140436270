import { noop } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import Icon from '@@components/Icon';
import RelativeBadge from '@@components/RelativeBadge';
import Spacer from '@@components/Spacer';
import Toggle from '@@components/toggle/Toggle';
import ToggleState from '@@components/toggle/ToggleState';

import OpaqueButton from './OpaqueButton';

export type Props = {
    className?: string;
    count?: number;
    expanded?: boolean;
    moreText?: string;
    lessText?: string;
    onChange: (index: number) => void;
};

const ToggleButton = (props: Props) => {
    const { t } = useTranslation();
    const {
        className,
        count,
        expanded,
        moreText = t('search.filterButton.more'),
        lessText = t('search.filterButton.less'),
        onChange = noop,
    } = props;

    return (
        <Toggle className={className} activeIndex={expanded ? 1 : 0} onChange={onChange}>
            <ToggleState key={0}>
                <OpaqueButton startIcon={<Icon name="plus-sharp" size="small" />} size="small">
                    {moreText}

                    {count != null && count > 0 && (
                        <>
                            <Spacer h sm />

                            <RelativeBadge
                                badgeContent={String(count)}
                                color="primary"
                                max={99999}
                            />
                        </>
                    )}
                </OpaqueButton>
            </ToggleState>

            <ToggleState key={1}>
                <OpaqueButton startIcon={<Icon name="minus-sharp" size="small" />} size="small">
                    {lessText}
                </OpaqueButton>
            </ToggleState>
        </Toggle>
    );
};

export default ToggleButton;
