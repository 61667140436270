import {
    cardClasses,
    type CSSObject,
    listItemButtonClasses,
    listItemClasses,
    styled,
    type StyledComponentProps,
    type Theme,
} from '@mui/material';
import { Box, type BoxProps } from '@mui/system';
import { type ComponentType } from 'react';

export const Anchor = {
    Card: '.' + cardClasses.root,
    ListItem: `*:is(.${listItemClasses.root}, .${listItemButtonClasses.root})`,
} as const;

export type Anchor = typeof Anchor;

type BaseProps = {
    anchor?: Anchor | StyledComponentProps<'web'> | string | null;
    position?: 'static' | 'top-right' | 'bottom-right' | 'bottom-left';
    spacing?: number;
    spacingX?: number;
    spacingY?: number;
};

export type Props = Override<BoxProps, BaseProps>;

const DEFAULT_SPACING = 2;

const positionCss = ({
    position,
    spacing = DEFAULT_SPACING,
    spacingX,
    spacingY,
    theme,
}: Pick<RequiredBy<BaseProps, 'position'>, 'position' | 'spacing' | 'spacingX' | 'spacingY'> & {
    theme: Theme;
}): CSSObject => ({
    [`${position.split('-')[1]}`]: theme.spacing(spacingX ?? spacing),
    [`${position.split('-')[0]}`]: theme.spacing(spacingY ?? spacing),
});

const anchorCss = ({ anchor }: Pick<RequiredBy<BaseProps, 'anchor'>, 'anchor'>): CSSObject => ({
    [`${anchor}:not(:hover):has(&) &`]: {
        opacity: 0,
    },
});

export const StyledBox = styled(Box, {
    shouldForwardProp: (prop: string) =>
        !['anchor', 'position', 'spacing', 'spacingX', 'spacingY'].includes(prop),
})<Props>(({ position = 'static', anchor, theme, spacing, spacingX, spacingY }) => ({
    ...(position !== 'static' && positionCss({ position, spacing, spacingX, spacingY, theme })),
    position: position === 'static' ? 'static' : 'absolute',
    zIndex: theme.zIndex.layer1,
    ...(anchor && anchorCss({ anchor })),
})) as ComponentType<Props>;

const FloatingBox = (props: Props) => {
    const propsWithDefaults = {
        ...props,
        anchor: props.anchor === undefined ? Anchor.Card : props.anchor,
        position: props.position === undefined ? 'top-right' : props.position,
        spacing: props.spacing === undefined ? DEFAULT_SPACING : props.spacing,
    };

    return <StyledBox {...propsWithDefaults} />;
};

export default FloatingBox;
