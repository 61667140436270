import { Stack, styled } from '@mui/material';
import { type TFunction } from 'i18next';
import { useCallback } from 'react';

import useTenants from '@@api/hooks/resources/useTenants';
import { type RTElementRouter, useRtElementsClient } from '@@api/services/rtelements/client';
import { getQueryParams } from '@@api/utils/getQueryParams';
import Spacer from '@@components/Spacer';
import ReactHookForm from '@@containers/ReactHookForm/ReactHookForm';
import { type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import useReactHookFormContext from '@@containers/ReactHookForm/useReactHookFormContext';
import UnitySearch from '@@containers/Search/UnitySearch';
import useUnitySearch from '@@containers/Search/useUnitySearch';
import TenantImages from '@@containers/TenantSpecific/TenantImages';
import { type Editor } from '@@editor/helpers';
import { PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import ButtonGroup from '@@form/components/ButtonGroup';
import SelectionTableField from '@@form/fields/SelectionTable';
import SwitchField from '@@form/fields/SwitchField';

import EmbeddedComponentPreview from './EmbeddedComponentPreview';

const StyledTenantImages = styled(TenantImages)(({ theme }) => ({
    padding: theme.spacing(2, 3),
    flexFlow: 'wrap',
}));

const FullWidthContent = styled('div')(({ theme }) => ({
    margin: `0 -${theme.spacing(4)}`,
    marginTop: theme.spacing(4),
}));

const TableWrapper = styled('div')({
    minHeight: '360px',
});

const Description = styled('div')(({ theme }) => ({
    ...theme.typography.medium,
    color: theme.palette.secondary.main,
}));

type Props = {
    editor: Editor;
    className: string;
    onCancel: VoidFunction;
    onSubmit: UseReactHookFormProps['onSubmit'];
    t: TFunction;
};

const SwitchCopyEdit = ({ t }) => {
    const { watch } = useReactHookFormContext();
    const copyedit = watch('copyedit');

    return (
        <SwitchField
            label={t(`embeddedComponent.toggleCopyedit`)}
            tooltip={
                copyedit
                    ? t(`embeddedComponent.copyedit.link`)
                    : t(`embeddedComponent.copyedit.unlink`)
            }
            name="copyedit"
        />
    );
};

const initialValues = {
    embed: {
        id: null,
    },
    copyedit: false,
};

const EmbeddedComponentForm = ({ editor, onCancel, t, className }: Props) => {
    const { client: rtElementsClient, queryKeys: rtElementsKeys } = useRtElementsClient();

    const slateModalState = editor.isEmbedModalVisible(PLUGIN_NAMES.EMBEDDED_COMPONENT);

    const { at, element } = slateModalState || {};

    const isEditMode = Boolean(element);

    const search = useUnitySearch({
        disableUrlSync: true,
        fields: {
            q: true,
            tenantIds: true,
        },
    });
    const { isPending: tenantIsLoading, data: tenants = [] } = useTenants();

    const query = getQueryParams<RTElementRouter['rtElements']['getAll']>({
        q: search.debouncedValues.q,
        tenantIds: search.debouncedValues.tenantIds?.map(Number),
    });
    const { data } = rtElementsClient.rtElements.getAll.useQuery({
        queryKey: rtElementsKeys.rtElements.getAll({ query }),
        queryData: {
            query,
        },
    });

    const rtElements = data?.body || [];

    const columns: TableColumn[] = [
        {
            title: t('rtElement.name'),
            fieldName: 'name',
            textAlign: 'left',
            verticalAlign: 'top',
        },
        {
            title: t('rtElement.tenants'),
            fieldName: 'tenantIds',
            textAlign: 'left',
            verticalAlign: 'top',
            render: ({ value }) => (
                <span>
                    {value && !tenantIsLoading ? (
                        <StyledTenantImages tenantIds={value} tenants={tenants} />
                    ) : null}
                </span>
            ),
        },
    ];

    const handleClickOnCancel = () => onCancel();
    const handleSubmit = useCallback(
        (data) => {
            const idToCheck = isEditMode ? element.data.embed.id : data.embed.id;
            const currentRecord = rtElements?.find((record) => record?.id === idToCheck);
            const isCopyEdit = data.copyedit;

            if (currentRecord) {
                if (isEditMode || isCopyEdit) {
                    editor.insertLayoutComponent(
                        {
                            ...currentRecord,
                        },
                        currentRecord.type,
                        {
                            replace: isEditMode,
                            at,
                            Path: at,
                        },
                    );
                } else {
                    editor.insertEmbedComponent(
                        {
                            embed: {
                                id: currentRecord?.id,
                            },
                        },
                        currentRecord.type,
                        {
                            replace: false,
                            at,
                            Path: at,
                        },
                    );
                }
            }

            onCancel();
        },
        [element, isEditMode, rtElements, at, editor, onCancel],
    );

    const renderButtons = (Buttons) => (
        <ButtonGroup>
            <Buttons.CancelButton />

            {isEditMode ? (
                <Buttons.SubmitButton disabled={false}>
                    {t(`rtElement.action`)}
                </Buttons.SubmitButton>
            ) : (
                <Buttons.SubmitButton>{t('relatedcontent.submitButton')}</Buttons.SubmitButton>
            )}
        </ButtonGroup>
    );

    return (
        <Stack className={className}>
            {!isEditMode ? (
                <UnitySearch {...search.unitySearchProps} />
            ) : (
                <Description>{t(`embeddedComponent.description`)}</Description>
            )}

            <ReactHookForm
                autoFocus={false}
                values={initialValues}
                formName="EmbeddedComponentForm"
                onSubmit={handleSubmit}
                onCancel={handleClickOnCancel}
                renderButtons={renderButtons}
                hasCancelButton
                hasActiveCancelButton
                alwaysShowCancelButton
            >
                {({ watch }) => {
                    const embed = watch('embed');
                    const currentRecord = rtElements?.find((record) => record?.id === embed?.id);

                    return (
                        <>
                            {!isEditMode && (
                                <>
                                    <FullWidthContent>
                                        <TableWrapper>
                                            <SelectionTableField
                                                name="embed.id"
                                                columns={columns}
                                                tableData={rtElements}
                                            />
                                        </TableWrapper>
                                    </FullWidthContent>

                                    <Stack>
                                        {currentRecord && (
                                            <>
                                                <Spacer v md />
                                                <EmbeddedComponentPreview
                                                    record={currentRecord}
                                                    editor={editor}
                                                />
                                            </>
                                        )}

                                        <Spacer v md />
                                        <SwitchCopyEdit t={editor.t} />
                                    </Stack>
                                </>
                            )}
                        </>
                    );
                }}
            </ReactHookForm>
        </Stack>
    );
};

const ComposedEmbeddedComponentForm = styled(EmbeddedComponentForm)(({ theme }) => ({
    width: theme.fixed.form.medium.width,
}));

export default ComposedEmbeddedComponentForm;
