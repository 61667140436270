import { type FieldPath, type FieldValues } from 'react-hook-form';

import RadioGroup, { type RadioGroupProps } from '@@form/components/RadioGroup';
import useFormField, { type UseFormFieldProps } from '@@form/hooks/useFormField';

export type RadioGroupFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = RadioGroupProps & UseFormFieldProps<TFieldValues, TName>;

const RadioGroupField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: RadioGroupFieldProps<TFieldValues, TName>,
) => {
    const {
        name,
        control,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const {
        field: { ref, ...formFieldProps },
    } = useFormField<TFieldValues, TName>({
        name,
        control,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    return <RadioGroup inputRef={ref} {...formFieldProps} required={required} {...rest} />;
};

export default RadioGroupField;
