import { type FieldPath, type FieldValues } from 'react-hook-form';

import Switch, { type Props as SwitchProps } from '@@form/components/Switch';
import useFormField, { type UseFormFieldProps } from '@@form/hooks/useFormField';

export type SwitchFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = SwitchProps & UseFormFieldProps<TFieldValues, TName>;

const SwitchField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: SwitchFieldProps<TFieldValues, TName>,
) => {
    const {
        name,
        control,
        validate,
        novalidate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
        ...rest
    } = props;

    const {
        field: { ref, ...formFieldProps },
    } = useFormField<TFieldValues, TName>({
        name,
        control,
        novalidate,
        validate,
        required,
        requiredCustom,
        validateCacheKey,
        onChange,
        onBlur,
        transform,
        defaultValue,
    });

    const checked = Boolean(formFieldProps.value);

    return (
        <Switch
            inputRef={ref}
            {...formFieldProps}
            required={required}
            {...rest}
            checked={checked}
        />
    );
};

SwitchField.displayName = 'SwitchField';

export default SwitchField;
