import { noop } from 'lodash-es';
import { createContext } from 'react';

export type Actions = {
    showCommentPopover: (commentId: string | null, anchorEl: HTMLElement | null) => void;
};

const CommentPopoverActionsContext = createContext<Actions>({
    showCommentPopover: noop,
});

export default CommentPopoverActionsContext;
