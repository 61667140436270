import { type InsertElementOptions } from '@@editor/helpers/Editor';
import { ELEMENT_TYPES, type FacebookElement, MIME_TYPES } from '@@editor/helpers/Element';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import facebookUrlOrEmbedCode from '@@form/utils/validators/facebookUrlOrEmbedCode';
import { parseFacebookUrlOrEmbedCode } from '@@utils/socialMedia/facebook';

import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import EmbedWrapper from './../components/EmbedWrapper';
import { createGenerateEmbedBlock } from './../utils';
import { generateEmbedBlockData } from './utils';
import SocialEmbed from '../components/SocialEmbed';

const TYPE = PLUGIN_NAMES.FACEBOOK;
const NODE_TYPE = ELEMENT_TYPES.FACEBOOK;
const ICON_NAME = PLUGIN_ICON_NAMES[TYPE];
const TITLE = 'Facebook';

type FacebookSocialEmbedProps = { element: FacebookElement };

const FacebookSocialEmbed = ({ element }: FacebookSocialEmbedProps) => (
    <SocialEmbed element={element} iconName={ICON_NAME} socialNetworkName={TITLE} />
);

const mimetypeConfigs = {
    [MIME_TYPES.FACEBOOK_POST]: {
        Component: FacebookSocialEmbed,
    },
    [MIME_TYPES.FACEBOOK_COMMENT]: {
        Component: FacebookSocialEmbed,
    },
    [MIME_TYPES.FACEBOOK_PAGE]: {
        Component: FacebookSocialEmbed,
    },
    [MIME_TYPES.FACEBOOK_VIDEO]: {
        Component: FacebookSocialEmbed,
    },
};

const FacebookNode = (props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        mimetypeConfigs={mimetypeConfigs}
        toolbarConfig={{
            infos: {
                iconName: ICON_NAME,
                title: TITLE,
            },
            actions: ['edit', 'delete'],
        }}
        widthType="small"
    />
);

const generateEmbedBlock = createGenerateEmbedBlock({
    contentType: 'html',
    type: TYPE,
    nodeType: ELEMENT_TYPES.FACEBOOK,
    generateEmbedBlockData,
    parseEmbedCode: parseFacebookUrlOrEmbedCode,
});

const validate = [facebookUrlOrEmbedCode];

const internalWithFacebook = (editor, options) => {
    const { insertData } = editor;

    return Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    validate={validate}
                    generateEmbedBlock={generateEmbedBlock}
                    type={TYPE}
                    formProps={{
                        withUrlAsDefault: true,
                    }}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, FacebookNode]], options),
        insertData: (data: DataTransfer, options: InsertElementOptions) => {
            // insertdata is getting called with undefined data.
            // This will guard against that.
            if (data) {
                const text = data.getData('text/plain');

                if (text && !facebookUrlOrEmbedCode(text)) {
                    generateEmbedBlock(editor, text);
                } else {
                    insertData(data, options);
                }
            }
        },
    });
};

export const withFacebook = (editor, options) =>
    internalWithFacebook(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withFacebook;
