import {
    CircularProgress,
    createTheme,
    IconButton,
    Link,
    Stack,
    styled,
    type Theme,
    ThemeProvider,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transforms } from 'slate';

import { type FunctionRouter, useFunctionsClient } from '@@api/services/functions/client';
import { useMetadataClient } from '@@api/services/metadata/client';
import { getQueryParams } from '@@api/utils/getQueryParams';
import Icon from '@@components/Icon';
import Image from '@@components/Image';
import Spacer from '@@components/Spacer';
import ContentDetails from '@@containers/ContentItem/ContentDetails';
import { PREVIEW_IMAGE_WIDTH } from '@@containers/ContentItem/styles';
import { getDefaultTeaserVariant } from '@@containers/Teaser/utils';
import { ReactEditor } from '@@editor/helpers';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import toText from '@@editor/utils/toText';

const ButtonsWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    right: theme.spacing(2),
    bottom: theme.spacing(1),
}));

const LinkDetailsWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary['600'],
    width: theme.fixed.contentItem.popper.width,
    textAlign: 'left',
}));

const LoaderWrapper = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const StyledContentDetails = styled(ContentDetails)({
    padding: 0,
});

const StyledImage = styled(Image)(({ theme }) => ({
    width: `${PREVIEW_IMAGE_WIDTH}px`,
    objectFit: 'cover',
    marginRight: theme.spacing(2),
}));

const generateTheme = (theme: Theme) =>
    createTheme(theme, {
        palette: {
            text: {
                primary: theme.palette.common.white,
                secondary: theme.palette.primary['300'],
            },
        },
    });

const LinkDetails = ({ metadataId, url, element, editor }) => {
    const { t } = useTranslation();
    const { client: metadataClient, queryKeys: metadataKeys } = useMetadataClient();
    const { client: functionsClient, queryKeys: functionsKeys } = useFunctionsClient();

    const params = { id: metadataId };

    const { data: metadataData, isLoading: isMetadataLoading } =
        metadataClient.metadata.get.useQuery({
            queryKey: metadataKeys.metadata.get({ params, query: {} }),
            queryData: { params },
            enabled: Boolean(metadataId),
        });

    const metadata = metadataData?.body;

    const query = getQueryParams<FunctionRouter['checkUrlPreview']>({
        url,
    });
    const { data: urlPreviewData, isLoading: isExternalLinkDataLoading } =
        functionsClient.checkUrlPreview.useQuery({
            queryKey: functionsKeys.checkUrlPreview({ query }),
            queryData: { query },
            enabled: Boolean(url),
        });

    const externalLinkData = urlPreviewData?.body;

    const onEdit = () => {
        const linkText = toText(element);

        const path = ReactEditor.findPath(editor, element);

        const range = {
            anchor: { path: [...path, 0], offset: linkText.length },
            focus: { path: [...path, 0], offset: 0 },
        };

        Transforms.select(editor, range);

        editor.showLinkModal();
    };

    const onUnlink = () => {
        editor.removeLink();
    };

    const renderButtons = () => (
        <ButtonsWrapper>
            <IconButton size="small" title={t('contentitem.editButton')} onClick={onEdit}>
                <Icon name="pen-regular" size="small" color="white" />
            </IconButton>
            <IconButton size="small" title={t('editor.remove.link')} onClick={onUnlink}>
                <Icon name={PLUGIN_ICON_NAMES[PLUGIN_NAMES.LINK][1]} size="small" color="white" />
            </IconButton>
        </ButtonsWrapper>
    );

    const renderContent = () => {
        if (isMetadataLoading || isExternalLinkDataLoading) {
            return (
                <LoaderWrapper justifyContent="center" alignItems="center">
                    <CircularProgress color="primary" />
                </LoaderWrapper>
            );
        }

        if (metadata) {
            const entity = {
                ...metadata,
                ...getDefaultTeaserVariant(metadata.teasers),
            };

            return (
                <>
                    <StyledContentDetails entity={entity} />
                    {renderButtons()}
                </>
            );
        }

        if (url && externalLinkData) {
            const { title, previewImageUrl } = externalLinkData;

            return (
                <Stack direction="row">
                    <StyledImage src={previewImageUrl} />

                    <Stack>
                        <Link
                            href={url}
                            target="_blank"
                            color="text.secondary"
                            underline="always"
                            variant="small"
                            display="block"
                            rel="noreferrer"
                            // link does not work correctly without it
                            component="a"
                        >
                            {url}
                        </Link>

                        <Spacer v xs />

                        <Typography variant="small" color="text.primary" component="p">
                            {title}
                        </Typography>

                        <Spacer v md />
                    </Stack>

                    {renderButtons()}
                </Stack>
            );
        }
    };

    return (
        <ThemeProvider theme={generateTheme}>
            <LinkDetailsWrapper contentEditable={false}>{renderContent()}</LinkDetailsWrapper>
        </ThemeProvider>
    );
};

export default LinkDetails;
