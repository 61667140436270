import validator from 'validator';

import config from '@@config';

const isFrontendComponentUrl = (value) => {
    if (validator.isURL(value)) {
        const url = new URL(value);

        return url && url.host.endsWith(config.frontendComponentHost);
    }

    return false;
};

export default (value) => {
    if (!isFrontendComponentUrl(value)) {
        // t('validator.message.invalidFrontendComponentUrl');
        return ['validator.message.invalidFrontendComponentUrl'];
    }
};
