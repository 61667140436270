import { type FieldPath, type FieldValues } from 'react-hook-form';

import RichTextString from '@@form/components/RichTextEditor/RichTextString';

import RichTextField, { type RichTextFieldProps } from './RichTextField';

export const RichTextStringField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: RichTextFieldProps<TFieldValues, TName>,
) => <RichTextField {...props} component={RichTextString} />;

export default RichTextStringField;
