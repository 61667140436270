import { Transforms } from 'slate';

import { LoadingStatusManager } from '@@containers/LoadingStatusManager';
import { generateKeyForRichTextEditorLoadingStatus } from '@@containers/LoadingStatusManager/utils';
import snackbar from '@@containers/Snackbar';
import { DATA_TRANSFER_TYPE_UNITY_ATTACHMENT } from '@@editor/constants';
import { Editor, Node, type Operation, ReactEditor } from '@@editor/helpers';
import { type GlobalOptions, type InsertElementOptions } from '@@editor/helpers/Editor';
import { Element, ELEMENT_TYPES, type ImageElement, NameSource } from '@@editor/helpers/Element';
import { updateFileNodeData } from '@@editor/plugins/fileUpload/utils';
import deleteBackward from '@@editor/plugins/utils/deleteBackward';
import deleteForward from '@@editor/plugins/utils/deleteForward';
import deleteFragment from '@@editor/plugins/utils/deleteFragment';
import {
    normalizeInlineEditableElement,
    preventDeleteBackward,
    preventDeleteForward,
    preventDeleteFragment,
    preventInsertBreak,
    syncInlineEditedEmbedElement,
} from '@@editor/plugins/utils/inlineEditing';
import insertBreak from '@@editor/plugins/utils/insertBreak';
import normalizeNode from '@@editor/plugins/utils/normalizeNode';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import getElvisIdByImage from '@@editor/selectors/getElvisIdByImage';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES, type PluginOptions } from '@@editor/typings/UnityPlugins';
import getImagesFromDataTransfer, {
    INVALID_FILE_TYPE_ERROR,
} from '@@utils/dataTransfer/getImagesFromDataTransfer';
import getUrlsFromDataTransfer from '@@utils/dataTransfer/getUrlsFromDataTransfer';
import { parseUrl } from '@@utils/URL';

import EditorWithEmbedModal from './../components/EditorWithEmbedModal';
import EmbedWrapper from './../components/EmbedWrapper';
import { createGenerateEmbedBlock } from './../utils';
import { createGenerateEmbedFiles } from './../utils/file';
import ImageDropArea from './components/ImageDropArea';
import PreviewImage from './components/PreviewImage';
import { allowedMimeTypes } from './constants';
import ImageForm from './ImageForm';
import { generateEmbedBlockData } from './utils';

const TYPE = PLUGIN_NAMES.IMAGE;
const NODE_TYPE = ELEMENT_TYPES.IMAGE;
const INLINE_EDITABLE_CHILDREN_TYPES = [ELEMENT_TYPES.EMBED_CAPTION, ELEMENT_TYPES.EMBED_CREDIT];

const generateImageDescription = (
    editor: Editor,
    node: ImageElement,
    previousNode: Node | null,
    options: RequiredBy<PluginOptions, 'generateImageAltText'>,
) => {
    switch (node.type) {
        case ELEMENT_TYPES.IMAGE: {
            if (
                node.data.src &&
                previousNode?.data.src !== node.data.src &&
                node.data.embed?.nameSource !== NameSource.AI &&
                node.data.embed?.nameSource !== NameSource.UNITY &&
                // Do not start upload again, if user pressed ctrl+z
                editor.history.redos.length === 0
            ) {
                const key = generateKeyForRichTextEditorLoadingStatus({
                    editorId: editor.id,
                    loadingStatusId: node.data.loadingStatusId,
                    type: 'generateImageAltText',
                });

                LoadingStatusManager.load({ key });

                options
                    .generateImageAltText({ imageUrl: node.data.src.toString() })
                    .then(({ altText }) => {
                        updateFileNodeData(editor, node, {
                            embed: {
                                name: altText,
                                nameSource: NameSource.AI,
                            },
                        });

                        LoadingStatusManager.loadSuccess({ key, data: { altText } });
                    })
                    .catch(() => {
                        updateFileNodeData(editor, node, {
                            embed: {
                                name: null,
                                nameSource: null,
                            },
                        });

                        LoadingStatusManager.loadError({
                            key,
                            error: new Error('Error while generating image alt text'),
                        });
                    });
            }
        }
    }
};

const generateEmbedBlock = createGenerateEmbedBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
    parseEmbedCode: parseUrl,
});

// If we pass children to the drop area, it will behave like:
// - Render the drop area when hovered
// - Render the passed children when NOT hovered
const Content = (props) => {
    const { editor } = props;
    const { useInlineEditing } = editor;

    return (
        <ImageDropArea {...props} droppable={useInlineEditing && !ReactEditor.isReadOnly(editor)}>
            <PreviewImage {...props} />
        </ImageDropArea>
    );
};

// If we do NOT pass children to the drop area, it will behave like:
// - Render the drop area when hovered
// - Render the drop area also when NOT hovered
const Placeholder = (props) => <ImageDropArea {...props}>{false}</ImageDropArea>;

type Props = {
    editor: Editor;
    element: ImageElement;
} & GlobalOptions;

const ImageElementComponent = (props: Props) => {
    const { editor, element } = props;
    const { useInlineEditing } = editor;

    return useInlineEditing ? (
        <EmbedWrapper
            {...props}
            type={TYPE}
            toolbarConfig={{
                infos: {
                    iconName: PLUGIN_ICON_NAMES[TYPE],
                    title: 'Image',
                },
                actions: Element.isTemplateElement(element)
                    ? ['delete']
                    : [
                          {
                              type: 'edit',
                              iconName: 'gear',
                              onClick: (e) => {
                                  e.preventDefault();

                                  return requestAnimationFrame(() =>
                                      editor.showEmbedModal(
                                          TYPE,
                                          element,
                                          ReactEditor.findPath(editor, element),
                                      ),
                                  );
                              },
                          },
                          'delete',
                      ],
            }}
            contentEditable={false}
            component={Content}
            // Do not display an overlay component, in order to allow drag and drop actions on the content component
            overlayComponent={null}
            placeholderComponent={Placeholder}
        />
    ) : (
        <EmbedWrapper
            {...props}
            type={TYPE}
            toolbarConfig={{
                infos: {
                    iconName: PLUGIN_ICON_NAMES[TYPE],
                    title: 'Image',
                },
                actions: ['edit', 'delete'],
            }}
            component={PreviewImage}
            // Do not display an overlay component, in order to allow drag and drop actions on the content component
            overlayComponent={null}
            placeholderText={editor.t('editor.plugin.image.placeholderText')}
            placeholderIconName={PLUGIN_ICON_NAMES[TYPE]}
        />
    );
};

const internalWithImage = (editor: Editor, options) => {
    const {
        apply,
        insertData,
        useInlineEditing,
        onEditorMount,
        onAfterUpdateElement,
        onAfterInsertElement,
    } = editor;
    const generateEmbedFiles = createGenerateEmbedFiles(options);
    const elementsContextActions = options.elementsContextActions;

    return Object.assign(editor, {
        apply: (operation: Operation) => {
            if (operation.type === 'set_node') {
                const node = Node.get(editor, operation.path);

                if (Element.isImageElement(node) && !Editor.isVoid(editor, node)) {
                    // We need to sync the inline edited image (children) whenever embed data of an image has been
                    // changed (for example after uploading the image to the BE, the `uploadFileHelper` will update
                    // embed data)
                    syncInlineEditedEmbedElement(
                        editor,
                        node,
                        operation.path,
                        operation.properties.data?.embed,
                        operation.newProperties.data?.embed,
                    );
                }
            }

            if (elementsContextActions) {
                if (operation.type === 'insert_node') {
                    if (Element.isImageElement(operation.node)) {
                        elementsContextActions.addElement?.({
                            editorId: editor.id,
                            element: operation.node,
                        });
                    }
                } else if (operation.type === 'set_node') {
                    const [node] = Editor.node(editor, operation.path);

                    if (
                        Element.isImageElement(node) &&
                        getElvisIdByImage(operation.properties) !==
                            getElvisIdByImage(operation.newProperties)
                    ) {
                        elementsContextActions.removeElement?.({
                            editorId: editor.id,
                            element: node,
                        });

                        elementsContextActions.addElement?.({
                            editorId: editor.id,
                            element: { ...node, ...operation.newProperties },
                        });
                    }
                } else if (operation.type === 'remove_node') {
                    if (Element.isImageElement(operation.node)) {
                        elementsContextActions.removeElement?.({
                            editorId: editor.id,
                            element: operation.node,
                        });
                    }
                }
            }

            apply(operation);
        },
        deleteForward: deleteForward(editor, [
            [preventDeleteForward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteBackward: deleteBackward(editor, [
            [preventDeleteBackward, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        deleteFragment: deleteFragment(editor, [
            [preventDeleteFragment, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        insertBreak: insertBreak(editor, [
            [preventInsertBreak, { types: INLINE_EDITABLE_CHILDREN_TYPES }],
        ]),
        normalizeNode: normalizeNode(editor, [
            [
                normalizeInlineEditableElement,
                {
                    type: ELEMENT_TYPES.IMAGE,
                    allowedChildrenTypes: INLINE_EDITABLE_CHILDREN_TYPES,
                },
            ],
        ]),
        onEditorMount: () => {
            if (elementsContextActions) {
                const matches = Editor.elements<ImageElement>(editor, {
                    mode: 'highest',
                    at: [],
                    types: NODE_TYPE,
                });

                const array: ImageElement[] = [];

                for (const match of matches) {
                    const [node] = match;

                    array.push(node);
                }

                elementsContextActions.mount?.({ editorId: editor.id, elements: array });
            }

            onEditorMount();
        },
        onEditorUnmount: () => {
            if (elementsContextActions) {
                elementsContextActions.unMount?.({ editorId: editor.id });
            }
        },
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formComponent={ImageForm}
                    generateEmbedBlock={generateEmbedBlock}
                    type={TYPE}
                    formProps={{ inlineEdited: useInlineEditing }}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, ImageElementComponent]], {
            ...options,
            generateEmbedFiles,
        }),
        insertData: (data: DataTransfer, options: InsertElementOptions) => {
            const files = data.files;
            const html = data.getData('text/html');
            const text = data.getData('text/plain');
            const urls = getUrlsFromDataTransfer(data);
            const attachment = data.getData(DATA_TRANSFER_TYPE_UNITY_ATTACHMENT);

            const images = getImagesFromDataTransfer(
                { files, html, text, urls, attachment },
                allowedMimeTypes,
                (error) => {
                    // Do not show error for invalid urls, since following plugins in the chain, like
                    // the link plugin, might handle this url if it does not contain an image
                    if (error?.name === INVALID_FILE_TYPE_ERROR) {
                        snackbar.error(
                            <>
                                <strong>{error.name}</strong> {error.message}
                            </>,
                        );
                    }
                },
            );

            if (images.length) {
                generateEmbedFiles(editor, images, options);
            } else {
                // If no image was found, we have to pass the data further down the plugin chain. Maybe another
                // plugin knows what to do with this data
                insertData(data);
            }
        },
        insertImage: (at) => {
            if (useInlineEditing) {
                generateEmbedBlock(
                    editor,
                    {},
                    {
                        at,
                        replace: false,
                    },
                );
                Transforms.select(editor, at);
                Transforms.collapse(editor);
            } else {
                editor.showEmbedModal(TYPE, undefined, at);
            }
        },
        onAfterUpdateElement: (node: Node, previousNode?: Node) => {
            generateImageDescription(editor, node, previousNode, options);

            onAfterUpdateElement(node, previousNode);
        },
        onAfterInsertElement: (node: Node) => {
            generateImageDescription(editor, node, null, options);

            onAfterInsertElement(node);
        },
    });
};

export const withImage = (editor, options) =>
    internalWithImage(editor, {
        ...options,
        nodeType: NODE_TYPE,
    });

export default withImage;
