import { cloneDeep, difference, forEach, groupBy, keyBy, last, omit, uniq } from 'lodash-es';

import { type Category } from '@@api/services/tenant/schemas';

import { FRONT_CATEGORY_ID } from './constants';

export const prepareCategoryTree = (data: Category[]) => {
    const newData = cloneDeep(data);
    const groupedByParents = groupBy(newData, 'parentId');
    const categoriesById = keyBy(newData, 'id');

    // "null" is the root level, because parentId is "null"
    // We need this to be defined in order to push children into it.
    if (!groupedByParents.null) {
        groupedByParents.null = [];
    }

    forEach(omit(groupedByParents, ['null']), (children, parentId) => {
        if (categoriesById[parentId]) {
            categoriesById[parentId].children = children;
        } else {
            // If the parent was filtered away, we still want to
            // show the valid children, so we just add them to the
            // root level.
            groupedByParents.null.push(...children);
        }
    });

    return {
        categoriesById,
        groupedByParents,
        // Expose our root level
        categoryTree: groupedByParents.null,
    };
};

export const makeCategoryTree = (data: Category[]) => prepareCategoryTree(data).categoryTree;

export const getDefaultCategoryPlacementDetails = (categories, categoryPlacementDetails) =>
    categories.reduce(
        (result, id) => ({
            ...result,
            [`categoryId${id}`]: categoryPlacementDetails[`categoryId${id}`] ?? { position: 1 },
        }),
        {},
    );

export const buildMainCategoryList = (
    categoryList: Category[],
    categoryIds: Category['id'][] = [],
    mainCategoryId?: Category['id'] | undefined,
) => {
    const allowedCategoryIds = [...categoryIds, ...(mainCategoryId ? [mainCategoryId] : [])];

    if (categoryList) {
        return categoryList.filter(
            (item) =>
                uniq(allowedCategoryIds).includes(item.id) &&
                item.id !== FRONT_CATEGORY_ID &&
                (item.active || item.id === mainCategoryId) &&
                (!item.expiryDate || item.id === mainCategoryId),
        );
    }

    return [];
};

export const categoryToSetAsMain = (
    mainCategoryId: Category['id'] | undefined,
    prevCategoryIds: Category['id'][],
    nextCategoryIds: Category['id'][],
): Category['id'] | undefined => {
    const addedIds = difference(nextCategoryIds, prevCategoryIds);
    const newSelectedValue = last(addedIds);
    const category = [
        // Prefer selected category
        ...(newSelectedValue ? [newSelectedValue] : []),
        ...nextCategoryIds,
    ].find((id) => id !== FRONT_CATEGORY_ID);

    const hasOutdatedMainCategory = !nextCategoryIds.find((id) => id === mainCategoryId);
    const hasNoMainCategory = !mainCategoryId;

    if ((hasNoMainCategory || hasOutdatedMainCategory) && category) {
        return category;
    }
};
