import {
    FormControl,
    type FormControlProps,
    FormHelperText,
    type FormHelperTextProps,
    FormLabel,
    type FormLabelProps,
    styled,
    ToggleButtonGroup as MuiToggleButtonGroup,
    type ToggleButtonGroupProps,
} from '@mui/material';
import { type FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { type FormFieldError } from '@@form/hooks/useReactHookFormFieldError';
import { getErrorMessage } from '@@form/utils/getErrorMessage';

import ToggleButtonRadio, { type ToggleButtonRadioProps } from './ToggleButtonRadio';

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)({
    flexWrap: 'wrap',
});

export type ToggleButtonRadioGroupProps = Omit<ToggleButtonGroupProps, 'ref'> & {
    label?: FormLabelProps['children'];
    error?: FormFieldError | FieldError;
    required?: FormControlProps['required'];
    disabled?: FormControlProps['disabled'];
    inputRef?: FormControlProps['ref'];
    helperText?: FormHelperTextProps['children'];
} & {
    fields: ToggleButtonRadioProps[];
    name: string;
};
const ToggleButtonGroup = (props: ToggleButtonRadioGroupProps) => {
    const { required, error, disabled, fields, label, name, inputRef, helperText, ...rest } = props;

    const { t } = useTranslation();

    const errorMessage = getErrorMessage(error, t);

    const helperTextValue = helperText || errorMessage;

    return (
        <FormControl
            ref={inputRef}
            error={Boolean(error)}
            required={required}
            disabled={disabled}
            fullWidth
        >
            {label && <FormLabel id={name}>{label}</FormLabel>}
            <StyledToggleButtonGroup exclusive aria-labelledby={label ? name : undefined} {...rest}>
                {fields.map((field, index) => (
                    <ToggleButtonRadio
                        key={index}
                        value={field.value}
                        label={field.label}
                        disabled={disabled}
                    />
                ))}
            </StyledToggleButtonGroup>
            {helperTextValue && <FormHelperText>{helperTextValue}</FormHelperText>}
        </FormControl>
    );
};

export default ToggleButtonGroup;
