import { ELEMENT_TYPES, MIME_TYPES } from '@@editor/helpers/Element';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';
import isFrontendComponentUrl from '@@form/utils/validators/isFrontendComponentUrl';
import FrontendComponentLogo from '@@images/frontendComponent.svg';

import { generateEmbedBlockData } from './utils';
import EditorWithEmbedModal from '../components/EditorWithEmbedModal';
import EmbedPlaceholder from '../components/EmbedPlaceholder';
import EmbedWrapper from '../components/EmbedWrapper';
import { createGenerateEmbedBlock } from '../utils';
import FrontendComponentForm from './components/FrontendComponentForm';

const TYPE = PLUGIN_NAMES.FRONTEND_COMPONENT;
const NODE_TYPE = ELEMENT_TYPES.FRONTEND_COMPONENT;

const mimetypeConfigs = {
    [MIME_TYPES.FRONTEND_COMPONENT]: {
        Component: ({ editor, element }) => {
            const label = element.data?.embed?.label;

            return (
                <EmbedPlaceholder
                    intro={label}
                    message={editor.t('editor.plugin.frontendComponent.message')}
                    image={{
                        src: FrontendComponentLogo,
                        alt: editor.t('editor.plugin.frontendComponent.logoAlt'),
                    }}
                />
            );
        },
    },
};

const FrontendComponentNode = (props) => (
    <EmbedWrapper
        {...props}
        type={TYPE}
        mimetypeConfigs={mimetypeConfigs}
        toolbarConfig={{
            infos: {
                iconName: PLUGIN_ICON_NAMES[TYPE],
                title: props.editor.t('editor.plugin.frontendComponent.toolbarTitle'),
            },
            actions: ['edit', 'delete'],
        }}
    />
);

const generateEmbedBlock = createGenerateEmbedBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
    generateEmbedBlockData,
});

const withFrontendComponent = (editor, options) =>
    Object.assign(editor, {
        renderEditor: renderEditor(
            editor,
            (props) => (
                <EditorWithEmbedModal
                    {...props}
                    formComponent={FrontendComponentForm}
                    generateEmbedBlock={generateEmbedBlock}
                    validate={[isFrontendComponentUrl]}
                    type={TYPE}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, FrontendComponentNode]], options),
    });

export default withFrontendComponent;
