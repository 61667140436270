type QueryParams = Record<string, string | number | boolean>;

type Config = {
    selector: string;
    path: string;
    getPath: (
        scenarioName: string | undefined,
        options?: {
            preset?: string;
            queryParams?: QueryParams;
        },
    ) => string;
};

const config: Config = {
    selector: 'Isolated__ScreenshotArea',
    path: 'isolated/:scenarioName?/:preset?',
    getPath: (scenarioName, { preset, queryParams } = {}) => {
        let path = config.path;

        path = path.replace(':scenarioName?', scenarioName ?? '');
        path = preset ? path.replace(':preset?', preset) : path.replace('/:preset?', '');
        path += queryParams
            ? '?' +
              Object.entries(queryParams)
                  .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
                  .join('&')
            : '';

        return path;
    },
};

export default config;
