import { castArray, first, flow, pick } from 'lodash-es';

import { Element, ELEMENT_TYPES, type SummaryListElement } from '@@editor/helpers/Element';
import transformValues from '@@editor/serialization/transformValues';
import { isUnitySummaryList, type UnitySummaryList } from '@@editor/typings/UnityElements';

import { CURRENT_VERSION } from '../constants';
import { createElement } from '../deserializeNodes';
import { type SerializerOptions } from '../types';

const serializeInlineSummaryListData = (children: Element[], data: SummaryListElement['data']) => {
    const newData = { ...data };

    const summary = children.find(Element.isSummaryListSummaryElement);

    if (summary) {
        newData.summary = summary.children;
    }

    return newData;
};

const addEmptySummaryList = (value) => {
    if (value?.items.length > 0) {
        return value;
    }

    return {
        type: ELEMENT_TYPES.UNORDERED_LIST,
        version: CURRENT_VERSION,
        items: [
            {
                type: ELEMENT_TYPES.TEXT,
                version: CURRENT_VERSION,
                items: [
                    {
                        text: '',
                        version: CURRENT_VERSION,
                        type: ELEMENT_TYPES.TEXTITEM,
                    },
                ],
            },
        ],
    };
};

const summaryListRules = {
    deserialize: (element: UnitySummaryList, next, options?): SummaryListElement | undefined => {
        if (!isUnitySummaryList(element)) {
            return;
        }

        const values = pick(element, ['summary', 'templateElement']);

        return createElement<SummaryListElement>(
            ELEMENT_TYPES.SUMMARY_LIST,
            transformValues<typeof values, SummaryListElement['data']>(values, [
                ['summary', flow(addEmptySummaryList, next, castArray)],
            ]),
            options,
        );
    },

    serialize: (
        node: SummaryListElement,
        next,
        options: SerializerOptions = {},
    ): UnitySummaryList | undefined => {
        if (!Element.isSummaryListElement(node)) {
            return;
        }

        const { type, data, children } = node;
        const { useInlineEditing } = options;
        const newData = useInlineEditing ? serializeInlineSummaryListData(children, data) : data;

        return {
            version: CURRENT_VERSION,
            type,
            ...transformValues<typeof data, Omit<UnitySummaryList, 'version' | 'type'>>(newData, [
                ['summary', flow(first, next)],
            ]),
        };
    },
};

export default summaryListRules;
