import { alpha, Button, Link, Stack, styled } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type SlideshowRouter, useSlideshowClient } from '@@api/services/slideshow/client';
import { type Slideshow as SlideshowType } from '@@api/services/slideshow/schemas';
import { getQueryParams } from '@@api/utils/getQueryParams';
import Icon from '@@components/Icon';
import Spacer from '@@components/Spacer';
import { type IImageTransformationsOutput } from '@@utils/buildImageTransformationUrl';

import {
    IMAGE_WRAPPER_HEIGHT,
    IMAGE_WRAPPER_HEIGHT_READONLY,
    SLIDE_PRELOAD_LIMIT,
} from './constants';
import SlideshowImage from './SlideshowImage';

const Wrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplate: '"preview" 1fr "counter" auto "caption" auto',
    width: '100%',
    ...theme.typography.small,
    lineHeight: 'initial',
    backgroundColor: theme.palette.primary.light,
}));

const DisabledMessageStack = styled(Stack)(({ theme }) => ({
    minHeight: '330px',
    maxHeight: '330px',
    minWidth: '100%',
    maxWidth: '100%',
    textAlign: 'center',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    ...theme.typography.small,
}));

const Navigation = styled('div')({
    gridArea: 'preview',
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const ARROW_HEIGHT = '20%';

const Arrow = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    cursor: 'pointer',
    [`${Icon}`]: {
        color: alpha(theme.palette.common.white, theme.opacityFactors.low),
    },
    '&:hover': {
        [`${Icon}`]: {
            color: alpha(theme.palette.common.white, theme.opacityFactors.medium),
        },
    },
}));

const SlideCounter = styled('div')(({ theme }) => ({
    gridArea: 'counter',
    ...theme.typography.editorSecondarySmall,
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(2, 0, 0, 2),
}));

const NoSlidePlaceholder = styled(Stack)(({ theme }) => ({
    gridArea: 'preview',
    height: '100px',
    backgroundColor: theme.palette.common.white,
}));

const LinkIcon = styled(Icon)({
    position: 'relative',
    top: '0.15rem',
    left: '0.1rem',
});

type Props = {
    readOnly: boolean;
    slideshowId?: SlideshowType['id'];
    disabled?: boolean;
    disabledMessage?: string;
    renderImage?: (imageProps: {
        slideIndex: number;
        transformations: IImageTransformationsOutput;
    }) => JSX.Element;
    slidesLength?: number;
};

const pageSize = SLIDE_PRELOAD_LIMIT;

const Slideshow = (props: Props) => {
    const { slideshowId, disabled, disabledMessage, renderImage, readOnly, slidesLength } = props;

    const { t } = useTranslation();

    const [slideIndex, setSlideIndex] = useState(0);
    const slidePageNumber = Math.floor(slideIndex / pageSize);

    const { client: slideshowClient, queryKeys: slideshowKeys } = useSlideshowClient();

    const params = { id: slideshowId! };
    const query = getQueryParams<SlideshowRouter['slide']['getAll']>({
        size: pageSize,
        page: slidePageNumber,
    });

    const { data, isPending: isSlidesLoading } = slideshowClient.slide.getAll.useQuery({
        queryKey: slideshowKeys.slide.getAll({ params, query }),
        queryData: { params, query },
        enabled: Boolean(slideshowId),
    });

    const content = data?.body?.content || [];
    const totalElements = data?.body?.totalElements || pageSize;

    const slidesCount = (slideshowId ? totalElements : slidesLength) as number;

    const incrementSlideIndex = (by = 1) => {
        const newSlideIndex = (slideIndex + by) % slidesCount;

        setSlideIndex(newSlideIndex === -1 ? slidesCount - 1 : newSlideIndex);
    };

    const handleNext = () => {
        incrementSlideIndex();
    };

    const handlePrevious = () => {
        incrementSlideIndex(-1);
    };

    const currentSlide = useMemo(() => content[slideIndex % pageSize], [content, slideIndex]);

    const renderSlide = () => {
        const imageProps = {
            slideIndex,
            transformations: {
                maxHeight: readOnly ? IMAGE_WRAPPER_HEIGHT_READONLY : IMAGE_WRAPPER_HEIGHT,
            },
        };

        if (renderImage) {
            return renderImage(imageProps);
        }

        return (
            <SlideshowImage
                slide={currentSlide}
                isLoading={isSlidesLoading || !slideshowId}
                {...imageProps}
            />
        );
    };

    if (disabled) {
        const metadataUrl = `/slideshows/${slideshowId}/metadata`;

        return (
            <DisabledMessageStack alignItems="center" justifyContent="center">
                {disabledMessage}

                <Link href={metadataUrl} target="_blank">
                    {t('editor.edit.embed.slideshow')}{' '}
                    <LinkIcon name="arrow-up-right-from-square" />
                </Link>
            </DisabledMessageStack>
        );
    }

    return (
        <Wrapper>
            {slidesCount !== 0 ? (
                renderSlide()
            ) : (
                <NoSlidePlaceholder alignItems="center" justifyContent="center">
                    {t('slideshow.editorPlugin.preview.noSlideMessage')}

                    <Spacer v sm />

                    <Button href={`/slideshows/${slideshowId}`} variant="outlined" target="_blank">
                        {t('slideshow.editorPlugin.preview.noSlideLink')}
                    </Button>
                </NoSlidePlaceholder>
            )}

            <Navigation>
                <Arrow onClick={handlePrevious}>
                    <Icon name="chevron-left-sharp" size={ARROW_HEIGHT} />
                </Arrow>
                <Arrow onClick={handleNext}>
                    <Icon name="chevron-right-sharp" size={ARROW_HEIGHT} />
                </Arrow>
            </Navigation>

            <SlideCounter> {`${slideIndex + 1}/${slidesCount}`}</SlideCounter>
        </Wrapper>
    );
};

export default Slideshow;
