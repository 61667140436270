import { useTranslation } from 'react-i18next';

import Spacer from '@@components/Spacer';
import AutocompleteField from '@@form/fields/Autocomplete';
import InfoboxEditorField from '@@form/fields/RichTextEditor/InfoboxEditor';
import SimpleRichTextEditorField from '@@form/fields/RichTextEditor/SimpleRichTextEditor';
import SwitchField from '@@form/fields/SwitchField';

import { CONTAINER_TYPE_MAPPINGS, DEFAULT_CONTAINER_TYPE } from '../constants';
type Props = {
    groupName?: string;
    inlineEdited?: boolean;
};

const InfoboxFields = ({ groupName, inlineEdited }: Props) => {
    const { t } = useTranslation();

    const makeName = (name) => (groupName ? `${groupName}.${name}` : name);

    const styleOptions = Object.entries(CONTAINER_TYPE_MAPPINGS).map(([key, value]) => ({
        label: t(value),
        value: key,
    }));
    const defaultValue = { label: t(DEFAULT_CONTAINER_TYPE), value: DEFAULT_CONTAINER_TYPE };

    return inlineEdited ? (
        <SwitchField
            name={makeName('collapsed')}
            label={t('editor.plugin.infobox.form.collapsed')}
        />
    ) : (
        <>
            <AutocompleteField
                name={makeName('style.containerType')}
                label={t('editor.plugin.infobox.form.style')}
                defaultValue={defaultValue}
                disableClearable
                options={styleOptions}
                getOptionKey={(option) => option.value}
                getOptionLabel={(option) => option.label}
                transform={{
                    input: (value) =>
                        value ? styleOptions.find((option) => option.value === value) : null,
                    output: (event, value) => value?.value ?? null,
                }}
            />

            <Spacer v md />

            <SwitchField
                name={makeName('collapsed')}
                label={t('editor.plugin.infobox.form.collapsed')}
            />

            <Spacer v md />

            <SimpleRichTextEditorField
                name={makeName('title')}
                label={t('editor.plugin.infobox.form.title')}
                required
            />

            <Spacer v md />

            <InfoboxEditorField
                name={makeName('content')}
                label={t('editor.plugin.infobox.form.content')}
                required
            />
        </>
    );
};

export default InfoboxFields;
