import { type RouteObject } from 'react-router-dom';

export const routes: RouteObject[] = [
    {
        path: '',
        lazy: async () => {
            const { Layout } = await import('@@routes/categories');

            return {
                Component: Layout,
            };
        },
        children: [
            {
                path: ':id',
                lazy: async () => {
                    const { default: CategoryForm } = await import(
                        '@@routes/categories/CategoryForm'
                    );

                    return { Component: CategoryForm };
                },
            },
        ],
    },
];
