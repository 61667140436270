import { type ExistsFunction } from 'i18next';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

/** This comment is for translation auto extract
    t('page.title.articles')
    t('page.title.tickers')
    t('page.title.slideshows')
    t('page.title.externalContent.videos')
    t('page.title.externalContent.links')
    t('page.title.externalContent.embeds')
    t('page.title.breakingnews')
    t('page.title.placement')
    t('page.title.categories')
    t('page.title.sitemaps')
    t('page.title.collections')
    t('page.title.tags')
    t('page.title.kickwords')
    t('page.title.teams')
    t('page.title.rtelements')
    t('page.title.users')
    t('page.title.userSettings')
    t('page.title.home')
    t('page.title.curatedList')
*/

const getPageName = (pathname: string, existsFn: ExistsFunction): string => {
    const path = pathname.split('/').filter(Boolean);

    if (!path.length) {
        return `page.title.home`;
    }

    let title = '';

    while (!existsFn(title) && path.length) {
        title = ['page.title', ...path].join('.');

        path.pop();
    }

    return existsFn(title) ? title : '';
};

const PageTitle = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const pageName = getPageName(location.pathname, i18n.exists);

    const title = pageName ? `${t(pageName)} | Unity CMS` : 'Unity CMS';

    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

export default PageTitle;
