import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import Loader from '@@components/Loader';
import { LoadingStatusManager } from '@@containers/LoadingStatusManager';
import { generateKeyForRichTextEditorLoadingStatus } from '@@containers/LoadingStatusManager/utils';
import { type Editor } from '@@editor/helpers';
import { type EmbedElement } from '@@editor/helpers/Element';

type ChildrenProps = { onLoad: VoidFunction; onError: VoidFunction };

type Props = {
    editor: Editor;
    element: EmbedElement;
    children: (props: ChildrenProps) => ReactNode;
};

const EmbedComponentLoader = ({ children, editor, element }: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const isMounted = useRef<boolean | null>(null);
    const isLoadedBeforeMount = useRef(false);
    const isErrorBeforeMount = useRef(false);

    const key = generateKeyForRichTextEditorLoadingStatus({
        editorId: editor.id,
        loadingStatusId: element.data.loadingStatusId,
    });

    useEffect(() => {
        isMounted.current = true;

        LoadingStatusManager.load({
            key,
        });

        // It is possible, that `onLoad` is fired before the component is mounted. In this case we have to call
        // the load handler manually.
        if (isLoadedBeforeMount.current) {
            handleOnLoad();
        }

        // It is possible, that `onError` is fired before the component is mounted. In this case we have to call
        // the error handler manually.
        if (isErrorBeforeMount.current) {
            handleOnError();
        }

        return () => {
            isMounted.current = false;

            LoadingStatusManager.cancelLoading({ key });
        };
    }, []);

    const handleOnLoad = useCallback(() => {
        if (isMounted.current !== false) {
            setIsLoading(false);
        }

        if (isMounted.current) {
            LoadingStatusManager.loadSuccess({ key });
        } else {
            isLoadedBeforeMount.current = true;
        }
    }, [key]);

    const handleOnError = useCallback(() => {
        if (isMounted.current !== false) {
            setIsLoading(false);
        }

        if (isMounted.current) {
            LoadingStatusManager.loadError({
                key,
                error: new Error('Error while loading embed component', { cause: element }),
            });
        } else {
            isErrorBeforeMount.current = true;
        }
    }, [key, element]);

    return (
        <Loader isLoading={isLoading} keepChildrenInDom>
            {children({ onLoad: handleOnLoad, onError: handleOnError })}
        </Loader>
    );
};

export default EmbedComponentLoader;
