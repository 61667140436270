import { Divider, IconButton, Stack, Tooltip } from '@mui/material';
import { type MouseEvent, type ReactNode, type Ref } from 'react';
import { Translation } from 'react-i18next';

import ContextMenu from '@@components/ContextMenu/ContextMenu';
import Icon from '@@components/Icon';
import { type ActionType } from '@@routes/commons';

import ActionMenuContext from './ActionMenuContext';
import ActionMenuItem from './ActionMenuItem';

type RenderActionProps = {
    action: IContextMenuAction;
    onClick: (e: MouseEvent, action: IContextMenuAction) => void;
    extraData?: UnknownObject;
    iconOnlyIfInline?: boolean;
    isInline?: boolean;
};

const renderAction = ({
    action,
    extraData,
    iconOnlyIfInline,
    isInline,
    onClick,
}: RenderActionProps) => {
    const showTitle = !(iconOnlyIfInline && isInline);

    if (typeof action.render === 'function') {
        return action.render({
            action,
            extraData,
            isInline,
            showTitle,
            onClick,
        });
    }

    return (
        <ActionMenuItem
            key={action.type}
            {...{
                action,
                isInline,
                onClick,
            }}
        >
            {showTitle && action.title}
        </ActionMenuItem>
    );
};

const defaultRenderMenuButton = ({ ref, openMenu }) => (
    <ActionMenuContext.Consumer>
        {({ enableTooltips }) => (
            <Translation>
                {(t) => {
                    const button = (
                        <IconButton
                            ref={ref}
                            title={!enableTooltips ? t('action.more') : undefined}
                            onClick={openMenu}
                        >
                            <Icon name="ellipsis-vertical" />
                        </IconButton>
                    );

                    return enableTooltips ? (
                        <Tooltip title={t('action.more')}>{button}</Tooltip>
                    ) : (
                        button
                    );
                }}
            </Translation>
        )}
    </ActionMenuContext.Consumer>
);

type Props = {
    actions: IContextMenuAction[];
    onClickAction: (e: MouseEvent, action: IContextMenuAction, extraData?: UnknownObject) => void;
    enableTooltips?: boolean;
    wrapAfterActionType?: ActionType;
    wrapAfter?: number;
    extraData?: UnknownObject;
    iconOnlyIfInline?: boolean;
    renderMenuButton?: (props: { ref: Ref<HTMLElement>; openMenu: VoidFunction }) => ReactNode;
};

const ActionMenu = (props: Props) => {
    const {
        actions,
        enableTooltips,
        extraData,
        iconOnlyIfInline = true,
        renderMenuButton = defaultRenderMenuButton,
        wrapAfter = 2,
        wrapAfterActionType,
        onClickAction,
    } = props;

    const handleClick = (e, action) => {
        onClickAction(e, action, extraData);
    };

    const filterByRenderOutput = (isInline, action) =>
        typeof action.render === 'function'
            ? renderAction({ action, extraData, iconOnlyIfInline, isInline, onClick: handleClick })
            : true;

    const wrapAfterIndex = wrapAfterActionType
        ? actions.findIndex((action) => action.type === wrapAfterActionType) + 1
        : wrapAfter;

    const inlineActions = actions
        .slice(0, wrapAfterIndex)
        .filter(filterByRenderOutput.bind(null, true));

    const wrapedActions = actions
        .slice(wrapAfterIndex)
        .filter(filterByRenderOutput.bind(null, false));

    return (
        <ActionMenuContext.Provider value={{ enableTooltips }}>
            <Stack direction="row" gap={2}>
                {inlineActions.map((action) =>
                    renderAction({
                        action,
                        extraData,
                        iconOnlyIfInline,
                        isInline: true,
                        onClick: handleClick,
                    }),
                )}

                {wrapedActions.length > 0 && (
                    <ContextMenu
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        renderItems={(closeMenu) => {
                            const handleClickAndCloseMenu = (e, action) => {
                                closeMenu();

                                return handleClick(e, action);
                            };

                            return wrapedActions.reduce<ReactNode[]>((result, action, index) => {
                                if (result.length > 0 && action.type === 'delete') {
                                    result.push(
                                        <Divider
                                            key={'divider' + index}
                                            sx={{ marginTop: 1, marginBottom: 1 }}
                                        />,
                                    );
                                }

                                return result.concat(
                                    renderAction({
                                        action,
                                        extraData,
                                        iconOnlyIfInline,
                                        onClick: handleClickAndCloseMenu,
                                    }),
                                );
                            }, []);
                        }}
                    >
                        {renderMenuButton}
                    </ContextMenu>
                )}
            </Stack>
        </ActionMenuContext.Provider>
    );
};

export default ActionMenu;
