import {
    type FormControlLabelProps,
    styled,
    ToggleButton as MuiToggleButton,
    type ToggleButtonProps,
} from '@mui/material';

const StyledToggleButton = styled(MuiToggleButton)({
    textTransform: 'none',
});

export type ToggleButtonRadioProps = ToggleButtonProps & {
    label?: FormControlLabelProps['label'];
};

const ToggleButtonRadio = (props: ToggleButtonRadioProps) => {
    const { label, ...rest } = props;

    return (
        <StyledToggleButton {...rest} role="radio">
            {label}
        </StyledToggleButton>
    );
};

export default ToggleButtonRadio;
