import { noop } from 'lodash-es';
import { type ReactNode } from 'react';

import { type UploadFileConfig, type UploadFilePromiseResponse } from '@@api/hooks/useUploadFile';
import { type ImageMimeType } from '@@constants/ImageMimeTypes';
import useMultiFileUploadHelper from '@@hooks/useMultiFileUploadHelper';

import FileDropArea from './FileDropArea';

const UPLOAD_COMPLETE_TIMEOUT = 500;

export type Props = {
    allowedMimeTypes?: ImageMimeType[];
    children?: ReactNode;
    className?: string;
    multiple?: boolean;
    compact?: boolean;
    hideValidationCriteria?: boolean;
    uploadFile: (file: UploadFileConfig['file'], options) => UploadFilePromiseResponse;
    onUploadSuccess?: (result: any) => void;
    onUploadFailure?: (error: any) => void;
    onUploadComplete?: (results: any) => void;
    useChildrenHeight?: boolean;
};

const FileUploadDropArea = (props: Props) => {
    const {
        allowedMimeTypes,
        children,
        className,
        compact,
        hideValidationCriteria,
        multiple,
        uploadFile,
        onUploadSuccess = noop,
        onUploadFailure = noop,
        onUploadComplete = noop,
        useChildrenHeight,
    } = props;

    const [{ isLoading, totalFiles, filesPending, progress }, uploadFiles] =
        useMultiFileUploadHelper({
            uploadFile,
            waitAfterComplete: UPLOAD_COMPLETE_TIMEOUT,
            onSuccess: (result) => {
                onUploadSuccess(result);
            },
            onFailure: (error) => {
                onUploadFailure(error);
            },
            onComplete: (results) => {
                onUploadComplete(multiple ? results : results[0]);
            },
        });

    return (
        <FileDropArea
            {...{
                allowedMimeTypes,
                children,
                className,
                compact,
                hideValidationCriteria,
                multiple,
                isLoading,
                progress,
            }}
            done={totalFiles - filesPending}
            total={totalFiles}
            onDrop={uploadFiles}
            onUpload={uploadFiles}
            useChildrenHeight={useChildrenHeight}
        />
    );
};

export default FileUploadDropArea;
