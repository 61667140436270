import { styled } from '@mui/material';
import { ellipsis } from 'polished';

import { getFullName } from '@@api/services/auth/transformers';

const CreatorName = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    ...ellipsis('140px'),
}));

type OverviewCreatorProps = {
    value?: {
        firstName?: string;
        lastName?: string;
    };
};

const OverviewCreator = ({ value }: OverviewCreatorProps) => {
    const fullName = getFullName(value);

    return <CreatorName title={fullName}>{fullName}</CreatorName>;
};

export default OverviewCreator;
