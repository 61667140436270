import { styled } from '@mui/material';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import InfoTooltip from '@@components/InfoTooltip';
import { type Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import { BLOCK_TYPES, type ElementAttributes, type EmbedElement } from '@@editor/helpers/Element';
import { getInputPlaceholderStyle } from '@@editor/plugins/serializable/styles';

const StyledCaptionElement = styled('div')<{ $templateElement; $placeholderText }>(
    ({ theme, $templateElement, $placeholderText }) => ({
        // Overwrite default styles applied by 'RichTextEditorBase'
        padding: '0 !important',
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        ...theme.typography.editorSubtitleSmall,
        ...($templateElement &&
            getInputPlaceholderStyle({ theme, placeholderText: $placeholderText })),
    }),
);

const TooltipContent = styled('span')(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
}));

type Props = {
    attributes: ElementAttributes;
    children: ReactNode;
    editor: Editor;
    element: EmbedElement;
};

// Translation keys
// t('image.tooltip.text')
// t('editor.embeddedcontent.tooltip')
const TOOLTIPS = {
    [BLOCK_TYPES.IMAGE]: 'image.tooltip.text',
    [BLOCK_TYPES.EMBEDDED_CONTENT]: 'editor.embeddedcontent.tooltip',
};

const CaptionElement = (props: Props) => {
    const { attributes, children, editor, element } = props;
    const { t } = useTranslation();
    const path = ReactEditor.findPath(editor, element);
    const parentNode = Node.parent(editor, path);

    const tooltipContent = TOOLTIPS[parentNode.type];

    return (
        <>
            {tooltipContent && (
                <TooltipContent>
                    <InfoTooltip title={tooltipContent} hideSpacer />
                </TooltipContent>
            )}

            <StyledCaptionElement
                {...attributes}
                $templateElement={
                    Element.isTemplateElement(parentNode) ||
                    Element.isEmptyEmbedCaptionElement(element)
                }
                $placeholderText={t('image.caption')}
            >
                {children}
            </StyledCaptionElement>
        </>
    );
};

export default CaptionElement;
