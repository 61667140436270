import { createContext, type ReactNode } from 'react';

import { EXCLUDE_CATEGORIES_NOT_REQUIRED } from './constants';

export const TenantsParamsContext = createContext({});

type Props = { children: ReactNode };
export const TenantsParamsProvider = ({ children }: Props) => {
    const params = { ...EXCLUDE_CATEGORIES_NOT_REQUIRED };

    return <TenantsParamsContext.Provider value={params}>{children}</TenantsParamsContext.Provider>;
};
