import { createElement, type FunctionComponent } from 'react';

import { type Editor } from '@@editor/helpers';

const renderLeaf = <T extends AnyObject>(
    editor: Editor,
    leafsConfig: Array<[string, FunctionComponent<T>]>,
) => {
    const { renderLeaf } = editor;

    return (props) => {
        let { renderersUsed = 0 } = props;

        const children = leafsConfig.reduce((children, [mark, Component]) => {
            if (Boolean(props.leaf[mark]) === true) {
                renderersUsed++;

                return createElement<T>(Component, { ...props, editor }, children);
            }

            return children;
        }, props.children);

        return renderLeaf({ ...props, children, renderersUsed });
    };
};

export default renderLeaf;
