import { isEmpty } from 'lodash-es';
import { type Range, Transforms } from 'slate';

import { Editor } from '@@editor/helpers/Editor';

export const withAutoReplace = (editor, options) => {
    const { insertText } = editor;
    const { contentLocale, replacements = [] } = options;

    return Object.assign(editor, {
        insertText: (text) => {
            if (!editor.selection) {
                insertText(text);

                return;
            }

            const selectedLocation = Editor.start(editor, editor.selection);

            const textBeforeStart = { ...selectedLocation, offset: 0 };
            const textBeforeEnd = selectedLocation;
            const getTextBefore = (text) =>
                Editor.string(editor, {
                    anchor: textBeforeStart,
                    focus: textBeforeEnd,
                }) + text;
            let textBefore = getTextBefore(text);

            const didReplace = replacements.some(({ locales, needle, replacement }) => {
                const isActiveForCurrentContentLocale =
                    isEmpty(locales) || locales.includes(contentLocale);

                if (isActiveForCurrentContentLocale) {
                    if (text.length > 1) {
                        // If text is not inserted letter by letter we want to check all of it
                        // And replace every occurence of a needle
                        while (text.includes(needle)) {
                            const replacementText =
                                typeof replacement === 'function'
                                    ? replacement(editor, { locales, needle, textBefore })
                                    : replacement;

                            // eslint-disable-next-line no-param-reassign
                            text = text.replace(needle, replacementText);
                            textBefore = getTextBefore(text);
                        }
                    } else if (textBefore.substr(-needle.length) === needle) {
                        const distance = needle.length - text.length;

                        const replacementText =
                            typeof replacement === 'function'
                                ? replacement(editor, { locales, needle, textBefore })
                                : replacement;

                        const anchor =
                            Editor.before(editor, textBeforeEnd, {
                                distance,
                                unit: 'character',
                            }) ?? textBeforeEnd;

                        const focus = Editor.end(editor, editor.selection);
                        const range: Range = { anchor, focus };

                        Transforms.insertText(editor, replacementText, {
                            at: range,
                        });

                        return true;
                    }
                }

                return false;
            });

            if (!didReplace) {
                insertText(text);
            }
        },
    });
};

export default withAutoReplace;
