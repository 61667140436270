import { type Editor, Element, ReactEditor } from '@@editor/helpers';
import { ELEMENT_TYPES, type InterviewSegmentElement } from '@@editor/helpers/Element';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderElement from '@@editor/plugins/utils/renderElement';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import InterviewSegment from '../components/InterviewSegment';
import { createGenerateLayoutBlock } from '../utils';
import EmbedWrapper from './../../embed/components/EmbedWrapper';
import InterviewModal from './components/InterviewModal';

const TYPE = PLUGIN_NAMES.INTERVIEW;
const NODE_TYPE = ELEMENT_TYPES.INTERVIEW_SEGMENT;

const InterviewSegmentNode = (props) => {
    const { editor, element } = props;

    return (
        <EmbedWrapper
            {...props}
            type={TYPE}
            component={InterviewSegment}
            placeholderText={props.editor.t('editor.plugin.interviewSegment.placeholderText')}
            placeholderIconName={PLUGIN_ICON_NAMES[TYPE]}
            toolbarConfig={{
                infos: {
                    iconName: PLUGIN_ICON_NAMES[TYPE],
                    title: 'Interview',
                },
                actions: [
                    'delete',
                    {
                        iconName: 'pen-regular',
                        onClick: () =>
                            requestAnimationFrame(() => editor.showInterviewModal(element)),
                    },
                ],
            }}
        />
    );
};

const generateLayoutBlock = createGenerateLayoutBlock({
    type: TYPE,
    nodeType: NODE_TYPE,
});

export const withInterviewSegment = (editor: Editor, options) => {
    const { isVoid, useInlineEditing } = editor;

    return Object.assign(editor, {
        isVoid: (element: Element) => {
            if (useInlineEditing && Element.isInterviewSegmentRelatedElement(element)) {
                return false;
            }

            return element.type === NODE_TYPE ? true : isVoid(element);
        },
        renderEditor: renderEditor(
            editor,
            (props) => (
                <InterviewModal
                    {...props}
                    formProps={{ editor, formName: 'LayoutInterviewSegment' }}
                    generateEmbedBlock={generateLayoutBlock}
                />
            ),
            options,
        ),
        renderElement: renderElement(editor, [[NODE_TYPE, InterviewSegmentNode]], options),
        showInterviewModal: (
            element?: InterviewSegmentElement,
            isEditMode = Boolean(element),
            at = editor.selection && editor.selection.focus,
        ) => {
            editor.setData({
                isInterviewModalVisible: {
                    element,
                    at,
                    isEditMode,
                },
            });
        },
        hideInterviewModal: () => {
            editor.setData({
                isInterviewModalVisible: false,
            });

            requestAnimationFrame(() => {
                ReactEditor.focus(editor);
            });
        },
        isInterviewModalVisible: () => editor.getDataIn(['isInterviewModalVisible']),
    });
};

export default withInterviewSegment;
