import {
    Button,
    type ButtonProps,
    CircularProgress,
    IconButton,
    type IconButtonProps,
    styled,
} from '@mui/material';
import { type FC, type MouseEvent, type MouseEventHandler } from 'react';
// eslint-disable-next-line no-restricted-imports
import { type FieldValues, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DefaultSubmitButton, {
    type Props as DefaultSubmitButtonProps,
} from '@@components/buttonImplementations/SubmitButton';
import FloatingBox from '@@components/FloatingBox';
import Icon from '@@components/Icon';
import useModal, { type UseModalResult } from '@@containers/Modal/useModal/useModal';
import { useFormPersistContext } from '@@containers/ReactHookForm/useFormPersist';

import { useButtonsContext } from './ButtonsContext';
import ButtonGroup from '../ButtonGroup';

const FormSubmitButton = styled(DefaultSubmitButton)({});

const FormCancelButton = styled(Button)({});

const FormDeleteButton = styled(Button)(({ theme }) => ({
    marginRight: 'auto',
    '&:hover, &:focus': {
        color: theme.palette.error.main,
    },
    '&:active': {
        color: theme.palette.error.dark,
    },
}));

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
const LegacyDeleteButton = (props: ButtonProps = {}) => {
    const { handleClickOnDeleteButton } = useButtonsContext();
    const { t } = useTranslation();

    return (
        <FormDeleteButton onClick={handleClickOnDeleteButton} variant="text" {...props}>
            {props.children || t('form.deleteButton')}
        </FormDeleteButton>
    );
};

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
const LegacyCloseButton = (props: IconButtonProps = {}) => {
    const { handleClickOnCloseButton } = useButtonsContext();
    const { t } = useTranslation();

    return (
        <FloatingBox anchor={null} spacing={3}>
            <IconButton
                title={t('form.closeButton.tooltip')}
                onClick={handleClickOnCloseButton}
                {...props}
            >
                <Icon name="xmark" />
            </IconButton>
        </FloatingBox>
    );
};

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
type LegacyCancelButtonProps = ButtonProps & { iconOnly?: boolean; iconName?: string };

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
const LegacyCancelButton = ({
    iconOnly,
    children,
    iconName,
    ...props
}: LegacyCancelButtonProps = {}) => {
    const { handleClickOnResetButton, pristine, hasActiveCancelButton } = useButtonsContext();
    const { t } = useTranslation();
    const text = children || t('form.cancelButton');

    if (iconOnly) {
        return (
            <IconButton
                disabled={hasActiveCancelButton ? false : pristine}
                onClick={handleClickOnResetButton}
                {...props}
                type="reset"
            >
                <Icon name={iconName ?? 'circle-xmark-regular'} size="large" />
            </IconButton>
        );
    }

    return (
        <FormCancelButton
            startIcon={iconName && <Icon name={iconName} />}
            disabled={hasActiveCancelButton ? false : pristine}
            onClick={handleClickOnResetButton}
            variant="outlined"
            {...props}
            type="reset"
        >
            {text}
        </FormCancelButton>
    );
};

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
type LegacySubmitButtonProps = DefaultSubmitButtonProps & { iconOnly?: boolean };

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
const LegacySubmitButton = ({
    children,
    iconOnly,
    iconName,
    ...props
}: LegacySubmitButtonProps = {}) => {
    const { handleClickOnSubmitButton, pristine, submitting } = useButtonsContext();
    const { t } = useTranslation();
    const text = children || t('form.submitButton');

    if (iconOnly) {
        return (
            <IconButton
                color="success"
                onClick={handleClickOnSubmitButton}
                disabled={pristine || submitting}
                {...props}
                type="submit"
            >
                {submitting ? (
                    <CircularProgress color="inherit" size={24} />
                ) : (
                    <Icon name={iconName ?? 'circle-check-sharp'} size="large" />
                )}
            </IconButton>
        );
    }

    return (
        <FormSubmitButton
            onClick={handleClickOnSubmitButton}
            disabled={pristine || submitting}
            loading={submitting}
            iconName={iconName}
            {...props}
        >
            {text}
        </FormSubmitButton>
    );
};

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
const Buttons = {
    SubmitButton: LegacySubmitButton,
    CancelButton: LegacyCancelButton,
    CloseButton: LegacyCloseButton,
    DeleteButton: LegacyDeleteButton,
};

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
export const ButtonPreset = () => {
    const {
        hasButtonGroup,
        hasDeleteButton,
        isEditMode,
        alwaysShowCancelButton,
        hasCancelButton,
        dirty,
        hasCloseButton,
        ...props
    } = useButtonsContext();

    const renderDeleteButton = () =>
        props.renderDeleteButton ? props.renderDeleteButton(Buttons) : <LegacyDeleteButton />;

    const renderCancelButton = () =>
        props.renderCancelButton ? props.renderCancelButton(Buttons) : <LegacyCancelButton />;

    const renderSubmitButton = () =>
        props.renderSubmitButton ? props.renderSubmitButton(Buttons) : <LegacySubmitButton />;

    return hasButtonGroup ? (
        <ButtonGroup>
            {hasDeleteButton && isEditMode ? renderDeleteButton() : null}

            {hasCancelButton && (alwaysShowCancelButton || dirty) ? renderCancelButton() : null}

            {renderSubmitButton()}

            {hasCloseButton && <LegacyCloseButton />}
        </ButtonGroup>
    ) : null;
};

/**
 * @deprecated Remove when all forms are migrated to Zod
 */
export type ButtonsType = {
    DeleteButton: typeof LegacyDeleteButton;
    CancelButton: typeof LegacyCancelButton;
    SubmitButton: typeof LegacySubmitButton;
    CloseButton: typeof LegacyCloseButton;
};

export default {
    ...Buttons,
    ButtonPreset: () => <ButtonPreset />,
};

export type DeleteButtonProps = ButtonProps & {
    deleteModal?: UseModalResult;
};

export const DeleteButton: FC<DeleteButtonProps> = (props) => {
    const { children, deleteModal, onClick, ...rest } = props;

    const { t } = useTranslation();

    const defaultDeleteModal = useModal({
        type: 'delete',
        title: t('form.modal.deleteTitle'),
        children: <p>{t('form.modal.deleteMessage')}</p>,
    });

    const confirmationModal = deleteModal || defaultDeleteModal;
    const handleClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
        try {
            await confirmationModal.delete();

            onClick?.(e);
        } catch (error) {
            // Do nothing
        }
    };

    return (
        <FormDeleteButton onClick={handleClick} variant="text" {...rest}>
            {children || t('form.deleteButton')}
        </FormDeleteButton>
    );
};

export type CloseButtonProps = IconButtonProps & {
    cancelModal?: UseModalResult;
};

export const CloseButton = <TFieldValues extends FieldValues = FieldValues>(
    props: CloseButtonProps,
) => {
    const { cancelModal, onClick } = props;

    const { control, reset } = useFormContext<TFieldValues>();
    const { clearPersistedForm } = useFormPersistContext();

    const { isDirty, isSubmitting } = useFormState<TFieldValues>({
        control,
    });

    const { t } = useTranslation();

    const defaultCancelModal = useModal({
        type: 'confirm',
        title: t('form.modal.cancelTitle'),
        children: <p>{t('form.modal.cancelMessage')}</p>,
    });

    const confirmationModal = cancelModal || defaultCancelModal;

    const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
        try {
            if (isDirty || isSubmitting) {
                await confirmationModal.confirm();
            }

            reset();

            onClick?.(e);

            clearPersistedForm();
        } catch (error) {
            // Do nothing
        }
    };

    return (
        <FloatingBox anchor={null} spacing={3}>
            <IconButton title={t('form.closeButton.tooltip')} {...props} onClick={handleClick}>
                <Icon name="xmark" />
            </IconButton>
        </FloatingBox>
    );
};

export type CancelButtonProps = ButtonProps & {
    cancelModal?: UseModalResult;
    iconOnly?: boolean;
    iconName?: string;
    isAlwaysActive?: boolean;
    isAlwaysVisible?: boolean;
};
export const CancelButton = <TFieldValues extends FieldValues = FieldValues>({
    cancelModal,
    onClick,
    isAlwaysActive,
    isAlwaysVisible,
    iconOnly,
    children,
    iconName,
    ...props
}: CancelButtonProps = {}) => {
    const { t } = useTranslation();
    const text = children || t('form.cancelButton');

    const { control, reset } = useFormContext<TFieldValues>();
    const { clearPersistedForm } = useFormPersistContext();

    const { isDirty, isSubmitting } = useFormState<TFieldValues>({
        control,
    });

    const defaultCancelModal = useModal({
        type: 'confirm',
        title: t('form.modal.cancelTitle'),
        children: <p>{t('form.modal.cancelMessage')}</p>,
    });

    const confirmationModal = cancelModal || defaultCancelModal;
    const isDisabled = isAlwaysActive ? false : !isDirty;

    const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
        try {
            if (isDirty || isSubmitting) {
                await confirmationModal.confirm();
            }

            reset();

            onClick?.(e);

            clearPersistedForm();
        } catch (error) {
            // Do nothing
        }
    };

    if (!isDirty && !isAlwaysVisible) {
        return null;
    }

    if (iconOnly) {
        return (
            <IconButton disabled={isDisabled} onClick={handleClick} {...props} type="reset">
                <Icon name={iconName ?? 'circle-xmark-regular'} size="large" />
            </IconButton>
        );
    }

    return (
        <FormCancelButton
            startIcon={iconName && <Icon name={iconName} />}
            disabled={isDisabled}
            onClick={handleClick}
            variant="outlined"
            {...props}
            type="reset"
        >
            {text}
        </FormCancelButton>
    );
};

type IconSubmitButtonProps = {
    iconOnly: true;
} & IconButtonProps;

type NormalSubmitButtonProps = {
    iconOnly?: false;
} & DefaultSubmitButtonProps;

type SubmitButtonProps = {
    iconName?: string;
    isLoading?: boolean;
} & (IconSubmitButtonProps | NormalSubmitButtonProps);

export const SubmitButton = <TFieldValues extends FieldValues = FieldValues>({
    children,
    iconName,
    iconOnly,
    isLoading,
    onClick,
    form,
}: SubmitButtonProps) => {
    const { control } = useFormContext<TFieldValues>();
    const { isDirty, isSubmitting } = useFormState<TFieldValues>({
        control,
    });

    const { t } = useTranslation();
    const text = children || t('form.submitButton');

    if (iconOnly) {
        return (
            <IconButton
                color="success"
                onClick={onClick}
                disabled={!isDirty || isSubmitting}
                form={form}
                type="submit"
            >
                {isSubmitting ? (
                    <CircularProgress color="inherit" size={24} />
                ) : (
                    <Icon name={iconName ?? 'circle-check-sharp'} size="large" />
                )}
            </IconButton>
        );
    }

    return (
        <FormSubmitButton
            onClick={onClick}
            disabled={!isDirty || isSubmitting}
            loading={isSubmitting || isLoading}
            iconName={iconName}
            form={form}
        >
            {text}
        </FormSubmitButton>
    );
};
