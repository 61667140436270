import validator from 'validator';

import secureUrl from './secureUrl';

type Options = {
    allowInsecure?: boolean;
    allowMailto?: boolean;
};

const validateinsecureOrMailto = (value: string) => {
    if (
        !validator.isURL(value || '', {
            // eslint-disable-next-line camelcase
            require_protocol: true,
            protocols: ['http', 'https'],
        }) &&
        value.indexOf('mailto:') !== 0
    ) {
        // t('validator.message.validUrlOrMailto');
        return ['validator.message.validUrlOrMailto'];
    }
};

const validateInsecure = (value: string) => {
    if (
        !validator.isURL(value || '', {
            // eslint-disable-next-line camelcase
            require_protocol: true,
            protocols: ['http', 'https'],
        })
    ) {
        // t('validator.message.insecureUrl');
        return ['validator.message.insecureUrl'];
    }
};

const validateMailto = (value: string) => {
    if (value.indexOf('mailto:') !== 0 && secureUrl(value)) {
        // t('validator.message.secureUrlOrMailto');
        return ['validator.message.secureUrlOrMailto'];
    }
};

const isUrl = (value: string, options: Options) => {
    if (options.allowInsecure && options.allowMailto) {
        return validateinsecureOrMailto(value);
    } else if (options.allowInsecure) {
        return validateInsecure(value);
    } else if (options.allowMailto) {
        return validateMailto(value);
    }

    return secureUrl(value);
};

const getIsUrl =
    ({ allowInsecure, allowMailto }: Options) =>
    (value: string) =>
        isUrl(value, { allowInsecure, allowMailto });

export default getIsUrl;
