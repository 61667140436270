import { styled } from '@mui/material';
import { type ReactNode } from 'react';
import { type Range, Text } from 'slate';

import { NARROW_NO_BREAK_SPACE, NO_BREAK_SPACE } from '@@constants/Unicode';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';

const SYMBOL_NAME = 'noBreakSpace';

type NoBreakSpaceRange = Range & { [SYMBOL_NAME]: boolean };

const StyledNoBreakSpace = styled('strong')(({ theme }) => ({
    position: 'relative',
    '&:before': {
        display: 'inline-flex',
        content: '"·"',
        position: 'absolute',
        inset: '0',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.light,
    },
}));

type Props = {
    attributes: Record<string, unknown>;
    children: ReactNode;
    leaf: {
        text: string;
    };
};

const NoBreakSpace = (props: Props) => (
    <StyledNoBreakSpace {...props.attributes}>{props.children}</StyledNoBreakSpace>
);

export const withNoBreakSpace = (editor) => {
    const { decorate } = editor;

    return Object.assign(editor, {
        // This inspired by the following slate example:
        // https://github.com/ianstormtaylor/slate/blob/master/site/examples/search-highlighting.js
        // If performance is not good we need to change to an inline elmeent. Something like:
        // https://github.com/DND-IT/cms-frontend/pull/2202
        decorate: (nodeEntry) => {
            const [node, path] = nodeEntry;
            const ranges: NoBreakSpaceRange[] = [];

            if (Text.isText(node)) {
                const { text } = node;
                const parts = text.split(new RegExp(`${NO_BREAK_SPACE}|${NARROW_NO_BREAK_SPACE}`));
                let offset = 0;

                parts.forEach((part, i) => {
                    if (i !== 0) {
                        ranges.push({
                            anchor: { path, offset: offset - 1 },
                            focus: { path, offset },
                            [SYMBOL_NAME]: true,
                        });
                    }

                    offset = offset + part.length + 1;
                });
            }

            return [...ranges, ...decorate(nodeEntry)];
        },
        renderLeaf: renderLeaf(editor, [[SYMBOL_NAME, NoBreakSpace]]),
    });
};

export default withNoBreakSpace;
