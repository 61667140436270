import { noop } from 'lodash-es';
import { type RefObject, useState } from 'react';
import { type ConnectDropTarget, type DropTargetMonitor, useDrop, type XYCoord } from 'react-dnd';
import invariant from 'tiny-invariant';

const QUARTER = 0.25;
const HALF = 0.5;

export const DropItemType = {
    ITEM: 'item',
    SPACER: 'spacer',
} as const;

export type DropItemType = ValueOf<typeof DropItemType>;

export const DropMode = {
    INSERT_AFTER: 'after',
    REPLACE: 'replace',
    INSERT_BEFORE: 'before',
} as const;

export type DropMode = ValueOf<typeof DropMode>;

export type DragObjectBase = { dragContextId?: string; index: number };

export const calculateDropMode = (
    dragPosition: XYCoord | null | undefined,
    dropAreaRect: DOMRect | undefined,
    supportedDropModes: DropMode[],
): DropMode | null => {
    if (dragPosition != null && dropAreaRect != null) {
        const isBeforeAndAfterSupported =
            supportedDropModes.includes(DropMode.INSERT_BEFORE) &&
            supportedDropModes.includes(DropMode.INSERT_AFTER);

        const isReplaceSupported = supportedDropModes.includes(DropMode.REPLACE);

        let beforeAndAfterRatio = 0;

        if (isBeforeAndAfterSupported) {
            beforeAndAfterRatio = isReplaceSupported ? QUARTER : HALF;
        }

        if (isBeforeAndAfterSupported) {
            const isDraggingOverUpperArea =
                dragPosition.y < dropAreaRect.top + dropAreaRect.height * beforeAndAfterRatio;

            const isDraggingOverLowerArea =
                dragPosition.y > dropAreaRect.bottom - dropAreaRect.height * beforeAndAfterRatio;

            if (isDraggingOverUpperArea) {
                return DropMode.INSERT_BEFORE;
            } else if (isDraggingOverLowerArea) {
                return DropMode.INSERT_AFTER;
            }
        }

        if (isReplaceSupported) {
            const isDraggingOverMiddleArea =
                dragPosition.y >= dropAreaRect.top + dropAreaRect.height * beforeAndAfterRatio &&
                dragPosition.y <= dropAreaRect.bottom - dropAreaRect.height * beforeAndAfterRatio;

            if (isDraggingOverMiddleArea) {
                return DropMode.REPLACE;
            }
        }
    }

    return null;
};

export const calculateDropToIndex = (
    dragItem: DragObjectBase,
    dropItem: DragObjectBase,
    dropMode: DropMode | null,
    type: DropItemType,
) => {
    const dropToIndex = dropItem.index;

    invariant(
        !(type === DropItemType.SPACER && dropMode === DropMode.REPLACE),
        'Spacers do not support replace actions.',
    );

    if (type === DropItemType.SPACER) {
        if (dragItem.dragContextId === dropItem.dragContextId) {
            if (dragItem.index < dropItem.index) {
                // Move from top to bottom
                return dropToIndex;
            } else if (dragItem.index > dropItem.index) {
                // Move from bottom to top
                return dropToIndex + 1;
            }
        } else {
            return dropToIndex + 1;
        }
    } else if (dragItem.dragContextId === dropItem.dragContextId) {
        if (dragItem.index < dropItem.index) {
            // Move from top to bottom
            if (dropMode === DropMode.INSERT_BEFORE) {
                return dropToIndex - 1;
            }
        } else if (dragItem.index > dropItem.index) {
            // Move from bottom to top
            if (dropMode === DropMode.INSERT_AFTER) {
                return dropToIndex + 1;
            }
        }
    } else {
        if (dropMode === DropMode.INSERT_AFTER) {
            return dropToIndex + 1;
        }
    }

    return dropToIndex;
};

export const canDropOnSpacer = (dragItem: DragObjectBase, dropItem: DragObjectBase): boolean =>
    !(dropItem.index === dragItem.index || dropItem.index === dragItem.index - 1);

export const canDrop = (
    dragItem: DragObjectBase,
    dropItem: DragObjectBase,
    dropMode: DropMode | null,
    type: DropItemType,
): boolean => {
    if (!dropMode) {
        return false;
    }

    if (dragItem.dragContextId !== dropItem.dragContextId) {
        return true;
    }

    if (type === DropItemType.SPACER) {
        return canDropOnSpacer(dragItem, dropItem);
    }

    const shouldDropAfter = dropMode === DropMode.INSERT_AFTER;
    const isDropTargetPreviousSibling = dropItem.index === dragItem.index - 1;
    const shouldDropBefore = dropMode === DropMode.INSERT_BEFORE;
    const isDropTargetNextSibling = dropItem.index === dragItem.index + 1;

    // Prohibit dropping dropItem immediately after/before dragItem
    if (
        (shouldDropAfter && isDropTargetPreviousSibling) ||
        (shouldDropBefore && isDropTargetNextSibling)
    ) {
        return false;
    }

    // Prohibit dropping dropItem on dragItem
    return dragItem.index !== dropItem.index;
};

type Props<DragObject extends DragObjectBase> = {
    accept?: string[];
    canDrop?: (
        dragItem: DragObject,
        dropItem: DragObject,
        monitor: DropTargetMonitor,
        type: DropItemType,
    ) => boolean;
    item: DragObject;
    ref: RefObject<HTMLElement>;
    supportedDropModes?: DropMode[];
    type?: DropItemType;
    onDrop?: (dragItem: DragObject, dropItem: DragObject, monitor: DropTargetMonitor) => void;
};

export const useDropItem = <DragObject extends DragObjectBase>({
    accept = [],
    canDrop: externalCanDrop = () => true,
    item: dropItem,
    ref,
    supportedDropModes = [DropMode.INSERT_BEFORE, DropMode.INSERT_AFTER],
    type = DropItemType.ITEM,
    onDrop = noop,
}: Props<DragObject>): [{ dropMode: DropMode | null }, ConnectDropTarget] => {
    const [dropMode, setDropMode] = useState<DropMode | null>(null);

    invariant(
        !(type === DropItemType.SPACER && dropMode === DropMode.REPLACE),
        'Spacers do not support replace actions.',
    );

    const [{ isOver }, drop] = useDrop<
        DragObject,
        unknown,
        {
            isOver: boolean;
        }
    >({
        accept,
        canDrop(dragItem, monitor) {
            return (
                canDrop(dragItem, dropItem, dropMode, type) &&
                externalCanDrop(dragItem, dropItem, monitor, type)
            );
        },
        hover(dragItem, monitor) {
            const nextDropMode = calculateDropMode(
                monitor.getClientOffset(),
                ref.current?.getBoundingClientRect(),
                supportedDropModes,
            );

            setDropMode(
                // You will receive hover() even for items for which canDrop() is false
                canDrop(dragItem, dropItem, nextDropMode, type) &&
                    externalCanDrop(dragItem, dropItem, monitor, type)
                    ? nextDropMode
                    : null,
            );
        },
        drop(dragItem, monitor) {
            // If this drop item operates with only INSERT_BEFORE and/or INSERT_AFTER, we will translate the index
            // of the drop item to it's final position (this brings the benefit of not being dependent on the drop
            // mode, when calculating movements). This is not possible as soon as we start to operate with REPLACE,
            // therefore we will not transform the final index in this case.
            const dropToIndex = supportedDropModes.includes(DropMode.REPLACE)
                ? dropItem.index
                : calculateDropToIndex(dragItem, dropItem, dropMode, type);

            onDrop(dragItem, { ...dropItem, index: dropToIndex }, monitor);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    return [{ dropMode: isOver ? dropMode : null }, drop];
};

export default useDropItem;
