import { z } from 'zod';

import {
    NavigationLayoutTypes,
    SectionButtonTypes as SectionButtonTypesConst,
    SectionLayouts as SectionLayoutsConst,
    SectionTypes as SectionTypesConst,
} from '@@routes/placement/constants';

import { Entity } from '../../utils/schemas/schemas';

export const Preset = Entity.extend({
    id: z.string().uuid(),
    presetName: z.string(),
    items: z.array(z.string()),
});

export type Preset = z.infer<typeof Preset>;

export const Bookmark = Entity.extend({
    id: z.string().uuid().optional(),
    metadataId: z.number(),
    relatedResourceId: z.string(),
    relatedResourceName: z.string(),
});

export type Bookmark = z.infer<typeof Bookmark>;

const SectionType = z.nativeEnum(SectionTypesConst);

export type SectionType = z.infer<typeof SectionType>;

export const SectionLayout = z.object({
    name: z.string(),
    tenantIds: z.array(z.number()),
});

export type SectionLayout = z.infer<typeof SectionLayout>;

export const SectionLayouts = z.nativeEnum(SectionLayoutsConst);

export type SectionLayouts = z.infer<typeof SectionLayouts>;

const BaseSection = Entity.extend({
    id: z.number(),
    type: SectionType,

    /**
     * @deprecated Remove when BE removed
     */
    tenantId: z.number().nullish(),

    /**
     * @deprecated Remove when BE removed
     */
    selfCategoryId: z.number().nullish(),
    title: z.string(),
    showTitle: z.boolean(),
    isSectionVisible: z.boolean(),
});

const SectionButtonBase = z.object({
    text: z.string(),
});

type SectionButtonBase = z.infer<typeof SectionButtonBase>;

const SectionCategoryButton = SectionButtonBase.extend({
    type: z.literal(SectionButtonTypesConst.CATEGORY),
    categoryId: z.number(),
});

const SectionTagButton = SectionButtonBase.extend({
    type: z.literal(SectionButtonTypesConst.TAG),
    tagId: z.number(),
});

const SectionInternalButton = SectionButtonBase.extend({
    type: z.literal(SectionButtonTypesConst.INTERNAL),
    metadataId: z.number(),
});

const SectionExternalButton = SectionButtonBase.extend({
    type: z.literal(SectionButtonTypesConst.EXTERNAL),
    url: z.string(),
});

const SectionTextItemButton = SectionButtonBase.extend({
    type: z.literal(SectionButtonTypesConst.TEXT_ITEM),
    text: z.string(),
});

const SectionButtonType = z.nativeEnum(SectionButtonTypesConst);

export type SectionButtonType = z.infer<typeof SectionButtonType>;

export type SectionCategoryButton = z.infer<typeof SectionCategoryButton>;
export type SectionTagButton = z.infer<typeof SectionTagButton>;
export type SectionInternalButton = z.infer<typeof SectionInternalButton>;
export type SectionExternalButton = z.infer<typeof SectionExternalButton>;
export type SectionTextItemButton = z.infer<typeof SectionTextItemButton>;

const SectionButton = z.union([
    SectionCategoryButton,
    SectionTagButton,
    SectionInternalButton,
    SectionExternalButton,
    SectionTextItemButton,
]);

export type SectionButton = z.infer<typeof SectionButton>;

const DefaultSectionBase = BaseSection.extend({
    layout: SectionLayouts,
    teaserCount: z.number(),
    isInfiniteScroll: z.boolean().nullable(),
    adsPositions: z
        .object({
            desktop: z.array(z.number()).nullable(),
        })
        .nullish(),
    isButtonVisible: z.boolean(),
    button: SectionButton.nullable(),
});

export const DefaultCategorySection = DefaultSectionBase.extend({
    type: z.literal(SectionTypesConst.DEFAULT_CATEGORY),
});

export type DefaultCategorySection = z.infer<typeof DefaultCategorySection>;
const NewDefaultCategorySection = DefaultCategorySection.omit({ id: true });

type NewDefaultCategorySection = z.infer<typeof NewDefaultCategorySection>;

export const DefaultTagSection = DefaultSectionBase.extend({
    type: z.literal(SectionTypesConst.DEFAULT_TAG),
});

export type DefaultTagSection = z.infer<typeof DefaultTagSection>;
const NewDefaultTagSection = DefaultTagSection.omit({ id: true });

type NewDefaultTagSection = z.infer<typeof NewDefaultTagSection>;

export const CategorySection = DefaultSectionBase.omit({ isInfiniteScroll: true }).extend({
    type: z.literal(SectionTypesConst.CATEGORY),
    pointerCategoryId: z.number(),
    pointerSectionId: z.number().nullish(),
});

export type CategorySection = z.infer<typeof CategorySection>;
const NewCategorySection = CategorySection.omit({ id: true });

type NewCategorySection = z.infer<typeof NewCategorySection>;

export const TagSection = DefaultSectionBase.omit({ isInfiniteScroll: true }).extend({
    type: z.literal(SectionTypesConst.TAG),
    pointerTagId: z.number(),
    pointerSectionId: z.number().nullish(),
});

export type TagSection = z.infer<typeof TagSection>;
const NewTagSection = TagSection.omit({ id: true });

type NewTagSection = z.infer<typeof NewTagSection>;

export const CustomSection = BaseSection.extend({
    type: z.literal(SectionTypesConst.CUSTOM),
    customTypeIdentifier: z.string(),
});

export type CustomSection = z.infer<typeof CustomSection>;

const NewCustomSection = CustomSection.omit({ id: true });

type NewCustomSection = z.infer<typeof NewCustomSection>;

export const UserCategorySelectionSection = DefaultSectionBase.omit({
    isInfiniteScroll: true,
}).extend({
    type: z.literal(SectionTypesConst.USER_CATEGORY_SELECTION),
    selectableCategoryIds: z.array(z.number()),
    defaultCategoryId: z.number(),
    navigationLayout: z.union([
        z.literal(NavigationLayoutTypes.DROPDOWN),
        z.literal(NavigationLayoutTypes.LINK),
    ]),
    subtitle: z.string(),
    pointerSectionId: z.number().nullish(),
});

export type UserCategorySelectionSection = z.infer<typeof UserCategorySelectionSection>;

const NewUserCategorySelectionSection = UserCategorySelectionSection.omit({ id: true });

type NewUserCategorySelectionSection = z.infer<typeof NewUserCategorySelectionSection>;

export const NoLogicSection = DefaultSectionBase.omit({
    isInfiniteScroll: true,
}).extend({
    type: z.literal(SectionTypesConst.NO_LOGIC),
});

export type NoLogicSection = z.infer<typeof NoLogicSection>;

const NewNoLogicSection = NoLogicSection.omit({ id: true });

type NewNoLogicSection = z.infer<typeof NewNoLogicSection>;

export const Section = z.union([
    DefaultCategorySection,
    DefaultTagSection,
    CategorySection,
    CustomSection,
    TagSection,
    UserCategorySelectionSection,
    NoLogicSection,
]);

export const NewSection = z.union([
    NewDefaultCategorySection,
    NewDefaultTagSection,
    NewCategorySection,
    NewCustomSection,
    NewTagSection,
    NewUserCategorySelectionSection,
    NewNoLogicSection,
]);

export type NewSection = z.infer<typeof NewSection>;

export type Section = z.infer<typeof Section>;
export type UnitySection = z.infer<typeof Section>;
export type NewUnitySection = z.infer<typeof NewSection>;

export const isDefaultCategorySection = (
    section?: Section | Pick<Section, 'type'>,
): section is DefaultCategorySection => section?.type === SectionTypesConst.DEFAULT_CATEGORY;

export const isDefaultTagSection = (
    section?: Section | Pick<Section, 'type'>,
): section is DefaultTagSection => section?.type === SectionTypesConst.DEFAULT_TAG;

export const isCategorySection = (section?: Section): section is CategorySection =>
    section?.type === SectionTypesConst.CATEGORY;

export const isTagSection = (section?: Section): section is TagSection =>
    section?.type === SectionTypesConst.TAG;

export const isCustomSection = (section?: Section): section is CustomSection =>
    section?.type === SectionTypesConst.CUSTOM;

export const isNoLogicSection = (section?: Section): section is NoLogicSection =>
    section?.type === SectionTypesConst.NO_LOGIC;

const CategoryPageSection = z.union([
    DefaultCategorySection,
    CategorySection,
    CustomSection,
    TagSection,
    UserCategorySelectionSection,
    NoLogicSection,
]);

export type CategoryPageSection = z.infer<typeof CategoryPageSection>;

export const CategoryPage = z.object({
    id: z.number(),
    categoryId: z.number(),
    tenantId: z.number(),
    sections: z.array(CategoryPageSection),
    draft: z.boolean(),
});
export type CategoryPage = z.infer<typeof CategoryPage>;

export const TagPage = z.object({
    id: z.number(),
    tagId: z.number(),
    tenantId: z.number(),
    sections: z.tuple([DefaultTagSection]),
    draft: z.boolean(),
});
export type TagPage = z.infer<typeof TagPage>;

const SectionContentItem = z.object({
    metadataId: z.number().nullish(),
    sectionContentItemType: z.string(),
    placeholderTenantId: z.number().nullish(),
    placeholderCategoryId: z.number().nullish(),
    placeholderIndexPosition: z.number().nullish(),
    publishedSince: z.string().optional(),
    pinned: z.boolean().optional(),
    deletedDate: z.string().optional(),
    unpublishedDate: z.string().optional(),
    placeholderType: z.string().nullish(),
    placeholderTitle: z.string().nullish(),
    placeholderIdentifier: z.string().nullish(),
});

export type SectionContentItem = z.infer<typeof SectionContentItem>;

export const SectionContent = z.object({
    sectionId: z.number(),

    /**
     * @deprecated Remove when BE removed
     */
    tenantId: z.number().nullish(),

    /**
     * @deprecated Remove when BE removed
     */
    categoryId: z.number().nullish(),
    content: z.array(SectionContentItem),
    updatedAt: z.string(),
    lastUpdatedBy: z.string().nullable(),
    draft: z.boolean(),
    previewUrl: z.string().nullable(),
});

export type SectionContent = z.infer<typeof SectionContent>;
