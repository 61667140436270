import { type PropsWithChildren, useEffect } from 'react';
import { Transforms } from 'slate';

import { type Editor } from '@@editor/helpers';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import { PLUGIN_NAMES, type PluginOptions } from '@@editor/typings/UnityPlugins';

type EditorWrapperProps = PropsWithChildren<{
    editor: Editor;
    options: PluginOptions;
}>;

const applyProposal = (editor, proposal) => {
    Transforms.insertText(editor, proposal.titleHeader, { at: [0] });
    Transforms.insertText(editor, proposal.title, { at: [1] });
    Transforms.insertText(editor, proposal.lead, { at: [2] });
};

const EditorWrapper = ({ children, editor }: EditorWrapperProps) => {
    const data = editor.getDataIn([PLUGIN_NAMES.HEADINGS_TEASERS]);

    useEffect(() => {
        if (data?.proposal) {
            applyProposal(editor, data.proposal);
        }
    }, [data]);

    return <>{children}</>;
};

const withHeadingsTeasers = (editor: Editor, options: PluginOptions) => {
    editor.setEditorContextData({ [PLUGIN_NAMES.HEADINGS_TEASERS]: { proposal: undefined } });

    return Object.assign(editor, {
        renderEditor: renderEditor(editor, ({ children }) => (
            <EditorWrapper editor={editor} options={options}>
                {children}
            </EditorWrapper>
        )),
    });
};

export default withHeadingsTeasers;
