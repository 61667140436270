import { createContext } from 'react';

import { type Metadata } from '@@api/services/metadata/schemas';

// Because of the way our Entities types are made, this
// metadataId type must allow undefined
type MetadataContextValue = {
    metadataId?: null | Metadata['id'];
    resourceId: string;
    resourceName: string;
};

const MetadataContext = createContext<MetadataContextValue>({
    metadataId: null,
    resourceName: '',
    resourceId: '',
});

export default MetadataContext;
