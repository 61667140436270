import { type ReactNode } from 'react';

import Modal from '@@containers/Modal/ModalWithLeavePrompt';
import { type UseReactHookFormProps } from '@@containers/ReactHookForm/types';
import { Editor } from '@@editor/helpers';
import { type LinkData } from '@@editor/helpers/Editor';
import {
    type ExternalLinkElement,
    type InternalLinkElement,
    type LinkElement,
} from '@@editor/helpers/Element';

import Form from './Form';

type Props = {
    children: ReactNode;
    editor: Editor;
};

const LinkModal = (props: Props) => {
    const { children, editor } = props;
    const { t } = editor;

    const slateModalState = editor.getDataIn(['isLinkModalVisible']);

    const selection = slateModalState?.selection;

    const isModalOpen = Boolean(slateModalState);

    const handleSubmit: UseReactHookFormProps['onSubmit'] = (data: LinkData) => {
        // `insertLink` select inserted link, so we do not need to pass `hideLinkModal` any
        // selection to restore

        editor.insertLink(data, { at: selection });
        editor.hideLinkModal();
    };

    const handleCancel = () => {
        // Restore initial selection by passing `selection` to `hideLinkModal`
        editor.hideLinkModal(true);
    };

    const renderForm = () => {
        if (isModalOpen && selection) {
            const inlineElement = Editor.getFirstInline(editor, { at: selection }) as LinkElement;
            const selectedText = Editor.string(editor, selection);
            const selectedLink =
                (inlineElement && (inlineElement as ExternalLinkElement).data.href) || '';
            const selectedMetadataId =
                (inlineElement && (inlineElement as InternalLinkElement).data.metadataId) || null;

            return (
                <Form
                    selectedText={selectedText}
                    selectedLink={selectedLink}
                    selectedMetadataId={selectedMetadataId}
                    t={t}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                />
            );
        }

        return null;
    };

    return (
        <>
            <Modal
                // We are restoring the focus already within `hideLinkModal`, using a proper slate js editor function
                disableRestoreFocus
                isOpen={isModalOpen}
                title={t('editor.insert.link')}
                onClose={handleCancel}
            >
                {renderForm()}
            </Modal>

            {children}
        </>
    );
};

export default LinkModal;
