import { styled } from '@mui/material';
import { type ReactNode } from 'react';

import { Element, type EmbedElement } from '@@editor/helpers/Element';

const Wrapper = styled('div')<{ $templateElement: boolean }>(({ theme, $templateElement }) => ({
    backgroundColor: $templateElement ? theme.palette.gold.light : theme.palette.primary['50'],
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
    position: 'relative',
}));

type Props = {
    children: ReactNode;
    element: EmbedElement;
};

const EditableElementFooter = (props: Props) => {
    const { children, element } = props;

    const caption = element.children.find(Element.isEmbedCaptionElement);
    const credit = element.children.find(Element.isEmbedCreditElement);

    if (caption || credit) {
        return <Wrapper $templateElement={Element.isTemplateElement(element)}>{children}</Wrapper>;
    }

    return <>{children}</>;
};

export default EditableElementFooter;
