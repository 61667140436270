import {
    Divider,
    styled,
    ToggleButton,
    toggleButtonClasses,
    ToggleButtonGroup,
} from '@mui/material';
import { noop } from 'lodash-es';

import ElementsDropdown from '@@components/ElementsDropdown';
import Icon from '@@components/Icon';
import Spacer from '@@components/Spacer';

import { type ToolbarButtonConfig } from '../typings';

const BUTTON_DIMENSION = 32;
const BUTTON_MARGIN = 3;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    gap: `${BUTTON_MARGIN}px`,
    margin: `${BUTTON_MARGIN}px`,
    [`& .${toggleButtonClasses.root}`]: {
        ...theme.typography.small,
        textTransform: 'none',
        border: '0 !important',
        borderRadius: `${BUTTON_DIMENSION / 2}px !important`,
        margin: '0 !important',
        minWidth: `${BUTTON_DIMENSION}px`,
        height: `${BUTTON_DIMENSION}px`,
    },
}));

const StyledElementsDropdown = styled(ElementsDropdown)({
    margin: `${BUTTON_MARGIN}px`,
});

type ButtonConfigGroup = {
    spacer: boolean;
    configs: ToolbarButtonConfig[];
    selected: string[];
};

const createButtonConfigGroups = (buttonsConfig: ToolbarButtonConfig[]) =>
    buttonsConfig.reduce<ButtonConfigGroup[]>((result, buttonConfig) => {
        if (buttonConfig.hidden) {
            return result;
        }

        if (buttonConfig.id === 'spacer') {
            if (result.length > 0) {
                return result.concat({ spacer: true, configs: [], selected: [] });
            }

            return result;
        }

        if (result.length === 0 || result[result.length - 1].spacer) {
            result.push({ spacer: false, configs: [], selected: [] });
        }

        result[result.length - 1].configs.push(buttonConfig);

        if (buttonConfig.active) {
            result[result.length - 1].selected.push(buttonConfig.id);
        }

        return result;
    }, []);

type Options = {
    disabled?: boolean;
};

export const renderButtons = (buttonsConfig: ToolbarButtonConfig[], options: Options = {}) => {
    const groups = createButtonConfigGroups(buttonsConfig);

    return groups.map((group, index) =>
        group.spacer ? (
            <Divider key={`spacer${index}`} flexItem orientation="vertical" variant="middle" />
        ) : (
            <StyledToggleButtonGroup key={index} size="small" value={group.selected}>
                {group.configs.map((config, index) => {
                    const {
                        id,
                        actions,
                        columnWidth,
                        columns,
                        iconName,
                        invalid,
                        justifyButtons,
                        label,
                        title,
                        onMouseDown,
                    } = config;

                    const disabled = invalid || options.disabled;
                    const text = iconName ? <Icon name={iconName} /> : label;

                    if (actions?.length) {
                        const elements = actions.map((action) => ({
                            label: action.label || '',
                            selected: action.active,
                            title: action.title,
                            iconName: action.iconName,
                            onMouseDown: action.onMouseDown || noop,
                            style: action.style,
                        }));

                        return (
                            <StyledElementsDropdown
                                key={`${id}${index}`}
                                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                autoFocus
                                columns={columns}
                                columnWidth={columnWidth}
                                elements={elements}
                                justifyButtons={justifyButtons}
                                renderToggleButton={(props) => (
                                    <ToggleButton
                                        {...props}
                                        size="small"
                                        value={id}
                                        disabled={disabled}
                                        title={title}
                                    >
                                        {text}

                                        <Spacer xs h />

                                        <Icon name="chevron-down-sharp" size="xsmall" />
                                    </ToggleButton>
                                )}
                            />
                        );
                    }

                    return (
                        <ToggleButton
                            key={`${id}${index}`}
                            size="small"
                            value={id}
                            disabled={disabled}
                            title={title}
                            // it prevents the events to lose editor focus when the toolbar buttons are clicked
                            onMouseDown={(e) => {
                                e.preventDefault();

                                onMouseDown?.();
                            }}
                        >
                            {text}
                        </ToggleButton>
                    );
                })}
            </StyledToggleButtonGroup>
        ),
    );
};
