import { type TFunction } from 'i18next';

import { type GenerateImageAltText } from '@@api/hooks/useGenerateImageAltText';
import { type UploadFile } from '@@api/hooks/useUploadFile';
import { PLUGIN_CONFIG_TEMPLATES } from '@@editor/constants';
import { type Editor } from '@@editor/helpers';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import {
    type OptionsPerPlugin,
    type Plugin,
    PLUGIN_NAMES,
    type PluginConfig,
    type PluginList,
    type PluginName,
} from '@@editor/typings/UnityPlugins';

import CommentPlugin from './comment';
import DndPlugin from './dnd';
import FileUploadPlugin from './fileUpload';
import HeadingsLayoutPlugin from './headingsLayout';
import HeadingsTeasersPlugin from './headingsTeasers';
import SingleEmbedPlugin from './rules/singleEmbed';
import SingleLinePlugin from './rules/singleLine';
import EmbeddedContentPlugin from './serializable/embed/embeddedcontent';
import EmbeddedIframePlugin from './serializable/embed/embeddedIframe';
import EmbeddedInfoboxPlugin from './serializable/embed/embeddedInfobox';
import EmbeddedPollPlugin from './serializable/embed/embeddedPoll';
import EmbeddedSnippetPlugin from './serializable/embed/embeddedSnippet';
import FacebookPlugin from './serializable/embed/facebook';
import FrontentComponentPlugin from './serializable/embed/frontendComponent';
import ImagePlugin from './serializable/embed/image';
import InstagramPlugin from './serializable/embed/instagram';
import SlideshowPlugin from './serializable/embed/slideshow';
import ThreadsPlugin from './serializable/embed/threads';
import TiktokPlugin from './serializable/embed/tiktok';
import TwitterPlugin from './serializable/embed/twitter';
import VideoCmsPlugin from './serializable/embed/videocms';
import YoutubePlugin from './serializable/embed/youtube';
import ZattooPlugin from './serializable/embed/zattoo';
import EmbeddedComponentPlugin from './serializable/embeddedComponent';
import HtmlPlugin from './serializable/insertHtml';
import DynamicTeaserPlugin from './serializable/layout/dynamicTeaser';
import ImportInterviewPlugin from './serializable/layout/importInterview';
import InfoboxPlugin from './serializable/layout/infobox';
import InlineInterviewPlugin from './serializable/layout/inlineInterview';
import InlineQuotePlugin from './serializable/layout/inlineQuote';
import InterviewSegmentPlugin from './serializable/layout/interviewSegment';
import QuotePlugin from './serializable/layout/quote';
import SeparatorPlugin from './serializable/layout/separator';
import PollPlugin from './serializable/poll';
import SnippetPlugin from './serializable/snippet';
import SummaryPlugin from './serializable/summary';
import AutoReplaceTextPlugin from './serializable/text/autoReplaceText';
import BoldPlugin from './serializable/text/bold';
import CodePlugin from './serializable/text/code';
import ItalicPlugin from './serializable/text/italic';
import LinkPlugin from './serializable/text/link';
import ListPlugin from './serializable/text/list';
import NoBreakSpacePlugin from './serializable/text/noBreakSpace';
import ParagraphPlugin from './serializable/text/paragraph';
import SoftHyphenPlugin from './serializable/text/softHyphen';
import SubscriptPlugin from './serializable/text/subscript';
import SuperscriptPlugin from './serializable/text/superscript';
import TextDiffPlugin from './serializable/text/textDiff';
import UnderlinedPlugin from './serializable/text/underlined';
import WrapTextPlugin from './serializable/text/wrapText';
import TickerSummaryPlugin from './serializable/tickerSummary';
import SpellCheckerPlugin from './spellChecker';
import TeaserGeneratorPlugin from './teaserGenerator';

const pluginMap: Record<PluginName, any> = {
    autoReplaceText: AutoReplaceTextPlugin,
    bold: BoldPlugin,
    code: CodePlugin,
    comment: CommentPlugin,
    dnd: DndPlugin,
    dynamicTeaser: DynamicTeaserPlugin,
    embeddedComponent: EmbeddedComponentPlugin,
    embeddedcontent: EmbeddedContentPlugin,
    embeddedIframe: EmbeddedIframePlugin,
    embeddedInfobox: EmbeddedInfoboxPlugin,
    embeddedPoll: EmbeddedPollPlugin,
    embeddedSnippet: EmbeddedSnippetPlugin,
    facebook: FacebookPlugin,
    fileUpload: FileUploadPlugin,
    frontendComponent: FrontentComponentPlugin,
    headingsLayout: HeadingsLayoutPlugin,
    headingsTeasers: HeadingsTeasersPlugin,
    image: ImagePlugin,
    importInterview: ImportInterviewPlugin,
    infobox: InfoboxPlugin,
    inlineInterview: InlineInterviewPlugin,
    inlineQuote: InlineQuotePlugin,
    insertHtml: HtmlPlugin,
    instagram: InstagramPlugin,
    interviewSegment: InterviewSegmentPlugin,
    italic: ItalicPlugin,
    link: LinkPlugin,
    list: ListPlugin,
    noBreakSpace: NoBreakSpacePlugin,
    paragraph: ParagraphPlugin,
    poll: PollPlugin,
    quote: QuotePlugin,
    separator: SeparatorPlugin,
    singleEmbed: SingleEmbedPlugin,
    singleLine: SingleLinePlugin,
    slideshow: SlideshowPlugin,
    snippet: SnippetPlugin,
    softHyphen: SoftHyphenPlugin,
    subscript: SubscriptPlugin,
    spellChecker: SpellCheckerPlugin,
    summary: SummaryPlugin,
    superscript: SuperscriptPlugin,
    teaserGenerator: TeaserGeneratorPlugin,
    textDiff: TextDiffPlugin,
    threads: ThreadsPlugin,
    tickerSummary: TickerSummaryPlugin,
    tiktok: TiktokPlugin,
    twitter: TwitterPlugin,
    underlined: UnderlinedPlugin,
    videocms: VideoCmsPlugin,
    wrapText: WrapTextPlugin,
    youtube: YoutubePlugin,
    zattoo: ZattooPlugin,
    insertText: undefined,
    specialCharacters: undefined,
};

const pluginsWithoutFile: PluginName[] = [
    PLUGIN_NAMES.SPECIAL_CHARACTERS,
    PLUGIN_NAMES.INSERT_TEXT,
];

const getPluginModule = (name: PluginName) => {
    const module = pluginMap[name];

    if (!module) {
        // It IS possible that for a plugin name there is no plugin file, but
        // sometimes this might be a mistake. This is why we filter out the known ones and
        // warn about every other plugin file not found here.
        if (import.meta.env.MODE === 'development' && !pluginsWithoutFile.includes(name)) {
            console.warn(`Could not find plugin module for "${name}"`);
        }

        return (editor) => editor;
    }

    return module;
};

const initializePlugin = (editor: Editor, { name, options }: PluginConfig) => {
    const pluginModule = getPluginModule(name);

    return pluginModule(editor, options);
};

export const ALL_PLUGIN_CONFIG: PluginList[] = [
    // DRAG_DROP needs to be above paragraph, since paragraph does not forward
    // the drop event (which is correct, nevertheless DRAG_DROP needs to get
    // notified about drop events)
    PLUGIN_NAMES.DRAG_DROP,
    PLUGIN_NAMES.FACEBOOK,
    PLUGIN_NAMES.TWITTER,
    PLUGIN_NAMES.TEASER_GENERATOR,
    PLUGIN_NAMES.INSTAGRAM,
    PLUGIN_NAMES.YOUTUBE,
    PLUGIN_NAMES.TIKTOK,
    PLUGIN_NAMES.THREADS,
    PLUGIN_NAMES.FRONTEND_COMPONENT,
    {
        name: PLUGIN_NAMES.VIDEOCMS,
        options: {
            plugins: [PLUGIN_NAMES.FILE_UPLOAD],
        },
    },
    PLUGIN_NAMES.ZATTOO,
    PLUGIN_NAMES.EMBEDDED_CONTENT,
    // Important! The image plugin needs to be loaded before the link plugin, otherwise pasted image urls
    // would be inserted as a link instead of an image
    {
        name: PLUGIN_NAMES.IMAGE,
        options: {
            plugins: [PLUGIN_NAMES.FILE_UPLOAD],
        },
    },
    PLUGIN_NAMES.SLIDESHOW,
    PLUGIN_NAMES.INFOBOX,
    PLUGIN_NAMES.POLL,
    PLUGIN_NAMES.SNIPPET,
    {
        name: PLUGIN_NAMES.EMBEDDED_COMPONENT,
        options: {
            plugins: [
                PLUGIN_NAMES.EMBEDDED_INFOBOX,
                PLUGIN_NAMES.EMBEDDED_POLL,
                PLUGIN_NAMES.EMBEDDED_IFRAME,
                PLUGIN_NAMES.EMBEDDED_SNIPPET,
            ],
        },
    },
    PLUGIN_NAMES.INTERVIEW,
    PLUGIN_NAMES.IMPORT_INTERVIEW,
    PLUGIN_NAMES.INLINE_INTERVIEW,
    PLUGIN_NAMES.QUOTE,
    PLUGIN_NAMES.INLINE_QUOTE,
    PLUGIN_NAMES.SUMMARY,
    PLUGIN_NAMES.TICKER_SUMMARY,
    PLUGIN_NAMES.SEPARATOR,
    PLUGIN_NAMES.DYNAMIC_TEASER,
    PLUGIN_CONFIG_TEMPLATES.autoReplaceText,
    PLUGIN_NAMES.INSERT_HTML,
    // Important! The list plugin needs to be placed above the paragraph plugin in order to overwrite
    // its `insertBreak` logic
    PLUGIN_NAMES.LIST,
    PLUGIN_NAMES.PARAGRAPH,
    PLUGIN_NAMES.BOLD,
    PLUGIN_NAMES.ITALIC,
    PLUGIN_NAMES.UNDERLINED,
    PLUGIN_NAMES.SUBSCRIPT,
    PLUGIN_NAMES.SUPERSCRIPT,
    PLUGIN_CONFIG_TEMPLATES.specialCharacters,
    PLUGIN_NAMES.LINK,
    PLUGIN_CONFIG_TEMPLATES.softHyphen,
    {
        name: PLUGIN_NAMES.COMMENT,
        options: {
            unsupportedElementTypes: [ELEMENT_TYPES.EMBED_CREDIT],
        },
    },
    PLUGIN_NAMES.SPELL_CHECKER,
];

const resolvePluginConfigEntry = (entry: PluginList): PluginConfig => {
    const resolvedEntry = typeof entry === 'string' ? { name: entry } : entry;

    return {
        ...resolvedEntry,
    };
};

type Options = {
    included?: PluginName[];
    excluded?: PluginName[];
    defaultOptions?: {
        reducedUI?: boolean;
        t?: TFunction;
        uploadFile?: UploadFile;
        generateImageAltText?: GenerateImageAltText;
        contentLocale?: string;
        tenantIds?: number[];
    };
    optionsPerPlugin?: OptionsPerPlugin;
};

export const setupPlugins = (pluginConfig, options: Options = {}): Plugin[] => {
    const {
        included: includedPlugins = [],
        excluded: excludedPlugins = [],
        defaultOptions = {},
        optionsPerPlugin = {},
    } = options;

    return pluginConfig.reduce((previousValue, entry) => {
        const {
            name,
            options: pluginOptions = {},
            when = () => true,
        } = resolvePluginConfigEntry(entry);

        if (excludedPlugins.indexOf(name) >= 0 && includedPlugins.indexOf(name) < 0) {
            return previousValue;
        }

        const childPlugins = setupPlugins(pluginOptions.plugins || [], options);

        return previousValue.concat({
            name,
            options: {
                ...defaultOptions,
                ...pluginOptions,
                ...optionsPerPlugin[name],
                plugins: childPlugins,
            },
            when,
        });
    }, [] as Plugin[]);
};

const createInitializationPlan = (
    pluginConfig: PluginList[],
    rootPluginConfig = pluginConfig,
): PluginConfig[] =>
    pluginConfig.reduce((previousValue, entry) => {
        const { name, options, when } = resolvePluginConfigEntry(entry);

        if (!when?.(options, rootPluginConfig)) {
            return previousValue;
        }

        const children = createInitializationPlan(options?.plugins || [], rootPluginConfig);

        [...children, { name, options }].forEach((child) => {
            // Avoid loading the same plugin multiple times
            if (previousValue.findIndex((plugin) => plugin.name === child.name) === -1) {
                previousValue.push(child);
            }
        });

        return previousValue;
    }, [] as PluginConfig[]);

export const withPlugins = (editor: Editor, pluginConfig: PluginConfig[]): Editor => {
    const initializationPlan = createInitializationPlan(pluginConfig);

    // We need to reverse this to preserve the plugins order. For example the image plugin
    // needs to get triggered before the link plugin.
    return initializationPlan.reverse().reduce(initializePlugin, editor);
};

export default setupPlugins;
