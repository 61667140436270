import {
    styled,
    TableCell,
    type TableCellProps,
    TableSortLabel,
    type TableSortLabelProps,
} from '@mui/material';
import { noop } from 'lodash-es';
import React, { type CSSProperties, type ElementType } from 'react';

type Props = {
    as?: ElementType;
    className?: string;
    textAlign?: TableCellProps['align'];
    title?: string;
    sortBy?: boolean;
    type?: string;
    onClick?: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void;
    active?: boolean;
    style?: CSSProperties;
    direction?: TableSortLabelProps['direction'];
};

const StyledTableCell = styled(TableCell, {
    shouldForwardProp: (prop: string) => !prop.startsWith('$'),
})(({ theme }) => ({
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    ...theme.typography.subtitle4,

    '&[data-type="id"], &[data-type="boolean"], &[data-type="actions"], &[data-type="contentStatus"], &[data-type="publicationStatus"]':
        {
            width: '1px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
        },

    '&[data-type="date"]': {
        textAlign: 'right',
    },
}));

const Column = ({
    as,
    className,
    textAlign,
    title,
    sortBy,
    style,
    type,
    onClick = noop,
    active,
    direction,
}: Props) => (
    <StyledTableCell
        as={as}
        className={className}
        sortDirection={direction}
        sx={style}
        align={textAlign}
        data-type={type}
    >
        {sortBy ? (
            <TableSortLabel active={active} direction={direction || 'desc'} onClick={onClick}>
                {title}
            </TableSortLabel>
        ) : (
            title
        )}
    </StyledTableCell>
);

export default Column;
