import { alpha, styled } from '@mui/material';
import { uniq } from 'lodash-es';
import { type ReactNode, useCallback, useEffect, useRef } from 'react';
import { type NodeEntry, Path, Range } from 'slate';
import { useFocused, useReadOnly, useSlateSelection, useSlateStatic } from 'slate-react';

import { type Editor, Element, Node, ReactEditor } from '@@editor/helpers';
import renderEditor from '@@editor/plugins/utils/renderEditor';
import renderLeaf from '@@editor/plugins/utils/renderLeaf';
import { PLUGIN_NAMES, type PluginOptions } from '@@editor/typings/UnityPlugins';

import { useCommentActions, useComments } from './CommentContext';
import useCommentPopoverActions from './CommentPopoverContext/useCommentPopoverActions';
import {
    EDITOR_TO_UNSUPPORTED_ELEMENT_TYPES,
    MARK,
    NEW_COMMENT_ID,
    NEW_COMMENT_SELECTION_IS_VISIBLE_SYMBOL,
    NEW_COMMENT_SELECTION_SYMBOL,
} from './constants';
import {
    canAddCommentOnNode,
    getCommentNodesInfo,
    isCloseToCommentThreadElement,
    isCommentActive,
} from './utils';

type CommentRange = Range & {
    [NEW_COMMENT_SELECTION_SYMBOL]?: boolean;
    [NEW_COMMENT_SELECTION_IS_VISIBLE_SYMBOL]?: boolean;
};

type Props = { children: ReactNode };

const EditorWrapper = ({ children }: Props) => {
    const editor = useSlateStatic();
    const { commentIds, commentIdsList } = useComments(editor.id);
    const { registerEditor, updateCommentNodesInfo } = useCommentActions();
    const focused = useFocused();
    const readOnly = useReadOnly();
    const commentThreadFocusHash = useRef<string | null>(null);

    const handleFocus = useCallback(
        (e) => {
            if (isCloseToCommentThreadElement(e.target)) {
                commentThreadFocusHash.current = crypto.randomUUID();

                // Whenever a comment thread gains or looses focus, we want to update the hightlighted state for the related text
                // inside the rich text editor (related text must be hightlighted if selected or related comment thread is focused,
                // not hightlighted if related text is not selected and related comment thread is not focused).
                // The rich text editor will not re-render if a comment thread gains or looses focus, since it is rendered outside
                // of the rich text editor component. Therefore we need to call `forceUpdate` in order to trigger a re-render and
                // an update of the highlighted state of all texts with comments.
                editor.forceUpdate();
            }
        },
        [editor],
    );

    const handleBlur = useCallback(
        (e) => {
            if (isCloseToCommentThreadElement(e.target)) {
                commentThreadFocusHash.current = crypto.randomUUID();

                // Whenever a comment thread gains or looses focus, we want to update the hightlighted state for the related text
                // inside the rich text editor (text must be hightlighted if selected or related comment thread is focused,
                // not hightlighted if text is not selected and related comment thread is not focused).
                // The rich text editor will not re-render if a comment thread gains or looses focus, since it is rendered outside
                // of the rich text editor component. Therefore we need to call `forceUpdate` in order to trigger a re-render and
                // an update of the highlighted state of all texts with comments.
                editor.forceUpdate();
            }
        },
        [editor],
    );

    useEffect(() => {
        const unregisterEditor = registerEditor(editor);

        return unregisterEditor;
    }, [editor]);

    useEffect(() => {
        window.addEventListener('focus', handleFocus, true);
        window.addEventListener('blur', handleBlur, true);

        return () => {
            window.removeEventListener('focus', handleFocus, true);
            window.removeEventListener('blur', handleBlur, true);
        };
    }, [handleFocus, handleBlur]);

    useEffect(() => {
        editor.setEditorContextData({ [PLUGIN_NAMES.COMMENT]: { isReadOnly: readOnly } });
    }, [readOnly]);

    useEffect(() => {
        editor.setEditorContextData({
            [PLUGIN_NAMES.COMMENT]: { commentsCount: uniq(commentIds).length },
        });
    }, [commentIdsList]);

    useEffect(() => {
        const commentNodesInfo = getCommentNodesInfo(editor, {
            at: [],
        });

        updateCommentNodesInfo(editor, commentNodesInfo);

        // Whenever the contents, the selection or focus state of the rich text editor changes,
        // or a comment thread component gained, or lost focus, we need to re-generate the comment nodes info,
        // which will trigger a re-render of the comment panel/list
    }, [
        editor.children,
        editor.selection,
        focused,
        commentThreadFocusHash.current,
        updateCommentNodesInfo,
    ]);

    return <>{children}</>;
};

const StyledCommentLeaf = styled('span')<{ $isActive: boolean; $isClickable?: boolean }>(
    ({ $isActive, $isClickable, theme }) => ({
        backgroundColor: $isActive
            ? theme.palette.amber.main
            : alpha(theme.palette.amber.main, theme.opacityFactors.low),

        ...($isClickable && {
            cursor: 'pointer',
        }),

        '&:hover': {
            ...($isClickable && {
                backgroundColor: theme.palette.amber.main,
            }),
        },
    }),
);

export const CommentLeaf = (props) => {
    const { attributes, children, editor, leaf } = props;
    const isCommentPanelVisible = editor.getDataIn([PLUGIN_NAMES.COMMENT, 'isCommentPanelVisible']);
    const isCommentSelected = isCommentActive(editor, leaf[MARK]);

    // Make sure this component gets re-rendered, whenever the slate selection changes.
    useSlateSelection();

    return (
        <StyledCommentLeaf
            $isActive={isCommentPanelVisible && isCommentSelected}
            {...attributes}
            data-comment-id={leaf[MARK]}
        >
            {children}
        </StyledCommentLeaf>
    );
};

const ClickableCommentWithPopover = (props) => {
    const { attributes, children, editor, leaf } = props;
    const isCommentPanelVisible = editor.getDataIn([PLUGIN_NAMES.COMMENT, 'isCommentPanelVisible']);
    const isCommentSelected = isCommentActive(editor, leaf[MARK]);
    const { showCommentPopover } = useCommentPopoverActions();

    return (
        <StyledCommentLeaf
            $isActive={isCommentPanelVisible && isCommentSelected}
            $isClickable
            {...attributes}
            data-comment-id={leaf[MARK]}
            onClick={(event) => {
                event.stopPropagation();

                showCommentPopover(leaf[MARK], event.currentTarget);
            }}
        >
            {children}
        </StyledCommentLeaf>
    );
};

const StyledNewCommentSelectionLeaf = styled('span')<{ $isVisible: boolean }>(
    ({ $isVisible, theme }) => ({
        backgroundImage: $isVisible
            ? `repeating-linear-gradient(
        -45deg,
        ${alpha(theme.palette.amber.main, theme.opacityFactors.low)},
        ${alpha(theme.palette.amber.main, theme.opacityFactors.low)} 4px,
        ${theme.palette.common.white} 4px,
        ${theme.palette.common.white} 8px
    )`
            : 'none',
    }),
);

const NewCommentSelectionLeaf = (props) => (
    <StyledNewCommentSelectionLeaf
        {...props.attributes}
        data-comment-id={NEW_COMMENT_ID.get(props.editor)}
        $isVisible={props.leaf[NEW_COMMENT_SELECTION_IS_VISIBLE_SYMBOL]}
    >
        {props.children}
    </StyledNewCommentSelectionLeaf>
);

export const decorateNewCommentSelection = (editor: Editor, nodeEntry: NodeEntry): Range[] => {
    const { selection } = editor;
    const [node, path] = nodeEntry;
    const focused = ReactEditor.isFocused(editor);
    const readOnly = ReactEditor.isReadOnly(editor);
    const ranges: CommentRange[] = [];

    const isCommentPanelVisible = editor.getDataIn([PLUGIN_NAMES.COMMENT, 'isCommentPanelVisible']);

    const isCreateCommentInputFocused = editor.getDataIn([
        PLUGIN_NAMES.COMMENT,
        'isCreateCommentInputFocused',
    ]);

    if (
        selection &&
        Range.isExpanded(selection) &&
        !readOnly &&
        isCommentPanelVisible &&
        canAddCommentOnNode(editor, node, path)
    ) {
        const start =
            Path.compare(selection.anchor.path, selection.focus.path) <= 0
                ? selection.anchor
                : selection.focus;
        const end =
            Path.compare(selection.anchor.path, selection.focus.path) <= 0
                ? selection.focus
                : selection.anchor;

        // If user selects part of the link we want to expand it to include full link
        const anchorElement = Node.parent(editor, start.path);
        const isAnchorLinkElement = Element.isLinkElement(anchorElement);
        const anchor =
            Path.equals(start.path, path) && !isAnchorLinkElement
                ? { ...start }
                : { path, offset: 0 };

        const focusElement = Node.parent(editor, end.path);
        const isFocusLinkElement = Element.isLinkElement(focusElement);
        const focus =
            Path.equals(end.path, path) && !isFocusLinkElement
                ? { ...end }
                : { path, offset: Node.get(editor, path).text?.length ?? 0 };

        ranges.push({
            anchor,
            focus,
            [NEW_COMMENT_SELECTION_SYMBOL]: true,
            [NEW_COMMENT_SELECTION_IS_VISIBLE_SYMBOL]: !focused && isCreateCommentInputFocused,
        });
    }

    return ranges;
};

export const withComment = (editor: Editor, options: PluginOptions) => {
    const { decorate } = editor;
    const { isCommentClickable = false, unsupportedElementTypes = [] } = options;

    EDITOR_TO_UNSUPPORTED_ELEMENT_TYPES.set(editor, unsupportedElementTypes);
    NEW_COMMENT_ID.set(editor, crypto.randomUUID());

    editor.setEditorContextData({ [PLUGIN_NAMES.COMMENT]: { isCommentPanelVisible: false } });

    return Object.assign(editor, {
        renderEditor: renderEditor(editor, ({ children }) => (
            <EditorWrapper>{children}</EditorWrapper>
        )),
        // This inspired by the following slate example:
        // https://github.com/ianstormtaylor/slate/blob/master/site/examples/search-highlighting.js
        // If performance is not good we need to change to an inline element. Something like:
        // https://github.com/DND-IT/cms-frontend/pull/2202
        decorate: (nodeEntry: NodeEntry): Range[] => {
            const ranges = decorateNewCommentSelection(editor, nodeEntry);

            return [...ranges, ...decorate(nodeEntry)];
        },
        renderLeaf: renderLeaf(editor, [
            [MARK, isCommentClickable ? ClickableCommentWithPopover : CommentLeaf],
            [NEW_COMMENT_SELECTION_SYMBOL, NewCommentSelectionLeaf],
        ]),
    });
};

export default withComment;
