import { useMemo } from 'react';
import { type FieldPath, type FieldValues } from 'react-hook-form';

import { LoadingStatusManager } from '@@containers/LoadingStatusManager';
import { generateLookupKeyForRichTextEditorLoadingStatuses } from '@@containers/LoadingStatusManager/utils';
import snackbar, { type SnackbarKey } from '@@containers/Snackbar';
import RichTextEditor from '@@form/components/RichTextEditor/RichTextEditor';
import i18n from '@@lib/i18n/i18n';

import RichTextField, { type RichTextFieldProps } from './RichTextField';

const createHasLoadingElement = (editorId: string) => {
    const snackbarKeys = new Set<SnackbarKey>();

    return async () => {
        const key = generateLookupKeyForRichTextEditorLoadingStatuses({
            editorId,
        });

        const hasLoadingElement = await LoadingStatusManager.isLoading({ key });

        if (hasLoadingElement) {
            snackbarKeys.add(snackbar.error(i18n.t('editor.validation.uploading')));

            // t('editor.validation.uploading')
            return ['editor.validation.uploading'];
        }

        snackbarKeys.forEach((key) => snackbar.close(key));
        snackbarKeys.clear();
    };
};

export const RichTextEditorField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: RichTextFieldProps<TFieldValues, TName>,
) => {
    const { inputProps, ...rest } = props;
    const editorId = useMemo(() => crypto.randomUUID(), []);
    const hasLoadingElement = useMemo(() => createHasLoadingElement(editorId), [editorId]);

    return (
        <RichTextField
            {...rest}
            component={RichTextEditor}
            inputProps={{
                ...inputProps,
                editorId,
            }}
            validate={[hasLoadingElement]}
        />
    );
};

export default RichTextEditorField;
