import { type Editor, ReactEditor } from '@@editor/helpers';
import { type EmbeddedSnippetElement, MIME_TYPES } from '@@editor/helpers/Element';
import useEmbeddedComponentAsNode from '@@editor/plugins/serializable/embeddedComponent/useEmbeddedComponentAsNode';
import Snippet from '@@editor/plugins/serializable/snippet/Snippet';
import { PLUGIN_ICON_NAMES, PLUGIN_NAMES } from '@@editor/typings/UnityPlugins';

import EmbedWrapper from '../../components/EmbedWrapper';
import { TYPE } from '../constants';

type Props = {
    editor: Editor;
    element: EmbeddedSnippetElement;
};

const Component = (props) => {
    const elementAsNode = useEmbeddedComponentAsNode(props.element.data);

    return elementAsNode ? <Snippet {...props} element={elementAsNode} /> : null;
};

const mimetypeConfigs = {
    [MIME_TYPES.EMBEDDED_SNIPPET]: {
        Component,
    },
};

const EmbeddedSnippetNode = (props: Props) => {
    const { editor, element } = props;

    const elementAsNode = useEmbeddedComponentAsNode(element.data);
    const path = ReactEditor.findPath(editor, element);

    return (
        <EmbedWrapper
            {...props}
            type={TYPE}
            mimetypeConfigs={mimetypeConfigs}
            toolbarConfig={{
                layoutElement: elementAsNode,
                infos: {
                    iconName: PLUGIN_ICON_NAMES[TYPE],
                    title: props.editor.t('editor.plugin.snippet.frame.title'),
                },
                actions: [
                    {
                        type: 'edit',
                        iconName: 'gear',
                        onClick: (e) => {
                            e.preventDefault();

                            requestAnimationFrame(() =>
                                editor.showEmbedModal(
                                    PLUGIN_NAMES.EMBEDDED_COMPONENT,
                                    element,
                                    path,
                                ),
                            );
                        },
                    },
                    'delete',
                ],
            }}
        />
    );
};

export default EmbeddedSnippetNode;
