import { type FieldPath, type FieldValues } from 'react-hook-form';

import TextInputField, { type TextInputFieldProps } from './TextInput';

export type TextareaFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextInputFieldProps<TFieldValues, TName>;

const TextareaField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: TextareaFieldProps<TFieldValues, TName>,
) => {
    const { rows, ...rest } = props;
    const minRows = !rows ? 4 : undefined;

    return <TextInputField multiline minRows={minRows} rows={rows} {...rest} />;
};

export default TextareaField;
