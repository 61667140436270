import validator from 'validator';

export default (value) => {
    if (
        !validator.isURL(value || '', {
            // eslint-disable-next-line camelcase
            require_protocol: true,
            protocols: ['https'],
        })
    ) {
        // t('validator.message.secureUrl');
        return ['validator.message.secureUrl'];
    }
};
