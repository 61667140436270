import apiEnvs, { type ApiEnvConfig, type ApiEnvsConfig } from './apiEnvs';
import envs from './envs';

declare global {
    interface Window {
        runtime: {
            env: {
                CDII_ENV: ImportMetaEnv['CDII_ENV'] | null;
            };
        };
    }
}

interface IResolvedEnv {
    devToolbarDisabled: boolean;
    googleAnalyticsTrackingId: string | null;
    env: string;
    apiEnv: string;
    apiEnvVars: ApiEnvConfig;
    apiEnvs: ApiEnvsConfig;
    mode: string | undefined;
}

const currentEnvironment =
    window.runtime?.env?.CDII_ENV || import.meta.env.CDII_ENV || import.meta.env.MODE;
const currentEnvConfig = envs[currentEnvironment];

// Memory and localStorage take priority over this setting
export const resolveApiEnv = (apiEnv?: string | null): string =>
    apiEnv ||
    localStorage.getItem('CDII_API') ||
    import.meta.env.CDII_API ||
    currentEnvConfig.apiEnv;

const resolveEnv = (_apiEnv_?: string): IResolvedEnv => {
    const apiEnv = resolveApiEnv(_apiEnv_);
    const apiEnvVars = apiEnvs[apiEnv];

    if (!apiEnvVars) {
        throw new Error(`No matching configuration found for CDII_API=${apiEnv} in apiEnvs.ts`);
    }

    return {
        // devToolbar is enabled by default (besides visual tests)
        // disable it when needed, e.g. demo/production env
        devToolbarDisabled: currentEnvConfig.devToolbarDisabled,
        googleAnalyticsTrackingId: currentEnvConfig.googleAnalyticsTrackingId,
        // To which environment is it deployed?
        // E.g. dev/staging/demo/production and test
        env: currentEnvironment,
        // What backend API is connected?
        // E.g. localhost/mock-server/a*/dev-unitycms.io/...
        apiEnv,
        apiEnvVars,
        apiEnvs,
        // Replaced during build time
        // E.g. production (optimized) vs development build
        mode: import.meta.env.MODE,
    };
};

export default resolveEnv;
