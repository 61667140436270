import { styled } from '@mui/material';
import { type CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import UploadButton from '@@components/buttonImplementations/UploadButton';
import Icon from '@@components/Icon';
import Spacer from '@@components/Spacer';
import { type ImageMimeType } from '@@constants/ImageMimeTypes';

import { BaseWrapper, StatusText } from './styles';

const StyledIcon = styled(Icon)(({ theme }) => ({
    color: theme.fixed.fileUpload.dropArea.iconColor,
}));

const Wrapper = styled(BaseWrapper, {
    shouldForwardProp: (prop: string) => !prop.startsWith('$'),
})<{ $isOver: boolean }>(({ theme, $isOver }) => ({
    ...($isOver && {
        borderColor: theme.palette.primary.light,
        backgroundColor: theme.fixed.fileUpload.dropArea.backgroundHoverColor,
        [`${StyledIcon}`]: {
            color: theme.fixed.fileUpload.dropArea.iconHoverColor,
        },
    }),
}));

const Td = styled('td')<{ $align?: 'left' | 'right' | 'center' }>(({ theme, $align }) => ({
    border: `${theme.borders[1]} solid`,
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    ...($align && { textAlign: $align }),
}));

const Table = styled('table')(({ theme }) => ({
    border: `${theme.borders[1]} solid`,
    borderColor: theme.palette.primary.main,
    borderCollapse: 'collapse',
    ...theme.typography.small,
    color: theme.palette.primary.main,
}));

type Props = {
    allowedMimeTypes?: ImageMimeType[];
    className?: string;
    compact?: boolean;
    hideValidationCriteria?: boolean;
    multiple?: boolean;
    isOver: boolean;
    style?: CSSProperties;
    onUpload: (e: Event, files) => void;
};

const DropArea = (props: Props) => {
    const {
        allowedMimeTypes = [],
        className,
        compact,
        hideValidationCriteria,
        multiple = false,
        isOver,
        style,
        onUpload,
    } = props;

    const { t } = useTranslation();

    return (
        <Wrapper $isOver={isOver} className={className} style={style}>
            {!compact && <Spacer xl v />}
            <StyledIcon name={multiple ? 'images-regular' : 'image-regular'} size="xlarge" />
            {!compact && <Spacer sm v />}
            <StatusText>
                {multiple
                    ? t('fileUploadDropArea.multiple.text')
                    : t('fileUploadDropArea.single.text')}
            </StatusText>
            {!compact && <Spacer sm v />}
            <UploadButton
                accept={allowedMimeTypes.join(',')}
                multiple={multiple}
                onUpload={onUpload}
            >
                {multiple
                    ? t('fileUploadDropArea.multiple.upload')
                    : t('fileUploadDropArea.single.upload')}
            </UploadButton>

            {!hideValidationCriteria && (
                <>
                    <Spacer lg v />
                    <StatusText>{t('fileUploadDropArea.criteria.description')}</StatusText>
                    <Table>
                        <tbody>
                            <tr>
                                <Td>jpg/jpeg/png/svg/webp</Td>
                                <Td $align="right">64 MB</Td>
                            </tr>
                            <tr>
                                <Td>gif</Td>
                                <Td $align="right">10 MB</Td>
                            </tr>
                        </tbody>
                    </Table>
                </>
            )}
        </Wrapper>
    );
};

export default DropArea;
