import { createContext, type ReactNode, useContext, useMemo, useReducer } from 'react';

import getElvisIdByImage from '@@editor/selectors/getElvisIdByImage';

import { type ImageElement } from './helpers/Element';

type EditorId = string;

type State = {
    elements: Map<EditorId, ImageElement[]>;
};

const initialState: State = {
    elements: new Map(),
};

const ACTION_TYPES = {
    MOUNT: 'MOUNT',
    UNMOUNT: 'UNMOUNT',
    ADD_ELEMENT: 'ADD_ELEMENT',
    REMOVE_ELEMENT: 'REMOVE_ELEMENT',
} as const;

type ChangeElementPayload = {
    editorId: EditorId;
    element: ImageElement;
};

type MountElementsPayload = {
    editorId: EditorId;
    elements: ImageElement[];
};

type UnmountElementsPayload = {
    editorId: EditorId;
};

type ContextType = {
    actions: {
        addElement: (payload: ChangeElementPayload) => void;
        removeElement: (payload: ChangeElementPayload) => void;
        mount: (payload: MountElementsPayload) => void;
        unMount: (payload: UnmountElementsPayload) => void;
    };
    state: State;
};

type MountAction = {
    type: typeof ACTION_TYPES.MOUNT;
    payload: MountElementsPayload;
};

type UnmountAction = {
    type: typeof ACTION_TYPES.UNMOUNT;
    payload: UnmountElementsPayload;
};

type addElementAction = {
    type: typeof ACTION_TYPES.ADD_ELEMENT;
    payload: ChangeElementPayload;
};

type removeElementAction = {
    type: typeof ACTION_TYPES.REMOVE_ELEMENT;
    payload: ChangeElementPayload;
};

type Action = MountAction | UnmountAction | addElementAction | removeElementAction;

const applyAction = (state: State, action: Action): State => {
    switch (action.type) {
        case ACTION_TYPES.MOUNT: {
            const { editorId, elements } = action.payload;

            const nextState = {
                ...state,
                elements: new Map(state.elements).set(editorId, elements),
            };

            return nextState;
        }

        case ACTION_TYPES.UNMOUNT: {
            const { editorId } = action.payload;

            const newElements = new Map(state.elements);

            newElements.delete(editorId);

            return {
                ...state,
                elements: newElements,
            };
        }

        case ACTION_TYPES.ADD_ELEMENT: {
            const { editorId, element } = action.payload;
            const elements = state.elements.get(editorId) || [];

            return {
                ...state,
                elements: new Map(state.elements).set(editorId, [...elements, element]),
            };
        }

        case ACTION_TYPES.REMOVE_ELEMENT: {
            const { editorId, element } = action.payload;
            const elements = [...(state.elements?.get(editorId) || [])];

            const index = elements.findIndex(
                (el) => getElvisIdByImage(el) === getElvisIdByImage(element),
            );

            elements.splice(index, 1);

            return {
                ...state,
                elements: new Map(state.elements).set(editorId, elements),
            };
        }
    }
};

export const DEFAULT_STATE = {
    actions: {
        addElement: () => {},
        removeElement: () => {},
        unMount: () => {},
        mount: () => {},
    },
    state: initialState,
};

export const EditorElementsContext = createContext<ContextType>(DEFAULT_STATE);

export const getAllElementsFromState = (state: State) =>
    [...state.elements.values()].flatMap((elements) => [...elements]);

type Props = { children: ReactNode };
export const EditorElementsProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(applyAction, initialState);

    const actions: ContextType['actions'] = useMemo(
        () => ({
            addElement: (payload) => {
                dispatch({ type: ACTION_TYPES.ADD_ELEMENT, payload });
            },
            removeElement: (payload) => {
                dispatch({ type: ACTION_TYPES.REMOVE_ELEMENT, payload });
            },
            mount: (payload) => {
                dispatch({ type: ACTION_TYPES.MOUNT, payload });
            },
            unMount: (payload) => {
                dispatch({ type: ACTION_TYPES.UNMOUNT, payload });
            },
        }),
        [dispatch],
    );

    return (
        <EditorElementsContext.Provider value={{ state, actions }}>
            {children}
        </EditorElementsContext.Provider>
    );
};

export const useEditorElementsContext = () => {
    const context = useContext(EditorElementsContext);

    return context;
};
