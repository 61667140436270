import { pick } from 'lodash-es';
import { type NodeEntry, Transforms } from 'slate';

import { Editor, Element, Node, type Operation } from '@@editor/helpers';
import { ELEMENT_TYPES } from '@@editor/helpers/Element';
import ElementCounter from '@@editor/plugins/utils/ElementCounter';

export const withSingleLine = (editor: Editor): Editor => {
    const { apply, normalizeNode } = editor;

    let prevOperation: Operation | undefined;

    return Object.assign(editor, {
        insertBreak: () => {
            // Do nothing
        },
        normalizeNode: (entry: NodeEntry<Node>): void => {
            const [node, path] = entry;

            if (Editor.isEditor(node)) {
                const elementCounter = new ElementCounter();

                // Make sure we have max 1 paragraph block in the document
                for (const [child, childPath] of Node.children(editor, path)) {
                    const { overallCount } = elementCounter.count(child);

                    if (
                        !Element.isParagraphElement(child) ||
                        overallCount.types[ELEMENT_TYPES.PARAGRAPH] > 1
                    ) {
                        Transforms.mergeNodes(editor, {
                            at: childPath,
                        });

                        return;
                    }
                }
            }

            // Fall back to the original `normalizeNode` to enforce other constraints.
            normalizeNode(entry);
        },
        apply: (operation: Operation) => {
            // When the user has selected all the text of a singleLine rich text editor and pastes some text from the clipboard,
            // slate will remove the whole element and insert a new one. This will also create a new elementId. If we watch
            // this now in our compare tool, it will look quite strange. It looks like we have removed the element,
            // and re-added it. In order to avoid that wrong UX, we prevent resetting the element-id over here.
            if (operation.type === 'insert_node' && prevOperation?.type === 'remove_node') {
                return apply({
                    ...operation,
                    node: {
                        ...operation.node,
                        ...pick(prevOperation.node, ['id']),
                    },
                    preventIdOverwrite: true,
                });
            }

            prevOperation = operation;

            return apply(operation);
        },
    });
};

export default withSingleLine;
